// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tittle-subsection-buttons {
  background-color: var(--greyColor);
  min-width: 100%;
  pointer-events: none;
}
.tittle-subsection-buttons:hover {
  background-color: var(--greyColor);
  cursor: none;
}`, "",{"version":3,"sources":["webpack://./src/commons/chat-components/messages/ButtonsSection/buttonsSection.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;EACA,oBAAA;AACF;AAAE;EACE,kCAAA;EACA,YAAA;AAEJ","sourcesContent":[".tittle-subsection-buttons {\n  background-color: var(--greyColor);\n  min-width: 100%;\n  pointer-events: none;\n  &:hover {\n    background-color: var(--greyColor);\n    cursor: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
