// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-theme=light] {
  --background-color-not-match: #dedede8f;
}

body[data-theme=dark] {
  --background-color-not-match: #444444;
}

.hub-container {
  background-color: var(--background-automation);
  border-radius: 10px;
  padding: 1rem 1rem 1.8rem 1rem;
}
.hub-container__header {
  padding-bottom: 1rem;
}
.hub-container__cards-holder {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
  gap: 1rem;
}
.hub-container__cards-holder__not-match {
  background-color: var(--background-color-not-match);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hub-container__cards-holder__not-match__texts {
  color: var(--color-text-sidemenu);
  padding: 1rem 1.5rem;
}
.hub-container__cards-holder__not-match__texts__title {
  font-weight: 700;
  font-size: calc(var(--desktop-hub-text) * 1);
  margin-bottom: 0.5rem;
}
.hub-container__cards-holder__not-match__texts__subtitle {
  font-size: calc(var(--desktop-hub-text) * 0.7);
}
.hub-container__cards-holder__not-match__image {
  height: 6rem;
  margin: 0.5rem;
}

@media screen and (max-width: 768px) {
  .hub-container__cards-holder {
    grid-template-columns: repeat(auto-fit, minmax(98%, 1fr));
  }
}`, "",{"version":3,"sources":["webpack://./src/views/Automations-view/SecondaryViews/hubView.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;AACF;;AAEA;EACE,qCAAA;AACF;;AAEA;EACE,8CAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,oBAAA;AACJ;AAEE;EACE,aAAA;EACA,yDAAA;EACA,SAAA;AAAJ;AAEI;EACE,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAN;AAEM;EACE,iCAAA;EACA,oBAAA;AAAR;AAEQ;EACE,gBAAA;EACA,4CAAA;EACA,qBAAA;AAAV;AAGQ;EACE,8CAAA;AADV;AAKM;EACE,YAAA;EACA,cAAA;AAHR;;AASA;EAEI;IACE,yDAAA;EAPJ;AACF","sourcesContent":["body[data-theme=\"light\"] {\n  --background-color-not-match: #dedede8f;\n}\n\nbody[data-theme=\"dark\"] {\n  --background-color-not-match: #444444;\n}\n\n.hub-container {\n  background-color: var(--background-automation);\n  border-radius: 10px;\n  padding: 1rem 1rem 1.8rem 1rem;\n\n  &__header {\n    padding-bottom: 1rem;\n  }\n\n  &__cards-holder {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));\n    gap: 1rem;\n\n    &__not-match {\n      background-color: var(--background-color-not-match);\n      border-radius: 10px;\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n\n      &__texts {\n        color: var(--color-text-sidemenu);\n        padding: 1rem 1.5rem;\n\n        &__title {\n          font-weight: 700;\n          font-size: calc(var(--desktop-hub-text) * 1);\n          margin-bottom: 0.5rem;\n        }\n\n        &__subtitle {\n          font-size: calc(var(--desktop-hub-text) * 0.7);\n        }\n      }\n\n      &__image {\n        height: 6rem;\n        margin: 0.5rem;\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .hub-container {\n    &__cards-holder {\n      grid-template-columns: repeat(auto-fit, minmax(98%, 1fr));\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
