import axios from "axios";
const customURL = `${process.env.REACT_APP_BACK_API}custom/`;

export const getCustom = async (email, token) => {
  const mail = email;
  // Axios get request to get the customization of the user.
  try {
    const customResponse = await axios({
      method: "GET",
      url: customURL,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        mail,
      },
    });
    return customResponse;
  } catch (err) {
    return err;
  }
};

export const putCustom = async (email, custom, token) => {
  const jsonData = {};

  jsonData.mail = email;
  jsonData.digi = JSON.stringify(custom);
  // Axios post request to save the customization changes.
  try {
    const responseFunctions = await axios({
      method: "PUT",
      url: customURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: jsonData,
    });

    return responseFunctions;
  } catch (err) {
    return err;
  }
};
