import { useContext, useState } from "react";
import AutomationCard from "../../../commons/automation-card/AutomationCard";
import { BotsContext } from "../../../context/botsContext";
import SearchInput from "../../../commons/input/SearchInput";
import nuts from "../assets/nuts.png";
import "./hubView.scss";

const HubView = () => {
  const { allBots } = useContext(BotsContext);
  const bots = allBots.filter((bot) => !bot.downloaded);
  const [isSearching, setIsSearching] = useState(false);
  const [botsSearched, setBotsSearched] = useState([]);

  return (
    <div className="hub-container">
      <div className="hub-container__header">
        <SearchInput
          list={bots}
          setIsSearching={setIsSearching}
          setItemsSearched={setBotsSearched}
        />
      </div>
      <div className="hub-container__cards-holder">
        {isSearching && botsSearched.length > 0 ? (
          botsSearched.map((bot, index) => {
            return (
              <AutomationCard
                key={index}
                title={bot?.name}
                description={bot?.description}
                tags={bot?.tags}
                version={bot?.version}
                versionTimestamp={bot?.version_timestamp}
                los={bot?.los?.name}
                downloaded={bot?.downloaded}
              />
            );
          })
        ) : isSearching && botsSearched.length === 0 ? (
          <div className="hub-container__cards-holder__not-match">
            <div className="hub-container__cards-holder__not-match__texts">
              <p className="hub-container__cards-holder__not-match__texts__title">
                No encontramos resultados relacionados
              </p>
              <p className="hub-container__cards-holder__not-match__texts__subtitle">
                Podés probar ajustando tus filtros o las palabras de búsqueda
                para encontrar lo que buscas
              </p>
            </div>
            <img
              className="hub-container__cards-holder__not-match__image"
              src={nuts}
              alt="Nuts"
            />
          </div>
        ) : (
          <>
            {bots.map((bot, index) => {
              return (
                <AutomationCard
                  key={index}
                  title={bot?.name}
                  description={bot?.description}
                  tags={bot?.tags}
                  version={bot?.version}
                  versionTimestamp={bot?.version_timestamp}
                  los={bot?.los?.name}
                  downloaded={bot?.downloaded}
                />
              );
            })}
            {/* <AutomationCard
              key={"req1"}
              title={"Solicitar una automatización"}
              subtitle={"¿No encontraste lo que buscabas?"}
              type={"request"}
            /> */}
            <AutomationCard
              key={"soon1"}
              title={"Próximamente"}
              subtitle={"Estamos trabajando en nuevas automatizaciones"}
              type={"soon"}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HubView;
