import { useState, createContext, useEffect } from "react";
export const ScreenContext = createContext(null);
const { Provider } = ScreenContext;

// This is where we store screen-related data, such as the current theme, screen size, whether the side menu is open, etc.
const ScreenProvider = ({ children }) => {
  const [barSize, setBarSize] = useState(3);
  const [screenColsSize, setScreenColsSize] = useState(9);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isScreenLoading, setIsScreenLoading] = useState({
    state: false,
    text: "",
  });
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [theme, setTheme] = useState({
    theme: localStorage.getItem("theme") || "light",
    isTouched: false,
  });
  const nextTheme = theme.theme === "light" ? "dark" : "light";
  const [scrollRef, setScrollRef] = useState();

  useEffect(() => {
    document.body.dataset.theme = theme.theme;
  }, [theme]);

  const handleBarSize = (size) => {
    setBarSize(size);
  };

  const handleScreenColsSize = (size) => {
    setScreenColsSize(size);
  };

  const handleMenuIsOpen = (isOpen) => {
    setMenuIsOpen(isOpen);
  };

  const getBarAndScreenSize = (width) => {
    if (width < 576) {
      return {
        open: {
          barCols: 10,
          screenCols: 2,
        },
        close: {
          barCols: 1,
          screenCols: 11,
        },
      };
    }
    if (width >= 1200) {
      return {
        open: {
          barCols: 3,
          screenCols: 9,
        },
        close: {
          barCols: 1,
          screenCols: 11,
        },
      };
    }
    if (width >= 992) {
      return {
        open: {
          barCols: 3,
          screenCols: 9,
        },
        close: {
          barCols: 1,
          screenCols: 11,
        },
      };
    }
    if (width >= 768) {
      return {
        open: {
          barCols: 5,
          screenCols: 7,
        },
        close: {
          barCols: 1,
          screenCols: 10,
        },
      };
    }
    if (width >= 576) {
      return {
        open: {
          barCols: 7,
          screenCols: 5,
        },
        close: {
          barCols: 1,
          screenCols: 10,
        },
      };
    }
  };

  useEffect(() => {
    let width = window.innerWidth;
    const sizes = getBarAndScreenSize(width);
    setBarSize(sizes.close.barCols);
    setScreenColsSize(sizes.close.screenCols);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Provider
      value={{
        barSize,
        screenColsSize,
        handleBarSize,
        windowSize,
        setTheme,
        theme,
        nextTheme,
        handleScreenColsSize,
        handleMenuIsOpen,
        menuIsOpen,
        getBarAndScreenSize,
        isChatLoading,
        setIsChatLoading,
        isScreenLoading,
        setIsScreenLoading,
        scrollRef,
        setScrollRef,
      }}
    >
      {children}
    </Provider>
  );
};

export default ScreenProvider;
