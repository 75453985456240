/**
 * A wrapping component to format inner text (like links and email addresses)
 *
 * @param {object} props
 * @param {string} props.text The text to format
 * @returns {React.ReactNode}
 */
const FormatMessage = ({ text }) => {
    /** @type {string[]} */
    var message = [];
    var type = "";
    //Checks if email or url type message
    text.includes("https") || text.includes("@pwc")
        ? (type = "FORMAT")
        : (type = "");

    const FormattedMessage = () => {
        messageFormat();

        return (
            <div>
                {message.map((element, index) => {
                    // If link
                    if (message[index + 1]?.includes("https")) {
                        // Will select 5 words before the link to make it so, you click those words and you are redirected to that page.
                        return (
                            <>
                                {" " + element.split(" ").slice(0, -1).join(" ")}
                                <a
                                    href={decodeURI(encodeURI(message[index + 1]))}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="message-link"
                                >
                                    {" " + element.split(" ").slice(-1).join(" ")}
                                </a>
                            </>
                        );
                        //If mail
                    } else if (element.includes("@pwc")) {
                        // Necessary to send the email, if not replaced with the charcode of the character, it wouldn't work.
                        return (
                            <a
                                href={
                                    "mailto:" +
                                    element
                                        .replace("", "%20")
                                        .replace("@", "%40")
                                        .replace(".", "%2E")
                                }
                                target="_blank"
                                rel="noreferrer"
                                className="message-link"
                            >
                                {" " + element}
                            </a>
                        );
                        //If not a mail neither a link, just post the message
                    } else if (!element.includes("@pwc") && !element.includes("https")) {
                        return <>{" " + element}</>;
                    }
                    return null;
                })}
            </div>
        );
    };

    //Divides the message into multiples ones, so it's easier to select what type of format is needed
    const messageFormat = () => {
        /** @type {string[]} */
        var arrayMessage = [];
        message = [];
        var n = 0;
        arrayMessage = text.split(" ");
        arrayMessage.map((element, index) => {
            if (element.includes("@") || element.includes("https")) {
                if (arrayMessage.slice(n, index).join(" ").includes("   -")) {
                    message.push(arrayMessage.slice(n, index).join(" ").split("   -")[0]);
                    var secondPart = arrayMessage
                        .slice(n, index)
                        .join(" ")
                        .split("   -")[1];
                    var finalPart = "  -" + secondPart;
                    message.push(finalPart);
                } else {
                    message.push(arrayMessage.slice(n, index).join(" "));
                }
                message.push(element);

                n = index + 1;
            } else if (index === arrayMessage.length - 1) {
                message.push(arrayMessage.slice(n, index + 1).join(" "));
            }

            return null;
        });
    };

    return (
        <div>
            {type === "FORMAT" && <FormattedMessage />}
            {type === "" && <p>{text}</p>}
        </div>
    );
};

export default FormatMessage;
