import React from "react";
import {
  FirstLayerLoading,
  SecondLayerLoading,
  ThirdLayerLoading,
} from "./loading-assets/icon-pwc";
import "./firstLoadingView.scss";

// Loading screen when entering to the app
const FirstLoadingView = () => {
  return (
    <div className="first-loading-container">
      <div className="first-loading-container__transition">
        <div className="first-loading-container__item">
          <FirstLayerLoading />
        </div>
        <div className="first-loading-container__item">
          <SecondLayerLoading />
        </div>
        <div className="first-loading-container__item">
          <ThirdLayerLoading />
        </div>
      </div>
    </div>
  );
};

export default FirstLoadingView;
