import axios from "axios";

const customURL = `https://api.applicationinsights.io/v1/apps/${process.env.REACT_APP_APPINSIGHTS_APPID}`;

const apiKey = `${process.env.REACT_APP_APPINSIGHTS_APIKEY}`;

export const getGlobalScreenAVG = async () => {
  const query =
    "/query?query=pageViews | where name == 'ScreenTime' and timestamp > ago(30d) | where customDimensions.userGUID != 'undefined'  and customDimensions.sessionActive == 'true' | summarize averageDuration = avg(todouble(customDimensions.durationInSeconds)) by tostring(customDimensions.userGUID) | summarize averageGlobal = avg(averageDuration)";

  try {
    const customResponse = await axios({
      method: "GET",
      url: customURL + query,
      headers: {
        "Content-Type": "aplication/json",
        "x-api-key": apiKey,
      },
    });

    return customResponse;
  } catch (err) {
    return err;
  }
};

export const getUserScreenAVG = async (USER_GUID) => {
  const query = `/query?query=pageViews | where name == 'ScreenTime' and timestamp > ago(30d) | where customDimensions.userGUID == '${USER_GUID}' and customDimensions.sessionActive == 'true' | summarize averageDuration = avg(todouble(customDimensions.durationInSeconds)) by tostring(customDimensions.userGUID) | project userGUID = customDimensions_userGUID, averageDuration`;

  try {
    const customResponse = await axios({
      method: "GET",
      url: customURL + query,
      headers: {
        "Content-Type": "aplication/json",
        "x-api-key": apiKey,
      },
    });

    return customResponse;
  } catch (err) {
    return err;
  }
};
