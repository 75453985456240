import React, { useContext } from "react";
import { BotsContext } from "../../context/botsContext";
import "./customModal.scss";

/**
 *
 * @param {object} props
 * @param {object} props.message
 * @param {boolean} props.message.show
 * @param {React.ReactNode} props.message.title
 * @param {string} props.message.subtitle
 * @param {string} props.message.tags
 * @param {object} props.message.firstButton
 * @param {React.ReactNode} props.message.firstButton.title
 * @param {() => void} props.message.firstButton.onClickFunction
 * @param {object} props.message.secondButton
 * @param {React.ReactNode} props.message.secondButton.title
 * @param {() => void} props.message.secondButton.onClickFunction
 * @returns {React.ReactNode}
 */
const CustomModal = ({ message }) => {
  const { isDownloading, isExecuting } = useContext(BotsContext);

  return (
    <div className="custom-modal-container">
      <div className="custom-modal-container__title">{message.title}</div>
      <p className="custom-modal-container__subtitle">{message.subtitle}</p>

      {!message.tags || message.tags.toLowerCase().includes("python") ? null : (
        <p className="custom-modal-container__subtitle">
          {
            "📌 Solo podemos hacer entrega de esta automatización, pero no podrás ejecutarlo en esta plataforma."
          }
        </p>
      )}

      <div className="custom-modal-container__button-container">
        {message.firstButton && (
          <button
            className="custom-modal-container__button-container__firstButton"
            onClick={message.firstButton.onClickFunction}
          >
            {message.firstButton.title}
          </button>
        )}
        {message.secondButton && (
          <button
            className={`custom-modal-container__button-container__secondButton${
              message.secondButton.title.props?.children.includes("Descargar") &&
              (isDownloading || isExecuting)
                ? "--disabled"
                : ""
            }`}
            onClick={() => {
              if (
                !message.secondButton.title.props?.children.includes("Descargar")
              )
                message.secondButton.onClickFunction();
              else if (
                message.secondButton.title.props?.children.includes("Descargar") &&
                !isDownloading &&
                !isExecuting
              )
                message.secondButton.onClickFunction();
            }}
          >
            {message.secondButton.title}
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
