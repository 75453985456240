// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-loading-container {
  background-color: #fff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.first-loading-container__item {
  animation: fadeInOut 5s ease-in-out infinite;
}
.first-loading-container__item:nth-of-type(1) {
  animation-delay: 4s;
}
.first-loading-container__item:nth-of-type(2) {
  animation-delay: 2s;
}
.first-loading-container__item:nth-of-type(3) {
  animation-delay: 0s;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/Loading-view/firstLoadingView.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;AACE;EACE,4CAAA;AACJ;AACI;EACE,mBAAA;AACN;AAEI;EACE,mBAAA;AAAN;AAGI;EACE,mBAAA;AADN;;AAMA;EACE;IACE,UAAA;EAHF;EAMA;IACE,UAAA;EAJF;EAOA;IACE,UAAA;EALF;EAQA;IACE,UAAA;EANF;EASA;IACE,UAAA;EAPF;AACF","sourcesContent":[".first-loading-container {\n  background-color: #fff;\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n\n  &__item {\n    animation: fadeInOut 5s ease-in-out infinite;\n\n    &:nth-of-type(1) {\n      animation-delay: 4s;\n    }\n\n    &:nth-of-type(2) {\n      animation-delay: 2s;\n    }\n\n    &:nth-of-type(3) {\n      animation-delay: 0s;\n    }\n  }\n}\n\n@keyframes fadeInOut {\n  0% {\n    opacity: 1;\n  }\n\n  20% {\n    opacity: 1;\n  }\n\n  40% {\n    opacity: 0;\n  }\n\n  80% {\n    opacity: 0;\n  }\n\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
