function changeTempFormDataWithEditedResponses(item, tempFormData) {
  //this functions adds the new answers from editing in the tempFormData state
  //it adds the new fields at the end and replaces the repeated fields with the new answers

  let index = tempFormData.findIndex(
    (itemFormData) =>
      Object.keys(itemFormData).toString() === Object.keys(item.camp).toString()
  );

  if (index !== -1) {
    //replace object
    tempFormData.splice(index, 1, item.camp);
  } else {
    //new object
    tempFormData.push(item.camp);
  }
}

module.exports = { changeTempFormDataWithEditedResponses };
