import axios from "axios";
// Get the user stats
const statsURL = `${process.env.REACT_APP_BACK_API}stats/userstats`;
// Get all the users stats...
const statsAllURL = `${process.env.REACT_APP_BACK_API}stats/all`;
// ...filtered by the los
const statsByLosURL = `${process.env.REACT_APP_BACK_API}stats/bylos`;

export const getUserStats = async (email, token) => {
  const mail = email;
  try {
    const statsResponse = await axios({
      method: "GET",
      url: statsURL,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${token}`
      },
      params: {
        mail,
      },
    });
    return statsResponse;
  } catch (err) {
    throw err;
  }
};

export const getStatsAll = async (mail, token) => {
  try {
    const statsAllResponse = await axios({
      method: "GET",
      url: statsAllURL,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${token}`
      },
      params: {
        mail,
      },
    });
    return statsAllResponse;
  } catch (err) {
    throw err;
  }
};

export const getStatsByLos = async (los, token) => {
  try {
    const statsByLosResponse = await axios({
      method: "GET",
      url: statsByLosURL,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${token}`
      },
      params: {
        los,
      },
    });
    return statsByLosResponse.data?.response;
  } catch (err) {
    throw err;
  }
};
