// AppInsights.js
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
var jsonUserData = JSON.parse(sessionStorage.getItem("userData"));
let userSession = "";

const setUserSession = (session_value) => {
  userSession = session_value;
};

const getUserData = () => {
  return JSON.parse(sessionStorage.getItem("userData"));
};

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    disableCookiesUsage: true,
  },
});

appInsights.loadAppInsights();
const appInsightsTracker = {
  events: (typeEvent, typePage, pageName) => {
    if (!typeEvent || !typePage || !pageName) {
      return null;
    }

    appInsights.trackEvent({
      name: "Sidebar events",
      properties: {
        typeEvent: `${typeEvent}`,
        typePage: `${typePage}`,
        pageName: `${pageName}`,
        userLoS: `${jsonUserData?.los}`,
        userGUID: `${jsonUserData?.uid}`,
        userId: `${jsonUserData?.userId}`,
        userFile: `${jsonUserData?.file}`,
        userSeniority: `${
          jsonUserData?.upwcjobtitle?.split("-")[3] !== undefined
            ? jsonUserData?.upwcjobtitle?.split("-")[2]?.trim() +
              " " +
              jsonUserData?.upwcjobtitle?.split("-")[3]?.trim()
            : jsonUserData?.upwcjobtitle?.split("-")[2]?.trim()
        }`,
        userSession: `${userSession}`,
      },
    });
    appInsights.flush();
  },

  pageView: (pageName) => {
    if (!pageName || !jsonUserData || !userSession) {
      return null;
    }

    appInsights.trackPageView({
      name: "Page load",
      properties: {
        pageName: `${pageName}`,
        userLoS: `${jsonUserData?.los}`,
        userGUID: `${jsonUserData?.uid}`,
        userId: `${jsonUserData?.userId}`,
        userFile: `${jsonUserData?.file}`,
        userSeniority: `${
          jsonUserData?.upwcjobtitle?.split("-")[3] !== undefined
            ? jsonUserData?.upwcjobtitle?.split("-")[2]?.trim() +
              " " +
              jsonUserData?.upwcjobtitle?.split("-")[3]?.trim()
            : jsonUserData?.upwcjobtitle?.split("-")[2]?.trim()
        }`,
        userSession: `${userSession}`,
      },
    });
    appInsights.flush();
  },

  logFormEvents: (formName, typeEvent, inputName, idForm) => {
    if (!formName || !typeEvent || !inputName) {
      return null;
    }
    if (jsonUserData === null) jsonUserData = getUserData();

    appInsights.trackEvent({
      name: `${formName}`,
      properties: {
        typeEvent: `${typeEvent}`,
        inputName: `${inputName}`,
        idForm: `${idForm}`,
        userLoS: `${jsonUserData?.los}`,
        userGUID: `${jsonUserData?.uid}`,
        userId: `${jsonUserData?.userId}`,
        userFile: `${jsonUserData?.file}`,
        userSeniority: `${
          jsonUserData?.upwcjobtitle?.split("-")[3] !== undefined
            ? jsonUserData?.upwcjobtitle?.split("-")[2]?.trim() +
              " " +
              jsonUserData?.upwcjobtitle?.split("-")[3]?.trim()
            : jsonUserData?.upwcjobtitle?.split("-")[2]?.trim()
        }`,
        userSession: `${userSession}`,
      },
    });
    appInsights.flush();
  },

  logScreenTime: (pageName, startTime, sessionActive) => {
    if (jsonUserData === null) jsonUserData = getUserData();

    if (startTime !== undefined && pageName !== undefined) {
      const durationInSeconds = (new Date() - startTime) / 1000;
      if (durationInSeconds > 1) {
        appInsights.trackPageView({
          name: "ScreenTime",
          properties: {
            screenTime: `${pageName}`,
            durationInSeconds: durationInSeconds,
            userGUID: `${jsonUserData?.uid}`,
            userId: `${jsonUserData?.userId}`,
            userFile: `${jsonUserData?.file}`,
            sessionActive: `${sessionActive}`,
          },
        });
        appInsights.flush();
      }
    }
  },

  afterAnythingElse: (messageSender, messageText) => {
    if (jsonUserData === null) jsonUserData = getUserData();

    appInsights.trackPageView({
      name: "AfterAnythingElse",
      properties: {
        messageSender: `${messageSender}`,
        messageText: `${messageText}`,
        userLoS: `${jsonUserData?.los}`,
        userGUID: `${jsonUserData?.uid}`,
        userId: `${jsonUserData?.userId}`,
        userFile: `${jsonUserData?.file}`,
        userSession: `${userSession}`,
      },
    });
    appInsights.flush();
  },
};

export { reactPlugin, appInsightsTracker, setUserSession };
