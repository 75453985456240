import axios from "axios";

const functionURL = `${process.env.REACT_APP_BACK_API}functions/new`;
export const postFunctions = async ({ option, email, otherOption, token }) => {
  const jsonData = {};
  if (otherOption?.length > 0) option.push(`Otro: ${otherOption}`);

  jsonData.email = email;
  jsonData.option = JSON.stringify(option);

  // Axios post request to save the bug form data from BugsView
  try {
    const responseFunctions = await axios({
      method: "POST",
      url: functionURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: jsonData,
    });

    return responseFunctions;
  } catch (err) {
    return err;
  }
};
