import { createContext, useState } from "react";

export const BotsContext = createContext(/** @type {ReturnType<typeof useBots>} */ ({}));

/**
 * Line of service object
 * 
 * @typedef {object} LoS
 * @property {string} id UUID for this line of service
 * @property {string} name Line of service name
 */

/**
 * A singular bot object
 * Received over IPC, itself queried from /api/ideas
 * Augmented with local data like downloading
 * 
 * @typedef {object} Bot
 * @property {string} id UUID for the bot object
 * @property {string} name Bot title
 * @property {string} description Bot description
 * @property {string} tags Hash tags, #-prefixed and space-separated
 * @property {string} version Version string
 * @property {string} versionTimestamp Unix timestamp. Yes, it's a string
 * @property {LoS} los The line of service scope for this bot
 * @property {boolean} downloaded Whether the bot is currently installed
 */

function useBots() {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);
    const [allBots, setAllBots] = useState(/** @type {Bot[]} */ ([]));
    const [isLoading, setIsLoading] = useState(true);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    // TODO: setAllBots = useCallback() wrapper that uses io-js to validate ipc response

    return {
        /**
         * Whether a bot is currently being downloaded
         */
        isDownloading,
        /** 
         * Set if a bot is currently being downloaded or not
         */
        setIsDownloading,
        /**
         * Whether a bot is currently running
         */
        isExecuting,
        /**
         * Set if a bot is being executed or not
         */
        setIsExecuting,
        /**
         * List of all available bots/ideas, downloaded or not
         */
        allBots,
        /**
         * Set the list of all available bots, received over IPC or API
         */
        setAllBots,
        /**
         * Whether the bots list hasn't been loaded yet or is being loaded
         */
        isLoading,
        /**
         * Set the loading state of the bots.
         */
        setIsLoading,
        /**
         * Whether an error popup is showing. Currently seems to be unused
         */
        showErrorPopup,
        /**
         * Set the error popup state
         */
        setShowErrorPopup
    };
}

/**
 * Provider for bots global state in Digi Automation
 * 
 * @param {object} props
 * @param {React.ReactNode} props.children The subtree with the context 
 * @returns {React.ReactNode} Subtree wrapped in BotsState Provider
 */
export default function BotsProvider({ children }) {
    const bots = useBots();

    return (
        <BotsContext.Provider value={bots}>
            {children}
        </BotsContext.Provider>
    );
};
