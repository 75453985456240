import { useState, useEffect, useContext, useRef } from "react";
import { io } from "socket.io-client";
import { UserContext } from "../context/userContext";
import { SessionContext } from "../context/sessionContext";
import { checkValueForm } from "../helpers/checkValueForm";
import { getAvailableEditOptions } from "../helpers/forms/getAvailableEditOptions";
import { addResponsesToTempFormData } from "../helpers/forms/addResponsesToTempFormData";

import { postFormIdea } from "../services/formIdea/formIdeaService";
import { appInsightsTracker, setUserSession } from "../helpers/AppInsights";

const url = process.env.URL ? process.env.URL : "/";
export const socket = io.connect(url);

export const useSocket = () => {
  const {
    user,
    formData,
    setFormData,
    optionsCheckbox,
    currentlyInForm,
    setCurrentlyInForm,
    apiToken,
  } = useContext(UserContext);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [sessionData, setSessionData] = useState("");
  const [categoriesForm, setCategoriesForm] = useState([]);
  const [volverShouldBeGoToEditarPage, setVolverShouldBeGoToEditarPage] =
    useState(-1);
  const [comingFromEditarLast, setComingFromEditarLast] = useState(false);
  const [messages, setMessages] = useState([]);
  //is this state we store the answer of a edited field
  const [arrayEditedResponses] = useState([]);
  const [currentlyEditing, setCurrentlyEditing] = useState(false);
  const [totalOptions, setTotalOptions] = useState([]);
  const [lastPageOfSubBranchIsText, setLastPageOfSubBranchIsText] =
    useState("");
  const [optionEndOfSubBranch, setOptionEndOfSubBranch] = useState([]);
  const [firstTimeAfterReload, setFirstTimeAfterReload] = useState(true);
  const { setSessionValue, activeSession, setActiveSession } =
    useContext(SessionContext);

  const currentSession = true;
  const emitFlag = useRef(null);
  const checkReload =
    performance.getEntriesByType("navigation")[0].type === "reload";

  /* this function emits an event the first time the client is trying to join a room or when a message is sent */
  function emitMessage(event, obj) {
    let session_value_after_refresh = "";
    if (event === "joinRoom") {
      return socket.emit(event, obj);
    } else {
      var { name, option, text, isFinalSubBranch } = obj;
      if (sessionData === "" || sessionData === undefined) {
        let tempHistoryVariable = JSON.parse(
          sessionStorage.getItem("chatHistory")
        );
        session_value_after_refresh =
          tempHistoryVariable?.at(-1)?.session_value;
      }
      //this is to determine that the user is active and track the time it is in the app
      setActiveSession({ ...activeSession, chatted: true });

      if (
        ((optionEndOfSubBranch.includes(option) && option !== undefined) ||
          lastPageOfSubBranchIsText === true) &&
        currentlyEditing
      ) {
        //aca deberia ir y es diferente a volver?

        var textToShowIfEditing = option || text;
        option = "14276CodedResponse54373 G0 b4ck t0 svbm1t";
      }

      var optionsForEditing = getAvailableEditOptions(
        formData,
        currentlyEditing
      );

      let comingFromEditar = volverShouldBeGoToEditarPage;
      let numberOfPageAfterEditar = volverShouldBeGoToEditarPage;

      setLastPageOfSubBranchIsText(false);
      return socket.emit(event, {
        sessionData,
        session_value_after_refresh,
        name,
        option,
        text,
        textToShowIfEditing,
        currentSession,
        isFinalSubBranch,
        currentlyInForm,
        totalOptions,
        optionsForEditing,
        optionsCheckbox,
        comingFromEditar,
        comingFromEditarLast,
        numberOfPageAfterEditar,
        currentlyEditing,
      });
    }
  }
  /*check if sessionStorage has data about the user and establish a socket connection */
  useEffect(() => {
    if (!sessionStorage.getItem("userData") && Object.keys(user).length) {
      const userData = {
        name: user?.name,
        email: user?.cloudEmail?.toLowerCase(),
        office: user?.office,
        upwcjobtitle: user?.upwcjobtitle,
        los: user?.LOS,
        uid: user.uid,
        file: user?.file,
        userId: user?.userId,
      };
      sessionStorage.setItem("userData", JSON.stringify(userData));
      emitMessage("joinRoom", JSON.parse(sessionStorage.getItem("userData")));
      emitFlag.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });
    socket.on("message", (data) => {
      // TO SEND EDITED ANSWER
      sendAnswerEdited(data);
      //End
      //getting values for FORM
      ideaForm(data, false);
      //end of Form code

      setSessionData(data.session_value);
      setSessionValue(data.session_value);
      setUserSession(data.session_value);

      let temp = messages;
      temp.push({
        session_value: data.session_value,
        name: data.name,
        text: data.text || data.response?.plainText,
        type: data.response?.intent || "",
        options: data.response?.options || [],
        intent: data.response?.intent_match,
        inputType: data.response?.inputType,
        firstBubbleCheckbox: data.response?.firstBubbleCheckbox,
        showACheckbox: data.response?.showACheckbox,
        showAsButtons: data.response?.showAsButtons,
        messagesObjectFinal: data.response?.messagesObjectFinal,
        conInicio_: data.response?.conInicio_,
        id: data?.id,
      });

      if (sessionStorage.getItem("resetChat") && data.name !== "Digi") {
        temp.splice(1, temp.length - 2);
        window.sessionStorage.removeItem("resetChat");
      }
      setMessages([...temp]);
      sessionStorage.setItem(
        "chatHistoryToSend",
        JSON.stringify(
          [...temp].filter(
            (message) => message.session_value === data.session_value
          )
        )
      );
      sessionStorage.setItem("chatHistory", JSON.stringify([...temp]));

      if (data.response?.isForm) {
        sessionStorage.setItem("currentlyInsideAForm", JSON.stringify("yes"));
      } else {
        sessionStorage.removeItem("currentlyInsideAForm");
      }
    });
    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    return () => {
      if (performance.getEntriesByType("navigation")[0].type !== "reload") {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("message");
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (checkReload) {
      let messagesBeforeRefreshFromStorage = [];

      socket.on("messageAfterRefresh", (data) => {
        if (firstTimeAfterReload) {
          setFirstTimeAfterReload(false);
        }
        // TO SEND EDITED ANSWER
        sendAnswerEdited(data);
        //End
        //getting values for FORM
        ideaForm(data, true);
        //end of Form code

        setSessionData(data.session_value);
        setMessages((prevState) => [
          ...prevState,
          {
            session_value: data.session_value,
            name: data.name,
            text: data.text || data.response?.plainText,
            type: data.response?.intent || "",
            options: data.response?.options || [],
            intent: data.response?.intent_match,
            inputType: data.response?.inputType,
            firstBubbleCheckbox: data.response?.firstBubbleCheckbox,
            showACheckbox: data.response?.showACheckbox,
            showAsButtons: data.response?.showAsButtons,
            messagesObjectFinal: data.response?.messagesObjectFinal,
            conInicio_: data.response?.conInicio_, //this is to difference the bubble with inicio_ parameters
          },
        ]);

        if (sessionStorage.getItem("chatHistory")) {
          messagesBeforeRefreshFromStorage = JSON.parse(
            sessionStorage.getItem("chatHistory")
          );

          let messagesBeforeAndAfterRefresh = [
            ...messagesBeforeRefreshFromStorage,
            {
              session_value: data.session_value,
              name: data.name,
              text: data.text || data.response?.plainText,
              type: data.response?.intent || "",
              options: data.response?.options || [],
              intent: data.response?.intent_match,
              messagesObjectFinal: data.response?.messagesObjectFinal,
              conInicio_: data.response?.conInicio_,
            },
          ];

          sessionStorage.setItem(
            "chatHistory",
            JSON.stringify(messagesBeforeAndAfterRefresh)
          );
        }

        //function to set a variable in sessionStorage indicating that the users is in a form
        if (data.response?.isForm) {
          sessionStorage.setItem("currentlyInsideAForm", JSON.stringify("yes"));
        } else {
          sessionStorage.removeItem("currentlyInsideAForm");
        }
      });

      //to be fired only once once the "reconection is made"
      if (sessionStorage.getItem("currentlyInsideAForm")) {
        setCurrentlyInForm(false);
        const name = JSON.parse(sessionStorage.getItem("userData")).name;
        emitMessage("chat", { name: name, option: "Salir del formulario" });
      }
    }
    return () => {
      socket.off("messageAfterRefresh");
    };
    // eslint-disable-next-line
  }, [checkReload]);

  const ideaForm = (data, boolean) => {
    if (data.text === "Idea Form" || data.text === "Help Form") {
      sessionStorage.setItem("formType", JSON.stringify(data.text));
      sessionStorage.setItem("idForm", JSON.stringify(Date.now()));
    } else if (data.response?.isForm) {
      appInsightsTracker.logFormEvents(
        `${JSON.parse(sessionStorage.getItem("formType"))} events`,
        "Input text",
        data.response?.intent,
        JSON.parse(sessionStorage.getItem("idForm")),
        sessionData
      );
    } else if (
      data.name === "Digi" &&
      !data.response?.isForm &&
      data.response?.intent_match !== "Provides.Confirm"
    ) {
      sessionStorage.removeItem("formType");
      sessionStorage.removeItem("idForm");
    }
    let isValueForm = checkValueForm(data);
    //next is to set element insideForm in sessionStorage: let us know if user was in form when page reloads
    if (
      isValueForm &&
      data.response?.intent_match === "Editar datos ingresados"
    ) {
      sessionStorage.setItem("inForm", JSON.stringify("yes"));
    }
    let tempFormData = formData;
    let tempCategoriesForm = categoriesForm;
    if (data.response?.firstPageAfter_EditarDatosIngresados === true) {
      setVolverShouldBeGoToEditarPage(-1);
    } else {
      setVolverShouldBeGoToEditarPage(data.response?.numberOfPageAfterEditar);
    }
    setComingFromEditarLast(data.response?.comingFromEditar);

    //to set all states related with forms to null again
    if (data.response?.intent === "Menu Form") {
      setCurrentlyEditing(false);
      tempFormData.length = 0;
      setFormData([...tempFormData]);
      setOptionEndOfSubBranch([]);
      tempCategoriesForm.length = 0;
      setCategoriesForm([...tempCategoriesForm]);
      //tempCategoriesForm = ["Form-Name"]
    }

    if (isValueForm) {
      //we load the previousCategoriesState
      tempCategoriesForm.push(data?.response?.intent);
      setCategoriesForm([...tempCategoriesForm]);
    }

    if (isValueForm === "cleanPreviousValues") {
      tempFormData.length = 0;
      tempCategoriesForm.length = 0;
    } else if (isValueForm) {
      let jsObj = {};

      if (data.response?.intent_match === "Editar datos ingresados") {
        setCurrentlyEditing(true);
      }

      let nameOfKey = categoriesForm[categoriesForm.length - 2];
      //next is for the first key of the array
      if (nameOfKey === undefined) {
        nameOfKey = "Name-Form";
      }
      let tempArrayEditedResponses = arrayEditedResponses;

      //this function adds/changes the user answer in the formData state
      addResponsesToTempFormData(
        nameOfKey,
        jsObj,
        data,
        tempFormData,
        tempArrayEditedResponses
      );

      //volvemos a entrar a un form / salimos de un form /se envia un form: se vuelve a setear currently editing false
      if (data.response?.intent_match === "Index - Forms") {
        setCurrentlyEditing(false);
      }

      setFormData([...tempFormData]);
    }
    if (data?.response?.intent?.includes("-")) {
      if (
        data?.response?.intent.split("-")[1] === "Submit" ||
        data?.response?.intent === "Menu Form"
      ) {
        setCurrentlyEditing(false);
      }
    }
    if (data?.response?.intent_match === "Provides.Confirm") {
      setCurrentlyEditing(false);
      const callServicePostFormIdea = async (e) => {
        const userData = sessionStorage.getItem("userData");
        try {
          await postFormIdea(formData, userData, apiToken);
          appInsightsTracker.logFormEvents(
            `${JSON.parse(sessionStorage.getItem("formType"))} events`,
            "Submitted form",
            "Confirm input",
            JSON.parse(sessionStorage.getItem("idForm")),
            sessionData
          );
          tempFormData.length = 0;
          setFormData([...tempFormData]);
          setTotalOptions([]);
        } catch (err) {}
      };
      callServicePostFormIdea();
    }
  };

  function sendAnswerEdited(data) {
    //este if/else es otra logica: para establecer isForm y para establecer las opciones que se pueden seleccionar(en un state: totalOptions)
    if (
      data.name === "Digi" &&
      data.response?.isForm &&
      data.response?.originalUserText !== "Salir del formulario"
    ) {
      setCurrentlyInForm(true);
      if (data.response?.options?.length >= 2) {
        setTotalOptions(data.response?.options);
      }
    } else {
      setCurrentlyInForm(false);
    }

    if (data.response === "Welcome" || data.name !== "Digi") {
      return;
    }

    var indexOfSubstring;
    if (data.response?.indexOptionsFinalSubBranchInForm !== undefined) {
      indexOfSubstring = data.response?.indexOptionsFinalSubBranchInForm.map(
        (a) => a.indexBubble
      );
    }
    //(if editing) here we check is clicking one option the edit must end
    let optionResponse = [];
    if (indexOfSubstring !== -1 && indexOfSubstring !== undefined) {
      for (let i = 0; i < indexOfSubstring.length; i++) {
        optionResponse.push(data.response?.options[indexOfSubstring[i]]);
      }
      setOptionEndOfSubBranch([...optionResponse]);
    }
    if (data.response?.lastPageOfSubBranchIsText) {
      setLastPageOfSubBranchIsText(true);
    }
  }

  return {
    socket,
    sessionData,
    setSessionData,
    emitMessage,
    messages,
    setMessages,
    isConnected,
    setIsConnected,
    emitFlag,
    checkReload,
  };
};
