import { createContext, useState } from "react";
import { useSession } from "../hooks/useSession";

export const SessionContext = createContext(null);
const { Provider } = SessionContext;

// To see if the user is active or inactive.
const SessionProvider = ({ children }) => {
  const {
    activeSession,
    setActiveSession,
    showPopupNps,
    setShowPopupNps,
    sessionValue,
    setSessionValue,
  } = useSession();
  const [startTime, setStartTime] = useState(new Date());
  const [activeSessionAppInsights, setActiveSessionAppInsights] =
    useState(true);
  const [logOut, setLogOut] = useState(false);

  return (
    <Provider
      value={{
        sessionValue,
        setSessionValue,
        activeSession,
        setActiveSession,
        showPopupNps,
        setShowPopupNps,
        startTime,
        setStartTime,
        activeSessionAppInsights,
        setActiveSessionAppInsights,
        logOut,
        setLogOut,
      }}
    >
      {children}
    </Provider>
  );
};

export default SessionProvider;
