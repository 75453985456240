import { useState } from "react";
import { SearchSVG } from "../../styles/assets/icons";
import "./searchInput.scss";

/**
 *
 * @param {object} props
 * @param {unknown[]} props.list
 * @param {(searching: boolean) => void} props.setIsSearching
 * @param {(results: unknown[]) => void} props.setItemsSearched
 * @returns {React.ReactNode}
 */
const SearchInput = ({ list, setIsSearching, setItemsSearched }) => {
  const [searchText, setSearchText] = useState("");

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setSearchText(inputText);
    if (inputText.length > 0) setIsSearching(true);
    else setIsSearching(false);
    searchFunction(inputText);
  };

  const searchFunction = (text) => {
    if (text?.length > 0) {
      const results = list.filter((item) =>
        item.name?.toLowerCase().includes(text?.toLowerCase())
      );
      setItemsSearched(results);
    }
  };

  return (
    <div className="search-input-container">
      <div className="search-input-container__glass">
        <SearchSVG />
      </div>
      <input
        className="search-input-container__input"
        type="text"
        placeholder="Buscar por nombre..."
        value={searchText}
        onChange={handleInputChange}
      />
      <button
        className="search-input-container__button"
        onClick={searchFunction}
      >
        Search
      </button>
    </div>
  );
};

export default SearchInput;
