import { useContext, useEffect, useState } from "react";
import LockMedalCase from "./LockMedalCase";
import { ScreenContext } from "../../context/screenContext";
import { TickSVG } from "../../styles/assets/icons";
import { BackgroundAvatarSVG } from "../../styles/svg/avatarSVG";
import iconsProps from "../../styles/svg/icons-props.json";
import "./avatarOption.scss";

/**
 *
 * @param {object} props
 * @param {{ svg: string, unlocked: boolean, color: keyof typeof iconsProps }[]} props.caseElements
 * @param {React.Dispatch<React.SetStateAction<{ digi: string, back: string, filter: string }>>} props.setDigiConfig
 * @param {{ digi: string, back: string, filter: string }} props.digiConfig
 * @returns {React.ReactNode}
 */
const AvatarOption = ({ caseElements, setDigiConfig, digiConfig }) => {
    const [selectedIndex, setSelectedIndex] = useState(/** @type {number | null} */(null));
    const { theme } = useContext(ScreenContext);

    useEffect(() => {
        setSelectedIndex(
            caseElements.findIndex((el) => {
                if (el.svg === "AvatarSVG") return el.color === digiConfig.digi;
                else return el.color === digiConfig.back;
            })
        );
    }, [caseElements, digiConfig.back, digiConfig.digi]);

    /**
     * Avatar option click handler
     *
     * @param {{ svg: string, unlocked: boolean, color: string }} element
     * @param {number} index
     */
    const handleClick = (element, index) => {
        setSelectedIndex(index);
        switch (element.svg) {
            case "BackgroundAvatarSVG":
                setDigiConfig((prev) => ({
                    ...prev,
                    back: element.color,
                }));
                break;
            case "AvatarSVG":
                setDigiConfig((prev) => ({
                    ...prev,
                    digi: element.color,
                }));
                break;
            default:
                break;
        }
    };


    /**
     * Render function for medal
     *
     * @param {{ svg: string, unlocked: boolean, color: keyof typeof iconsProps }} element
     * @param {number} index
     */
    const medalContent = (element, index) => {
        const isSelected = selectedIndex === index;
        return (
            <div
                className="avatar-option-container__box__content__inside"
                style={{
                    backgroundColor: iconsProps[element.color]?.sky,

                    border:
                        isSelected ?
                            `3px solid ${theme.theme === "dark" ? "#393939" : "rgb(240, 238, 238)"
                            }` : undefined,
                }}
            >
                {element.svg === "BackgroundAvatarSVG" && <BackgroundAvatarSVG {...element} />}
            </div>
        );
    };

    return (
        <div className="avatar-option-container">
            {caseElements.map((el, index) => {
                return (
                    <div key={index} className="avatar-option-container__box">
                        {selectedIndex === index && (
                            <div className="avatar-option-container__box__tick">
                                <TickSVG />
                            </div>
                        )}
                        {el.unlocked ? (
                            <div
                                className="avatar-option-container__box__content"
                                onClick={() => {
                                    handleClick(el, index);
                                }}
                                style={
                                    selectedIndex === index
                                        ? {
                                            border: "4px solid #eb8c00",
                                        }
                                        : { border: "none" }
                                }
                            >
                                {medalContent(el, index)}
                            </div>
                        ) : (
                            <LockMedalCase />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default AvatarOption;
