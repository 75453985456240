// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-body-confirmation-nps {
  margin-top: 3rem;
  width: 46.375rem;
}

.nps-confirmation-title {
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 500;
}

.nps-confirmation-body-text {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
}

.container-buttons--nps-confirmation {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.container-close-bottom {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
}

@media screen and (max-width: 800px) {
  .container-body-confirmation-nps {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/commons/main-app-popup/nps-confirmation-popup/npsConfirmationPopup.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AACA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;AAEF;;AAAA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AAGF;;AAAA;EACE,eAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;AAGF;;AAAA;EACE;IACE,WAAA;EAGF;AACF","sourcesContent":[".container-body-confirmation-nps {\n  margin-top: 3rem;\n  width: 46.375rem;\n}\n\n.nps-confirmation-title {\n  display: flex;\n  justify-content: center;\n  font-size: 1.4rem;\n  font-weight: 500;\n}\n.nps-confirmation-body-text {\n  margin-top: 2rem;\n  display: flex;\n  justify-content: center;\n  font-size: 1.2rem;\n}\n.container-buttons--nps-confirmation {\n  display: flex;\n  justify-content: center;\n  margin-top: 2rem;\n}\n\n.container-close-bottom {\n  cursor: pointer;\n  position: absolute;\n  right: 8px;\n  top: 8px;\n}\n\n@media screen and (max-width: 800px) {\n  .container-body-confirmation-nps {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
