import { createContext } from "react";
/* Custom hook */
import { useAuth } from "../hooks/useAuth";
export const UserContext = createContext(null);
const { Provider } = UserContext;

// All the configuration fo the user while using the app.
const UserProvider = ({ children }) => {
  const {
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    setIsLoading,
    user,
    setUser,
    apiToken,
    clearStoredUser,
    theme,
    setTheme,
    formData,
    setFormData,
    optionsCheckbox,
    setOptionsCheckbox,
    currentlyInForm,
    setCurrentlyInForm,
    disableBubblesButtons,
    setDisableBubblesButtons,
    currentTopic,
    setCurrentTopic,
  } = useAuth();

  return (
    <Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        setIsLoading,
        user,
        setUser,
        apiToken,
        clearStoredUser,
        theme,
        setTheme,
        formData,
        setFormData,
        optionsCheckbox,
        setOptionsCheckbox,
        currentlyInForm,
        setCurrentlyInForm,
        disableBubblesButtons,
        setDisableBubblesButtons,
        currentTopic,
        setCurrentTopic,
      }}
    >
      {children}
    </Provider>
  );
};

export default UserProvider;
