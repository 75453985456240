import { ScreenContext } from "../../context/screenContext";
import { useContext } from "react";

//Background images
import background1 from "../assets/backgroundImagesDigi/background1.gif";
import background2 from "../assets/backgroundImagesDigi/background2.gif";
import background3 from "../assets/backgroundImagesDigi/background3.gif";
import background4 from "../assets/backgroundImagesDigi/background4.png";
import background5 from "../assets/backgroundImagesDigi/background5.png";
import background6 from "../assets/backgroundImagesDigi/background6.png";
import background7 from "../assets/backgroundImagesDigi/background7.png";

//Props
import iconsProps from "./icons-props.json";
//Set the background images to the props
iconsProps.background01.url = background1;
iconsProps.background02.url = background2;
iconsProps.background03.url = background3;
iconsProps.background04.url = background4;
iconsProps.background05.url = background5;
iconsProps.background06.url = background6;
iconsProps.background07.url = background7;
const Props = iconsProps;
var color = "";

// Digi SVG
const AvatarSVG = ({ ...props }) => {
  const containerProperties = {
    topPosition: ["customDigi", "leaderboardDigi", "logoutDigi"].includes(
      props.filter
    )
      ? "10%"
      : "auto",
    leftPosition: ["customDigi", "leaderboardDigi", "logoutDigi"].includes(
      props.filter
    )
      ? "10%"
      : "auto",
  };

  return (
    <svg
      width={
        ["customDigi", "leaderboardDigi", "logoutDigi"].includes(props.filter)
          ? "100%"
          : props.filter === "headerDigi"
          ? "56"
          : props.filter === "dot"
          ? "30"
          : "100"
      }
      height={
        ["customDigi", "leaderboardDigi", "logoutDigi"].includes(props.filter)
          ? "100%"
          : props.filter === "headerDigi"
          ? "56"
          : props.filter === "dot"
          ? "30"
          : "100"
      }
      viewBox="-1 -8 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: props.filter === "dot" ? "0 20 0 0" : "5.5 20 0 5",
        position: props.filter === "logoutDigi" ? "static" : "absolute",
        top: containerProperties.topPosition,
        left: containerProperties.leftPosition,
      }}
    >
      {props.filter === "iconDigi"
        ? (color = Props[props.color].digi)
        : (color = props.digi ? Props[props.digi].digi : "#7D7D7D")}

      <path
        d="M22.7684 37.4254C19.8868 38.2125 6.95452 40.6499 6.29983 38.2631C5.64796 35.8778 18.023 31.3963 20.9026 30.6081C23.784 29.8203 26.5377 30.7084 27.0509 32.5909C27.5665 34.4736 25.6497 36.6376 22.7684 37.4254Z"
        fill={color}
      />
      <path
        d="M60.0169 23.8482C62.8259 34.3314 56.2264 45.2084 45.2754 48.1427C34.3244 51.077 23.1683 44.9576 20.3594 34.4744C17.5504 23.9912 24.1515 13.1145 35.1026 10.1802C46.0536 7.24589 57.2079 13.365 60.0169 23.8482Z"
        fill={color}
      />
      <path
        d="M47.5804 25.7012C47.4723 25.7168 47.3604 25.7184 47.244 25.7062C45.1342 25.4582 43.2506 24.4102 41.9444 22.7551C40.6358 21.0999 40.0591 19.0366 40.321 16.9432L41.3377 8.79799C41.5977 6.70594 42.6656 4.83481 44.3425 3.52957C46.0195 2.22506 48.106 1.64312 50.2173 1.89078C50.9179 1.9726 51.4134 2.60277 51.3276 3.29647C51.2404 3.99056 50.6028 4.48611 49.9024 4.40503C48.4703 4.23627 47.0531 4.63176 45.9133 5.51805C44.7734 6.40434 44.0496 7.67491 43.872 9.09624L42.8551 17.2408C42.6792 18.6624 43.0691 20.0641 43.9591 21.1884C44.8464 22.3117 46.1253 23.0236 47.5587 23.1912C48.2593 23.273 48.7563 23.9028 48.669 24.5969C48.597 25.1782 48.1403 25.6205 47.5804 25.7012Z"
        fill={color}
      />
      <path
        d="M51.558 23.2153C52.248 28.0825 46.6039 32.9223 38.9486 34.0286C31.2945 35.1337 24.5288 32.0861 23.8388 27.2189C23.1491 22.3524 28.7951 17.5112 36.4482 16.4056C44.1021 15.2997 50.8682 18.3488 51.558 23.2153Z"
        fill="#2D2D2D"
      />
      <path
        d="M28.5051 26.4011C28.5584 26.7872 28.2878 27.145 27.8977 27.2022C27.5088 27.2583 27.1497 26.9912 27.094 26.605C27.0385 26.2195 27.3111 25.8603 27.6999 25.8042C28.0903 25.7477 28.4498 26.0163 28.5051 26.4011Z"
        fill={color}
      />
      <path
        d="M33.0842 25.941C33.1397 26.3265 32.8684 26.6845 32.4781 26.741C32.0877 26.7975 31.7286 26.5304 31.6731 26.1449C31.6198 25.7588 31.8902 25.4003 32.279 25.3441C32.6694 25.2876 33.03 25.5543 33.0842 25.941Z"
        fill={color}
      />
      <path
        d="M31.5479 24.5627C31.6009 24.9481 31.3308 25.3074 30.9404 25.3639C30.5516 25.42 30.1908 25.1526 30.1368 24.7667C30.0811 24.3804 30.3524 24.0224 30.7427 23.9659C31.133 23.9094 31.4922 24.1765 31.5479 24.5627Z"
        fill={color}
      />
      <path
        d="M29.7488 24.7297C29.8018 25.1151 29.5315 25.4736 29.1426 25.5297C28.7523 25.5862 28.3929 25.3184 28.3374 24.9329C28.2819 24.5474 28.5547 24.189 28.9436 24.1329C29.3337 24.0756 29.6931 24.3434 29.7488 24.7297Z"
        fill={color}
      />
      <path
        d="M42.277 24.4125C42.3325 24.798 42.0597 25.1564 41.6711 25.2133C41.2808 25.2698 40.9214 25.002 40.8659 24.6165C40.8104 24.2309 41.0832 23.8725 41.4719 23.8157C41.8622 23.7592 42.2213 24.0263 42.277 24.4125Z"
        fill={color}
      />
      <path
        d="M46.856 23.9517C46.9115 24.3372 46.6401 24.6952 46.2498 24.7517C45.861 24.8078 45.5003 24.5411 45.4461 24.1545C45.3908 23.7697 45.6619 23.411 46.0523 23.3544C46.4426 23.2979 46.8019 23.5658 46.856 23.9517Z"
        fill={color}
      />
      <path
        d="M45.319 22.573C45.3725 22.9598 45.1019 23.3176 44.7131 23.3737C44.3229 23.431 43.9614 23.1637 43.9079 22.7769C43.8546 22.3908 44.125 22.0322 44.5138 21.9761C44.9042 21.9196 45.2643 22.1872 45.319 22.573Z"
        fill={color}
      />
      <path
        d="M43.5195 22.7406C43.575 23.1261 43.3022 23.4845 42.9133 23.5406C42.5232 23.5978 42.1638 23.33 42.1081 22.9437C42.0549 22.5576 42.3252 22.1991 42.7141 22.143C43.1044 22.0865 43.4645 22.3541 43.5195 22.7406Z"
        fill={color}
      />
      <path
        d="M62.1341 32.9586C63.6018 35.5257 69.1206 47.3249 66.9456 48.5548C64.7687 49.7835 57.3878 39.0381 55.9201 36.4711C54.4504 33.9054 54.6524 31.0384 56.3673 30.0681C58.0845 29.0981 60.6657 30.3918 62.1341 32.9586Z"
        fill={color}
      />
    </svg>
  );
};

// Background SVG for Digi
const BackgroundAvatarSVG = ({ ...props }) => {
  const containerProperties = {
    transformProperty:
      props.filter === "leaderboardDigi" ? "translateX(-15%)" : "",
  };
  const { theme } = useContext(ScreenContext);
  return (
    <svg
      width={
        props.filter === "customDigi"
          ? "100%"
          : props.filter === "leaderboardDigi"
          ? "100%"
          : props.filter === "headerDigi"
          ? "56"
          : "100"
      }
      height={
        props.filter === "customDigi"
          ? "100%"
          : props.filter === "leaderboardDigi"
          ? "100%"
          : props.filter === "headerDigi"
          ? "56"
          : "100"
      }
      viewBox={
        props.filter === "iconDigi"
          ? "0 0 89 100"
          : props.filter === "leaderboardDigi"
          ? "0 0 89 100"
          : "0 0 100 100"
      }
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {Props[props.back]?.sky || Props[props.color]?.sky ? (
        <rect
          width={props.filter === "leaderboardDigi" ? "120" : "100"}
          height="100"
          fill={Props[props.back]?.sky || Props[props.color]?.sky}
          style={{
            transform: containerProperties.transformProperty,
          }}
        />
      ) : Props[props.back]?.url || Props[props.color]?.url ? (
        <image
          href={Props[props.back]?.url || Props[props.color]?.url}
          width="120"
          style={{
            transform: containerProperties.transformProperty,
          }}
        />
      ) : (
        <rect width="100" height="100" fill="#FFB600" />
      )}
      <path
        d={
          props.filter === "leaderboardDigi"
            ? "M -13.5 65 H 106.7 V 100 H -13.5 V 100 Z"
            : "M 0 65 H 100 V 100 H -1 V 100 Z"
        }
        fill={
          Props[props.back]?.floor || Props[props.color]?.floor || "#E7A500"
        }
      />
      <svg
        width="95"
        height="95"
        viewBox="0 90 453 612"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {props.filter !== "iconDigi" && (
          <ellipse
            cx="230"
            cy="600"
            rx="99.4412"
            ry="8.64143"
            fill={props.back ? Props[props.back].shadowElipsis : "#B68200"}
          />
        )}
      </svg>
      {["headerDigi", "customDigi"].includes(props.filter) && (
        <svg height="100" width="100">
          <circle
            cx="50"
            cy="50"
            r="60"
            stroke={
              theme.theme === "light" &&
              ["headerDigi", "customDigi"].includes(props.filter)
                ? "#FAFAFA"
                : theme.theme === "light"
                ? "#ffff"
                : theme.theme === "dark" &&
                  ["headerDigi", "customDigi"].includes(props.filter)
                ? "#2D2D2D"
                : "#464646"
            }
            strokeWidth="22"
            fill="none"
          />
        </svg>
      )}
    </svg>
  );
};

const avatarColors = [
  { level: 1, avatar: { svg: "AvatarSVG", color: "gray", unlocked: false } },
  { level: 2, avatar: { svg: "AvatarSVG", color: "rose", unlocked: false } },
  { level: 2, avatar: { svg: "AvatarSVG", color: "orange", unlocked: false } },
];

const avatarBackgrounds = [
  {
    level: 1,
    avatar: { svg: "BackgroundAvatarSVG", color: "yellow", unlocked: false },
  },
  {
    level: 1,
    avatar: { svg: "BackgroundAvatarSVG", color: "lightGray", unlocked: false },
  },
  {
    level: 2,
    avatar: { svg: "BackgroundAvatarSVG", color: "orange", unlocked: false },
  },
  {
    level: 2,
    avatar: { svg: "BackgroundAvatarSVG", color: "rose", unlocked: false },
  },
  {
    level: 3,
    avatar: {
      svg: "BackgroundAvatarSVG",
      color: "background04",
      unlocked: false,
    },
  },
  {
    level: 3,
    avatar: {
      svg: "BackgroundAvatarSVG",
      color: "background05",
      unlocked: false,
    },
  },
  {
    level: 3,
    avatar: {
      svg: "BackgroundAvatarSVG",
      color: "background06",
      unlocked: false,
    },
  },
  {
    level: 4,
    avatar: {
      svg: "BackgroundAvatarSVG",
      color: "background07",
      unlocked: false,
    },
  },
  {
    level: 4,
    avatar: {
      svg: "BackgroundAvatarSVG",
      color: "background01",
      unlocked: false,
    },
  },
  {
    level: 4,
    avatar: {
      svg: "BackgroundAvatarSVG",
      color: "background02",
      unlocked: false,
    },
  },
  {
    level: 5,
    avatar: {
      svg: "BackgroundAvatarSVG",
      color: "background03",
      unlocked: false,
    },
  },
];

export { AvatarSVG, BackgroundAvatarSVG, avatarColors, avatarBackgrounds };
