import axios from "axios";
const sessionURL = `${process.env.REACT_APP_BACK_API}user/session`;

const userURL = `${process.env.REACT_APP_BACK_API}user`;

// Session created
export const createNewSession = async (email, token) => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `${sessionURL}/new`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { email },
    });

    return data;
  } catch (error) {
    return error;
  }
};

// IS IT USED?
export const getUserId = async (mail, token) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${userURL}/user_id`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: { mail },
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// IS IT USED?
// Save the chat that person had with Digi
export const sendHistorySession = async (historyChat, token) => {
  try {
    const response = await axios({
      method: "POST",
      url: sessionURL,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: { historyChat },
    });

    return response;
  } catch (error) {
    return error;
  }
};

// IS IT USED?
// To extract a session from a user
export const getValidSession = async ({ email, session_value, token }) => {
  try {
    const response = await axios({
      method: "GET",
      url: sessionURL,
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        user: email,
        session: session_value,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};
