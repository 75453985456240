import { createContext, useState } from "react";

export const PopupsContext = createContext({});
const { Provider } = PopupsContext;

// Confirmation popup for confirmation of changes.
const PopupsProvider = ({ children }) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState({
    show: null,
    response: "",
    message: null,
  });
  const [showCustomModal, setShowCustomModal] = useState({
    show: null,
    message: null,
  });
  const [showPopupStats, setShowPopupStats] = useState({
    show: null,
    stats: null,
    isMonthly: true,
    isExternal: null,
  });

  return (
    <Provider
      value={{
        showConfirmationPopup,
        setShowConfirmationPopup,
        showCustomModal,
        setShowCustomModal,
        showPopupStats,
        setShowPopupStats,
      }}
    >
      {children}
    </Provider>
  );
};

export default PopupsProvider;
