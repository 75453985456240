import axios from "axios";

/**
 *
 * @param {Object} data
 * @returns {AxiosResponse<any, any>}
 */

export const formURL = process.env.REACT_APP_ISSUE_FORM;

export const postIssueForm = async (data) => {
  try {
    const response = await axios({
      method: "POST",
      url: formURL,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
