import { createContext } from "react";
import { useSocket } from "../hooks/useSocket";

export const SocketContext = createContext(null);
const { Provider } = SocketContext;

// Socket to send message
const SocketProvider = ({ children }) => {
  const {
    socket,
    sessionData,
    setSessionData,
    emitMessage,
    messages,
    setMessages,
    isConnected,
    setIsConnected,
    emitFlag,
    checkReload,
  } = useSocket();

  return (
    <Provider
      value={{
        socket,
        sessionData,
        setSessionData,
        emitMessage,
        messages,
        setMessages,
        isConnected,
        setIsConnected,
        emitFlag,
        checkReload,
      }}
    >
      {children}
    </Provider>
  );
};

export default SocketProvider;
