import { useEffect, useState } from "react";
import { DeleteLineSVG } from "../../../styles/assets/icons";
import { getStatusMessage, StatusEnum } from "./getStatusMessage";
import "./statusMessage.scss";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param {object} props
 * @param {string} props.status
 * @param {string} props.automationName
 * @param {number} props.percentage
 * @param {string} props.progressTask
 * @param {() => void} props.closeMessage
 * @param {string | null} props.customTitle
 * @param {string | null} props.customSubtitle
 * @returns {React.ReactNode}
 */
const StatusMessage = ({
  status,
  automationName,
  percentage,
  progressTask,
  closeMessage,
  customTitle,
  customSubtitle,
}) => {
  const [content, setContent] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setContent(
      getStatusMessage(status, automationName, customTitle, customSubtitle)
    );
  }, [status, automationName, customTitle, customSubtitle]);

  return (
    <div
      className="status-message-container"
      style={{ backgroundColor: content.backgroundColor }}
    >
      <div className="status-message-container__header">
        <div className="status-message-container__header__info">
          {content.icon}
          <div className="status-message-container__header__info__texts">
            <p className="status-message-container__header__info__texts__title">
              <b>{content.title || customTitle}</b> &nbsp;
              {status !== StatusEnum.PROGRESS_CUSTOM &&
                status !== StatusEnum.INFO_CUSTOM &&
                content.subtitle}
              {status === StatusEnum.INFO_CUSTOM ||
              status === StatusEnum.E_CUSTOM
                ? progressTask
                : null}
            </p>
            {status === StatusEnum.INFO_CUSTOM && (
              <p className="status-message-container__header__info__texts__help-link">
                Si necesitás asistencia, podés solicitarla completando{" "}
                <b>
                  <u onClick={() => navigate("/bugs")}>este formulario</u>
                </b>
                .
              </p>
            )}
          </div>
        </div>
        <DeleteLineSVG onClickFunction={closeMessage} />
      </div>
      {status === StatusEnum.PROGRESS_CUSTOM && (
        <div className="status-message-container__progress">
          <div className="status-message-container__progress__bar">
            <div
              className="status-message-container__progress__bar__fill"
              style={{ width: `${percentage ? percentage : 0}%` }}
            >
              <div className="status-message-container__progress__bar__fill__lines"></div>
            </div>
          </div>
          <div className="status-message-container__progress__subtitles">
            <p className="status-message-container__progress__subtitles__subtitle1">
              {content.subtitle || customSubtitle}
            </p>
            <p className="status-message-container__progress__subtitles__subtitle2">
              {progressTask}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusMessage;
