// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-icon {
  fill: #7d7d7d;
  cursor: pointer;
}

.svg-div:hover .custom-icon {
  fill: #ffb600;
}

.custom-mic-icon {
  margin-bottom: 2.1em;
}
.custom-mic-icon:hover {
  fill: #ff6e20;
}

.error-icon,
.success-icon {
  fill: #ffffff;
}

.icon-arrow-loading {
  fill: none;
  vertical-align: middle;
}

.functionSVG_path {
  transform: scale(0.75) translate(7.5px, 7.5px);
}

.sidebar-icon {
  fill: var(--color-text-sidemenu);
}

.sidebar-icon-sunny {
  stroke: white;
}

.svg-copy {
  fill: var(--color-text-sidemenu);
}

.svg-copy:hover {
  cursor: pointer;
  fill: #ffb600;
}

.chevron-svg {
  margin-left: 0.5rem;
}

.money-svg path,
.vda-svg path,
.dca-svg path,
.chevron-svg path {
  fill: var(--color-text-sidemenu);
}`, "",{"version":3,"sources":["webpack://./src/styles/svg/icons.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF;;AAGE;EACE,aAAA;AAAJ;;AAIA;EACE,oBAAA;AADF;AAGE;EACE,aAAA;AADJ;;AAKA;;EAEE,aAAA;AAFF;;AAKA;EACE,UAAA;EACA,sBAAA;AAFF;;AAKA;EACE,8CAAA;AAFF;;AAKA;EACE,gCAAA;AAFF;;AAKA;EACE,aAAA;AAFF;;AAKA;EACE,gCAAA;AAFF;;AAKA;EACE,eAAA;EACA,aAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AASE;;;;EACE,gCAAA;AAHJ","sourcesContent":[".custom-icon {\n  fill: #7d7d7d;\n  cursor: pointer;\n}\n\n.svg-div:hover {\n  .custom-icon {\n    fill: #ffb600;\n  }\n}\n\n.custom-mic-icon {\n  margin-bottom: 2.1em;\n\n  &:hover {\n    fill: #ff6e20;\n  }\n}\n\n.error-icon,\n.success-icon {\n  fill: #ffffff;\n}\n\n.icon-arrow-loading {\n  fill: none;\n  vertical-align: middle;\n}\n\n.functionSVG_path {\n  transform: scale(0.75) translate(7.5px, 7.5px);\n}\n\n.sidebar-icon {\n  fill: var(--color-text-sidemenu);\n}\n\n.sidebar-icon-sunny {\n  stroke: white;\n}\n\n.svg-copy {\n  fill: var(--color-text-sidemenu);\n}\n\n.svg-copy:hover {\n  cursor: pointer;\n  fill: #ffb600;\n}\n\n.chevron-svg {\n  margin-left: 0.5rem;\n}\n\n.money-svg,\n.vda-svg,\n.dca-svg,\n.chevron-svg {\n  path {\n    fill: var(--color-text-sidemenu);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
