// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-dot, .message-dot--right, .message-dot--invisible {
  border-radius: 50%;
  background-color: var(--message-digi);
  height: 22px;
  width: 22px;
  align-self: flex-start;
  position: relative;
}
.message-dot--right, .message-dot--invisible {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/commons/chat-components/messages/DotMessage/DotMesagge.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qCAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;AACF;AACE;EAGE,kBAAA;AADJ","sourcesContent":[".message-dot {\n  border-radius: 50%;\n  background-color: var(--message-digi);\n  height: 22px;\n  width: 22px;\n  align-self: flex-start;\n  position: relative;\n\n  &--right,\n  &--invisible {\n    @extend .message-dot;\n    visibility: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
