import RadioOptions from "../commons/chat-components/messages/RadioOptions/RadioOptions";
import DotMessage from "../commons/chat-components/messages/DotMessage/DotMessage";

/**
 * Warning message displayed when the content is about TimeCapture
 *
 * @param {string} name
 * @returns {React.ReactNode}
 */

export const contentWithTC = (name) => {
  const messageBlock = () => {
    return (
      <p className="warning">
        <span>
          *Nos estamos poniendo al día sobre la nueva herramienta para cargar
          horas. Si querés conocer más sobre la flamante herramienta podés
          ingresar a la sección de Replicon en Sharepoint desde la opción de
          Menú.
          <RadioOptions
            containerDirection={"horizontal"}
            length={1}
            indexElementBubble={1}
            messageObject={{ content: ["Quiero conocer Replicon."] }}
          />
        </span>
      </p>
    );
  };
  return (
    <>
      {name !== "Digi" ? (
        <div className={"orientation"}>
          <div className={"message-container"}>
            <DotMessage type={"message-dot"} />
            <div className={"message-info"}>{messageBlock()}</div>
          </div>
        </div>
      ) : (
        <>
          <br />
          {messageBlock()}
        </>
      )}
    </>
  );
}

export const contentWithSS = (name) => {
  const messageBlock = () => {
    return (
      <p className="warning">
        <span>
          *¡Hola! ¡No te asustes! Queremos decirte que para el AC Buenos Aires, por ahora, no hay cambios sobre Snapshots, cómo solicitarlos y nuestro Professional framework. Para mayor información, consultá <a href="https://pwceur.sharepoint.com/sites/AR-xLoS-ccsds/humancap/pwcprofessional/SitePages/Performance-Year-and-Career-Development.aspx?xsdata=MDV8MDJ8fDdmNjRiOWVhMWRkMzQ2M2RjNzJlMDhkY2JiZDE3NTZjfDUxMzI5NGEwM2UyMDQxYjJhOTcwNmQzMGJmMTU0NmZhfDB8MHw2Mzg1OTE3NTU0NzIzODk5MzR8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPalF4WW1FeU0yWTRMVGcwTjJNdE5EQTFZaTFpWldVMExXRTRObU00TURRelpUZzNObDgxTkdKa056RTFNUzAxWkROakxUUXpZVGN0WW1FeVl5MDVPR1k0T1RreFltSTNNak5BZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN5TXpVM09EYzBOVGsxT0E9PXwyOGE3MzYzY2I1ODg0ZTVhYzcyZTA4ZGNiYmQxNzU2Y3w5MjFiODQxYWMxYmQ0NmViYmM3NWZiNmZiYzYyYWFjNA%3d%3d&sdata=eFA2MlB6TzZKWDU5UWV3WThQU0dxWUpjUGtTb3FEQ2ZMWm1oQ3cvMnFCYz0%3d&ovuser=513294a0-3e20-41b2-a970-6d30bf1546fa%2cnora.martinez%40pwc.com&OR=Teams-HL&CT=1723656962588&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA3MTEyODgyNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3d%3d&SafelinksUrl=https%3a%2f%2fpwceur.sharepoint.com%2fsites%2fAR-xLoS-ccsds%2fhumancap%2fpwcprofessional%2fSitePages%2fPerformance-Year-and-Career-Development.aspx" target="_blank" rel="noreferrer" className="message-link"> este sitio</a>
        </span>
      </p>
    );
  };
  return (
    <>
      {name !== "Digi" ? (
        <div className={"orientation"}>
          <div className={"message-container"}>
            <DotMessage type={"message-dot"} />
            <div className={"message-info"}>{messageBlock()}</div>
          </div>
        </div>
      ) : (
        <>
          <br />
          {messageBlock()}
        </>
      )}
    </>
  );
};
export const contentWithPF = (name) => {
  const messageBlock = () => {
    return (
      <p className="warning">
        <span>
          *¡Hola! ¡No te asustes! Queremos decirte que para el AC Buenos Aires, por ahora, no hay cambios sobre Snapshots, cómo solicitarlos y nuestro Professional framework. Para mayor información, consultá <a href="https://pwceur.sharepoint.com/sites/AR-xLoS-ccsds/humancap/pwcprofessional/SitePages/Performance-Year-and-Career-Development.aspx?xsdata=MDV8MDJ8fDdmNjRiOWVhMWRkMzQ2M2RjNzJlMDhkY2JiZDE3NTZjfDUxMzI5NGEwM2UyMDQxYjJhOTcwNmQzMGJmMTU0NmZhfDB8MHw2Mzg1OTE3NTU0NzIzODk5MzR8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPalF4WW1FeU0yWTRMVGcwTjJNdE5EQTFZaTFpWldVMExXRTRObU00TURRelpUZzNObDgxTkdKa056RTFNUzAxWkROakxUUXpZVGN0WW1FeVl5MDVPR1k0T1RreFltSTNNak5BZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN5TXpVM09EYzBOVGsxT0E9PXwyOGE3MzYzY2I1ODg0ZTVhYzcyZTA4ZGNiYmQxNzU2Y3w5MjFiODQxYWMxYmQ0NmViYmM3NWZiNmZiYzYyYWFjNA%3d%3d&sdata=eFA2MlB6TzZKWDU5UWV3WThQU0dxWUpjUGtTb3FEQ2ZMWm1oQ3cvMnFCYz0%3d&ovuser=513294a0-3e20-41b2-a970-6d30bf1546fa%2cnora.martinez%40pwc.com&OR=Teams-HL&CT=1723656962588&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA3MTEyODgyNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3d%3d&SafelinksUrl=https%3a%2f%2fpwceur.sharepoint.com%2fsites%2fAR-xLoS-ccsds%2fhumancap%2fpwcprofessional%2fSitePages%2fPerformance-Year-and-Career-Development.aspx" target="_blank" rel="noreferrer" className="message-link"> este sitio</a>
        </span>
      </p>
    );
  };
  return (
    <>
      {name !== "Digi" ? (
        <div className={"orientation"}>
          <div className={"message-container"}>
            <DotMessage type={"message-dot"} />
            <div className={"message-info"}>{messageBlock()}</div>
          </div>
        </div>
      ) : (
        <>
          <br />
          {messageBlock()}
        </>
      )}
    </>
  );
};
export const contentWithRL = (name) => {
  const messageBlock = () => {
    return (
      <p className="warning">
        <span>
          *¡Hola! ¡No te asustes! Queremos decirte que para el AC Buenos Aires, por ahora, no hay cambios sobre Snapshots, cómo solicitarlos y nuestro Professional framework. Para mayor información, consultá <a href="https://pwceur.sharepoint.com/sites/AR-xLoS-ccsds/humancap/pwcprofessional/SitePages/Performance-Year-and-Career-Development.aspx?xsdata=MDV8MDJ8fDdmNjRiOWVhMWRkMzQ2M2RjNzJlMDhkY2JiZDE3NTZjfDUxMzI5NGEwM2UyMDQxYjJhOTcwNmQzMGJmMTU0NmZhfDB8MHw2Mzg1OTE3NTU0NzIzODk5MzR8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPalF4WW1FeU0yWTRMVGcwTjJNdE5EQTFZaTFpWldVMExXRTRObU00TURRelpUZzNObDgxTkdKa056RTFNUzAxWkROakxUUXpZVGN0WW1FeVl5MDVPR1k0T1RreFltSTNNak5BZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN5TXpVM09EYzBOVGsxT0E9PXwyOGE3MzYzY2I1ODg0ZTVhYzcyZTA4ZGNiYmQxNzU2Y3w5MjFiODQxYWMxYmQ0NmViYmM3NWZiNmZiYzYyYWFjNA%3d%3d&sdata=eFA2MlB6TzZKWDU5UWV3WThQU0dxWUpjUGtTb3FEQ2ZMWm1oQ3cvMnFCYz0%3d&ovuser=513294a0-3e20-41b2-a970-6d30bf1546fa%2cnora.martinez%40pwc.com&OR=Teams-HL&CT=1723656962588&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA3MTEyODgyNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3d%3d&SafelinksUrl=https%3a%2f%2fpwceur.sharepoint.com%2fsites%2fAR-xLoS-ccsds%2fhumancap%2fpwcprofessional%2fSitePages%2fPerformance-Year-and-Career-Development.aspx" target="_blank" rel="noreferrer" className="message-link"> este sitio</a>
        </span>
      </p>
    );
  };
  return (
    <>
      {name !== "Digi" ? (
        <div className={"orientation"}>
          <div className={"message-container"}>
            <DotMessage type={"message-dot"} />
            <div className={"message-info"}>{messageBlock()}</div>
          </div>
        </div>
      ) : (
        <>
          <br />
          {messageBlock()}
        </>
      )}
    </>
  );
}
