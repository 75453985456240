import {
  ErrorCirlceSVG,
  InfoCircleSVG,
  SuccessCirlceSVG,
} from "../../../styles/assets/icons";

const StatusEnum = {
  E_DOWNLOAD: "download-error",
  E_INSTALL: "installation-error",
  E_CUSTOM: "custom-error",
  INFO_CUSTOM: "custom-information",
  S_DOWNLOAD: "successful-download",
  S_CUSTOM: "custom-successful",
  INFO: "information",
  PROGRESS_CUSTOM: "custom-progress",
};

const allStates = {
  [StatusEnum.E_CUSTOM]: {
    backgroundColor: "#F2D7C8",
    icon: <ErrorCirlceSVG />,
    title: "Error.",
    subtitle: null,
  },
  [StatusEnum.INFO_CUSTOM]: {
    backgroundColor: "#F8EAD5",
    icon: <InfoCircleSVG />,
    title: "Información.",
  },
  [StatusEnum.E_DOWNLOAD]: {
    backgroundColor: "#F2D7C8",
    icon: <ErrorCirlceSVG />,
    title: "Error en la descarga.",
    subtitle:
      "La automatización %AUTOMATION_NAME% no se pudo descargar correctamente. Por favor intenta nuevamente más tarde.",
  },
  [StatusEnum.E_INSTALL]: {
    backgroundColor: "#F2D7C8",
    icon: <ErrorCirlceSVG />,
    title: "Error en la instalación.",
    subtitle:
      "La automatización %AUTOMATION_NAME% no se pudo instalar correctamente. Por favor intenta nuevamente más tarde.",
  },
  [StatusEnum.S_DOWNLOAD]: {
    backgroundColor: "#DFF6DD",
    icon: <SuccessCirlceSVG />,
    title: "Descarga exitosa.",
    subtitle:
      "La automatización %AUTOMATION_NAME% ya se encuentra disponible para ejecutar.",
  },
  [StatusEnum.S_CUSTOM]: {
    backgroundColor: "#DFF6DD",
    icon: <SuccessCirlceSVG />,
    title: null,
    subtitle: null,
  },
  [StatusEnum.INFO]: {
    backgroundColor: "#F8EAD5",
    icon: <InfoCircleSVG />,
    title: "La ejecución de la automatización se realizó con algunos errores.",
    subtitle: "Por favor revisá el log de ejecución para más información.",
  },
  [StatusEnum.PROGRESS_CUSTOM]: {
    backgroundColor: "#EBEBEB",
    icon: null,
    title: null,
    subtitle: null,
  },
};

/**
 *
 * @param {string} status
 * @param {string} automationName
 * @param {string | null} customTitle
 * @param {string | null} customSubtitle
 * @returns {{ backgroundColor: string, icon: React.ReactNode, title: string | null, subtitle?: string | null }}
 */
const getStatusMessage = (
  status,
  automationName,
  customTitle,
  customSubtitle
) => {
  try {
    let messageContent = { ...allStates[status] };
    if (automationName && customTitle?.includes("%AUTOMATION_NAME%")) {
      messageContent.title = customTitle.replace(
        "%AUTOMATION_NAME%",
        automationName
      );
    } else if (customTitle) {
      messageContent.title = customTitle;
    }
    if (automationName && customSubtitle?.includes("%AUTOMATION_NAME%")) {
      messageContent.subtitle = customSubtitle.replace(
        "%AUTOMATION_NAME%",
        automationName
      );
    } else if (customSubtitle) {
      messageContent.subtitle = customSubtitle;
    } else if (automationName) {
      messageContent.subtitle = messageContent.subtitle?.replace(
        "%AUTOMATION_NAME%",
        automationName
      );
    }
    if (!messageContent) {
      messageContent = {
        backgroundColor: "#F8EAD5",
        icon: <InfoCircleSVG />,
        title: "",
        subtitle: "",
      };
    }
    return messageContent;
  } catch (err) {
    return {
      backgroundColor: "#F8EAD5",
      icon: <InfoCircleSVG />,
      title: "",
      subtitle: "",
    };
  }
};

export { getStatusMessage, StatusEnum };
