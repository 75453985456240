import axios from "axios";

// NPS is the feedback popup
const customURL = `${process.env.REACT_APP_BACK_API}feedback/new`;

const getLastNPSURL = `${process.env.REACT_APP_BACK_API}feedback/get`;

// Axios post request to save the nps data
export const sendNps = async (email, scoreObject, token) => {
  const jsonData = {};

  jsonData.mail = email;
  jsonData.points = scoreObject.scoreNps;
  jsonData.message = scoreObject.commentNps;
  jsonData.date = new Date().toISOString().split("T")[0];

  try {
    const responseFunctions = await axios({
      method: "POST",
      url: customURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: jsonData,
    });

    return responseFunctions;
  } catch (err) {
    return err;
  }
};

export const getLastDateNPS = async (email, token) => {
  const mail = email;
  // Axios get request to get the customization of the user.
  try {
    const customResponse = await axios({
      method: "GET",
      url: getLastNPSURL,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${token}`,
      },
      params: {
        mail,
      },
    });
    return customResponse;
  } catch (err) {
    return err;
  }
};
