import React, { useEffect, useRef, useContext, useState } from "react";
import { SocketContext } from "../../context/socketContext";
import { Col } from "react-bootstrap";
import WelcomeMessage from "../../commons/chat-components/messages/WelcomeMessage/WelcomeMessage";
import InputDeck from "../../commons/chat-components/input-deck/InputDeck";
import Message from "../../commons/chat-components/messages/Message";
import ConfirmationPopup from "../../commons/confirmation-popup/ConfirmationPopup";
import { ScreenContext } from "../../context/screenContext";
import { appInsightsTracker } from "../../helpers/AppInsights";
import { UserContext } from "../../context/userContext";
import { SessionContext } from "../../context/sessionContext";
import { deleteToken } from "../../services/login/loginService";
import "./chatView.scss";

const ChatView = () => {
  const { socket, emitMessage, messages, setMessages, emitFlag, checkReload } =
    useContext(SocketContext);
  const { isChatLoading, setIsChatLoading } = useContext(ScreenContext);
  const { setCurrentTopic, apiToken } = useContext(UserContext);
  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);
  const [afterAnythingElseFlag, setAfterAnythingElseFlag] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    appInsightsTracker.pageView("Chatbot");
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Chatbot",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Chatbot",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Chatbot",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  useEffect(() => {
    if (sessionStorage.getItem("userData") && !emitFlag.current) {
      if (sessionStorage.getItem("chatHistory")) {
        let currentSessionValue = JSON.parse(
          sessionStorage.getItem("chatHistory")
        )[0]?.session_value;

        //if a session value already exists, another 'joinRoom' event won't be emmited to the server
        if (!currentSessionValue) {
          emitMessage(
            "joinRoom",
            JSON.parse(sessionStorage.getItem("userData"))
          );
        }
        if (currentSessionValue && checkReload) {
          setMessages(JSON.parse(sessionStorage.getItem("chatHistory")));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, checkReload]);

  useEffect(() => {
    if (messages && sessionStorage.getItem("userData")) {
      let lastMessage = messages.at(-1);
      if (
        lastMessage?.name !==
        JSON.parse(sessionStorage.getItem("userData")).name
      )
        setIsChatLoading(false);
      else setIsChatLoading(true);
    }
  }, [messages, setIsChatLoading]);

  useEffect(() => {
    setLastMessage(messages.at(-1));
  }, [messages]);

  useEffect(() => {
    lastMessage?.type?.split(" - ")[1] === "Anything else" &&
      setAfterAnythingElseFlag(true);
    if (afterAnythingElseFlag === true) {
      if (lastMessage?.name !== "Digi") {
        appInsightsTracker.afterAnythingElse("User", lastMessage?.text);
      } else {
        appInsightsTracker.afterAnythingElse("Digi", lastMessage?.type);
      }
    }

    if (lastMessage?.name === "Digi") {
      setCurrentTopic(lastMessage?.intent);
    }
    messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
    messagesEndRef.current.scrollBottom = 0;
  }, [lastMessage]);

  return (
    <div className="chat-container">
      {/* Chat area */}
      <Col className="window" id="chat_body" ref={chatContainerRef}>
        <WelcomeMessage />
        {messages &&
          messages.map((message, i) => {
            const {
              text,
              name,
              type,
              options,
              intent,
              inputType,
              showAsButtons,
              showACheckbox,
              firstBubbleCheckbox,
              messagesObjectFinal,
              conInicio_,
              id,
            } = message;
            return (
              <Message
                text={text}
                key={i}
                indexMessagesArray={i}
                name={name}
                type={type}
                options={options}
                intent={intent}
                inputType={inputType}
                showAsButtons={showAsButtons}
                showACheckbox={showACheckbox}
                firstBubbleCheckbox={firstBubbleCheckbox}
                messagesObjectFinal={messagesObjectFinal}
                conInicio_={conInicio_}
                id={id}
              />
            );
          })}

        {isChatLoading && (
          <Message text="Digital Assistant está escribiendo..." name="Digi" />
        )}
        <div ref={messagesEndRef} />
      </Col>
      <InputDeck isDisabled={isChatLoading} ref={chatContainerRef} />
      <ConfirmationPopup />
    </div>
  );
};

export default ChatView;
