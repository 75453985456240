import { useContext, useState } from "react";
import "./dropdownMenu.scss";
import { ArrowSVG } from "../../styles/assets/icons";
import { ScreenContext } from "../../context/screenContext";
import { appInsightsTracker } from "../../helpers/AppInsights";

/**
 * @typedef {object} DropdownOption
 * @property {string} title
 * @property {string} helpText
 * @property {React.ReactNode} icon
 * @property {() => void} onClick
 * @property {boolean} isSelected
 */

/**
 *
 * @param {object} props
 * @param {string | null} [props.topTitle]
 * @param {React.ReactNode} props.icon
 * @param {string} props.title
 * @param {string | null} [props.helpText]
 * @param {(() => void) | null} props.onClickFunction
 * @param {DropdownOption[]} [props.options]
 * @param {boolean} props.isSelected
 * @param {boolean} props.isOpen
 * @param {(id: string) => void} props.onToggle
 * @param {string} props.id
 * @returns {React.ReactNode}
 */
const DropdownMenu = ({
  topTitle,
  icon,
  title,
  helpText,
  onClickFunction,
  options,
  isSelected,
  isOpen,
  onToggle,
  id,
}) => {
  const [showToolsTip, setShowToolsTip] = useState(null);
  const {
    handleBarSize,
    handleScreenColsSize,
    getBarAndScreenSize,
    handleMenuIsOpen,
    menuIsOpen,
    theme,
  } = useContext(ScreenContext);

  const handleOnClickFirstItem = () => {
    if (id !== "dropdownTheme") onToggle(id);
    if (onClickFunction) onClickFunction();
    else if (!menuIsOpen) {
      let width = window.innerWidth;
      const sizes = getBarAndScreenSize(width);
      handleBarSize(sizes.open.barCols);
      handleScreenColsSize(sizes.open.screenCols);
      handleMenuIsOpen(!menuIsOpen);
    }
    appInsightsTracker.events("Click", "Opcion Principal", title);
  };

  const handleOnMouseEnter = (position) => {
    if (menuIsOpen) setShowToolsTip(position);
    appInsightsTracker.events("Mouse Enter", "Opcion Principal", title);
  };

  const handleOnMouseLeave = () => {
    if (menuIsOpen) setShowToolsTip(null);
    appInsightsTracker.events("Mouse Leave", "Opcion Principal", title);
  };

  return (
    <>
      <p className="title-dropdown-menu">{menuIsOpen && topTitle}</p>
      <div
        className={`dropdown-menu-container${!menuIsOpen ? "--menuSmall" : ""}${
          isOpen ? " open" : ""
        }`}
      >
        <div
          className={`dropdown-menu-container__parent${
            !menuIsOpen ? "--menuSmall" : ""
          }${isSelected ? " selectedDropdown" : ""}`}
          onClick={handleOnClickFirstItem}
          onMouseEnter={() => handleOnMouseEnter("parent")}
          onMouseLeave={() => handleOnMouseLeave()}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleOnClickFirstItem();
          }}
        >
          {icon}
          {menuIsOpen && (
            <div className="dropdown-menu-container__parent__container">
              <p className="dropdown-menu-container__parent__container__title">
                {title}
              </p>
              <div className="dropdown-menu-container__parent__container__arrow">
                {!onClickFunction ? (
                  isOpen ? (
                    <ArrowSVG
                      direction="up"
                      color={theme.theme === "light" ? "#111111" : "#FFFFFF"}
                      strokeWidth="3"
                      width={menuIsOpen ? "11px" : "7px"}
                    />
                  ) : (
                    <ArrowSVG
                      direction="down"
                      color={theme.theme === "light" ? "#111111" : "#FFFFFF"}
                      strokeWidth="3"
                      width={menuIsOpen ? "11px" : "7px"}
                    />
                  )
                ) : (
                  <>&nbsp;</>
                )}
              </div>
              {showToolsTip === "parent" && helpText?.length > 0 && (
                <p className="dropdown-menu-container__parent__container__tooltiptext">
                  {helpText}
                </p>
              )}
            </div>
          )}
        </div>
        {isOpen && menuIsOpen && !onClickFunction && (
          <ul
            className={`dropdown-menu-container__options${
              !menuIsOpen ? "--menuSmall" : ""
            }`}
          >
            {options.map((el, i) => (
              <li
                className={`dropdown-menu-container__options__list${
                  !menuIsOpen ? "--menuSmall" : ""
                } ${el.isSelected ? "selectedDropdownSubitem" : ""}`}
                key={i}
                onClick={el.onClick}
                onMouseEnter={() => handleOnMouseEnter(i)}
                onMouseLeave={() => handleOnMouseLeave()}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") el.onClick();
                }}
              >
                {el.icon}
                <p className="dropdown-menu-container__options__list__title">
                  {menuIsOpen && el.title}
                </p>
                {showToolsTip === i && el.helpText?.length > 0 && (
                  <p className="dropdown-menu-container__options__list__tooltiptext">
                    {el.helpText}
                  </p>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default DropdownMenu;
