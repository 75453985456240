import {} from "../../../styles/assets/icons";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./confirmationResetChat.scss";
import { PopupsContext } from "../../../context/popupsContext";
import Modal from "react-bootstrap/Modal";
import { DeleteSVG } from "../../../styles/assets/icons";
import { SocketContext } from "../../../context/socketContext";
import { UserContext } from "../../../context/userContext";

// Confirm if we want to reset the chat
const ConfirmationResetChat = () => {
  const { setShowConfirmationPopup, showConfirmationPopup } =
    useContext(PopupsContext);
  const { setMessages, messages, emitMessage } = useContext(SocketContext);
  const [colorBottom, setColorBottom] = useState("black");
  const { setCurrentTopic, setCurrentlyInForm } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    //we need the user data first

    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "light") {
        setColorBottom("black");
      } else {
        setColorBottom("white");
      }
    }
  }, []);

  function handleClick(value) {
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
    }));
    if (value) {
      let temp = messages;
      temp.splice(1, temp.length - 1);

      setMessages([...temp]);
      sessionStorage.setItem("resetChat", JSON.stringify("si"));
      //we have to change the historyChat so it doesnt bring all the messages from before the chat reset
      if (sessionStorage.getItem("chatHistory")) {
        let chatHistory = JSON.parse(sessionStorage.getItem("chatHistory"));
        chatHistory.splice(1, chatHistory.length - 1);
        sessionStorage.setItem("chatHistory", JSON.stringify(chatHistory));
      }
      //We end the form if we are in one
      if (sessionStorage.getItem("currentlyInsideAForm")) {
        setCurrentlyInForm(false);
        const name = JSON.parse(sessionStorage.getItem("userData")).name;
        emitMessage("chat", { name: name, option: "Salir del formulario" });
      }
      navigate("/");
      setCurrentTopic("Default");
    }
  }
  const handleClose = () =>
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
    }));
  return (
    <div className="container-popup">
      <Modal
        show={showConfirmationPopup.show === "resetChat"}
        onHide={handleClose}
      >
        <Modal.Header>
          <div className="container-popup--close" onClick={handleClose}>
            <DeleteSVG color={colorBottom} />
          </div>
          <Modal.Title>Reiniciar chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reset-confirmation-body">
            Se borrarán todos los mensajes ¿Estás seguro?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button-confirmation button-confirmation--no"
            onClick={() => handleClick(false)}
          >
            NO
          </button>
          <button
            className="button-confirmation button-confirmation--yes"
            onClick={() => handleClick(true)}
          >
            SI
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmationResetChat;
