import "./optionsMessage.scss";
import { useState, useContext } from "react";
import { SocketContext } from "../../../../context/socketContext";
import { UserContext } from "../../../../context/userContext";
import { WelcomeMessageContext } from "../../../../context/welcomeMessageContext";
import { Carousel } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

/**
 *
 * @param {object} props
 * @param {string[]} props.options
 * @param {'vertical' | 'horizontal'} props.containerDirection
 * @param {number} props.indexElementBubble
 * @param {number} props.length
 * @param {number} props.indexMessagesArray
 * @param {boolean} props.permanent
 * @param {string} props.idName
 * @returns {React.ReactNode}
 */
const OptionsMessage = ({
  options,
  containerDirection,
  indexElementBubble,
  length,
  indexMessagesArray,
  permanent,
  idName,
}) => {
  const [indexElementBubbleButton] = useState(indexElementBubble);
  const [lengthButton] = useState(length);
  const { emitMessage } = useContext(SocketContext);
  const { user, optionsCheckbox, setDisableBubblesButtons } =
    useContext(UserContext);
  const { name } = user;
  const location = useLocation();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const orientationButtons = {
    buttonsContainer:
      options.length > 15 || containerDirection === "vertical"
        ? "containerVerticalButtons" //isn't use
        : indexElementBubbleButton === 0 && lengthButton === 1
        ? "containerHorizontalButtons--unique"
        : indexElementBubbleButton === 0
        ? "containerHorizontalButtons--first"
        : indexElementBubbleButton === lengthButton - 1
        ? "containerHorizontalButtons--last"
        : "containerHorizontalButtons",
    optionButton: "horizontalButton",
  };
  const { showWelcomeMessage, setShowWelcomeMessage } = useContext(
    WelcomeMessageContext
  );

  const largeAmountOptions = () => {
    let i = 0,
      optionsGroups = new Array();
    options.map((option, index) => {
      if ((index) % 8 === 0) {
        i++;
      }
      optionsGroups[i] !== undefined
        ? optionsGroups[i].push(option)
        : (optionsGroups[i] = [option]);
    });

    return optionsGroups;
  };

  const handleOption = (option) => {
    if (option) {
      // If the welcome message is showing
      showWelcomeMessage.show === false &&
        setShowWelcomeMessage({ ...showWelcomeMessage, show: true });
      // this is when we have for ex: "beneficios-gympass", we want only the second part
      if (option.includes("-")) {
        let arrayResponse = option.split("-");
        option = arrayResponse[1];
      }
      emitMessage("chat", { name, option, optionsCheckbox });
    }
    if (permanent !== true) setButtonDisabled(!isButtonDisabled);
    if (permanent !== true)
      setDisableBubblesButtons((oldArray) => [...oldArray, indexMessagesArray]);
  };

  return (
    <>
      {options.length <= 10 ? (
        <div className={"wrapper-" + orientationButtons.buttonsContainer}>
          {options?.map((option, idx) => {
            return (
              <button
                className={
                  permanent !== true
                    ? orientationButtons.optionButton
                    : "options__button"
                }
                key={option + idx}
                onClick={(prop) => {
                  if (permanent !== true) prop.target.className += "--selected";
                  handleOption(option);
                }}
                disabled={isButtonDisabled}
                id={idName}
              >
                {option === "Reportar" ? (
                  <Link
                    to={"/bugs"}
                    state={{ location }}
                    className="feedbackReport"
                  >
                    {option}
                  </Link>
                ) : (
                  option
                )}
              </button>
            );
          })}
        </div>
      ) : (
        <Carousel className="carousel-options" slide={false} interval={null}>
          {largeAmountOptions()?.map((optList, index) => {
            return (
              <Carousel.Item key={index}>
                <div
                  className={"wrapper-" + orientationButtons.buttonsContainer}
                >
                  {optList.map((option, idx) => {
                    return (
                      <button
                        className={
                          permanent !== true
                            ? orientationButtons.optionButton
                            : "options__button"
                        }
                        key={option + idx}
                        onClick={(prop) => {
                          if (permanent !== true)
                            prop.target.className += "--selected";
                          handleOption(option);
                        }}
                        disabled={isButtonDisabled}
                        id={idName}
                      >
                        {option}
                      </button>
                    );
                  })}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      )}
    </>
  );
};
export default OptionsMessage;
