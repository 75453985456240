// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ranking-barchart-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.ranking-barchart-container > * {
  width: 49%;
}

@media screen and (max-width: 768px) {
  .ranking-barchart-container {
    margin: 0.4rem 0;
    flex-direction: column;
  }
  .ranking-barchart-container > * {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/commons/my-points/ranking/leaderboard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,oBAAA;AACF;AACE;EACE,UAAA;AACJ;;AAGA;EACE;IACE,gBAAA;IACA,sBAAA;EAAF;EAEE;IACE,WAAA;EAAJ;AACF","sourcesContent":[".ranking-barchart-container {\n  display: flex;\n  justify-content: space-between;\n  padding-bottom: 1rem;\n\n  & > * {\n    width: 49%;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .ranking-barchart-container {\n    margin: 0.4rem 0;\n    flex-direction: column;\n\n    & > * {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
