export const CalculateProgressScorebar = (points) => {
  var percentage;
  var percentSubtotalOfTotal;
  var percentScoreBarSubSection;
  switch (true) {
    case points <= 99:
      //1.get the percent on the current level
      percentage = (points / 100) * 99;
      //2.get what that percent represents of the total bar
      percentSubtotalOfTotal = (20 / 100) * (percentage / 100) * 100;
      //3.we add to the percent we calculated the previous levels completed (20% for every level)
      percentScoreBarSubSection = percentSubtotalOfTotal;
      break;
    case points >= 100 && points <= 499:
      percentage = ((points - 100) / 399) * 100;
      percentSubtotalOfTotal = (20 / 100) * (percentage / 100) * 100;
      percentScoreBarSubSection = 20 + percentSubtotalOfTotal;
      break;
    case points >= 500 && points <= 1499: //case points >= 500 && points <= 999:
      percentage = ((points - 500) / 999) * 100; //percentage =  ((points-500)/499)*100;
      percentSubtotalOfTotal = (20 / 100) * (percentage / 100) * 100;
      percentScoreBarSubSection = 40 + percentSubtotalOfTotal;
      break;
    case points >= 1500 && points <= 2999: //case points >= 1000 && points <= 1499:
      percentage = ((points - 1500) / 1499) * 100;
      percentSubtotalOfTotal = (20 / 100) * (percentage / 100) * 100;
      percentScoreBarSubSection = 60 + percentSubtotalOfTotal;
      break;
    case points >= 3000: //case points >= 1500:
      percentage = ((points - 3000) / 5999) * 100;
      percentSubtotalOfTotal = (20 / 100) * (percentage / 100) * 100;
      percentScoreBarSubSection = 80 + percentSubtotalOfTotal;
      break;
    default:
      break;
  }
  return { percentage, percentScoreBarSubSection };
};
