import { useContext, useEffect, useState } from "react";
import "./formStyle.scss";
import Input from "../../commons/input/Input";
import DropdownInput from "../../commons/input/DropdownInput";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertToNumber,
  getCurrentMonths,
  isValidDate,
  isValidEmail,
  isValidRefoundAmount,
} from "../../helpers/forms/validateFormInputs";
import { PopupsContext } from "../../context/popupsContext";
import { postInternetForm } from "../../services/forms/internetFormService";
import { appInsightsTracker } from "../../helpers/AppInsights";
import { SessionContext } from "../../context/sessionContext";
import { ScreenContext } from "../../context/screenContext";
import { FormContext } from "../../context/formContext";
import { UserContext } from "../../context/userContext";
import { deleteToken } from "../../services/login/loginService";

const FormInternetView = () => {
  const declarationText =
    "Declaro que los datos ingresados son ciertos, y cumplen con lo definido por la política de rendición de gastos. (prestar especial atención al número de legajo).";

  const { setShowConfirmationPopup, setShowCustomModal } =
    useContext(PopupsContext);

  const maxRefoundAmount = process.env.REACT_APP_INTERNET_FEE
    ? convertToNumber(process.env.REACT_APP_INTERNET_FEE)
    : 3500;

  const { setIsScreenLoading } = useContext(ScreenContext);

  const { openDropdown, handleToggleDropdown, handleCloseDropdown } =
    useContext(FormContext);

  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);

  const { apiToken } = useContext(UserContext);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [archive, setArchive] = useState(null);
  const [errors, setErrors] = useState({
    file: false,
    issueDate: false,
    refundAmount: false,
    payPeriod: false,
    mail: false,
    archive_upload: false,
  });
  const [values, setValues] = useState({
    file: JSON.parse(sessionStorage.getItem("userData"))?.file || "",
    issueDate: "",
    refundAmount: process.env.REACT_APP_INTERNET_FEE
      ? convertToNumber(process.env.REACT_APP_INTERNET_FEE)
      : 1,
    payPeriod: "",
    mail: JSON.parse(sessionStorage.getItem("userData"))?.email || "",
    archive_upload: "",
    declaration: false,
  });
  const isAvailable =
    new Date().getDate() > 0 && new Date().getDate() < 16 ? true : false;
  const navigate = useNavigate();
  const location = useLocation();
  const [idForm] = useState(Date.now());

  useEffect(() => {
    appInsightsTracker.pageView("Reintegro de Internet");
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Reintegro de Internet",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Reintegro de Internet",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Reintegro de Internet",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  useEffect(() => {
    if (!isAvailable) {
      setShowCustomModal({
        show: true,
        message: {
          title: "Formulario no disponible 🗓️",
          subtitle:
            "La carga del formulario de reintegro de internet está disponible del 01 al 15 de cada mes.",
          secondButton: {
            title: "Volver con Digi",
            onClickFunction: () => {
              setShowCustomModal({ show: false });
              navigate("/chat");
            },
          },
        },
      });
    }
    setErrors({
      ...errors,
      issueDate: !isValidDate(values.issueDate),
      mail: !isValidEmail(values.mail),
      refundAmount: !isValidRefoundAmount(
        values.refundAmount,
        maxRefoundAmount
      ),
    });
    if (
      values.file.length > 0 &&
      values.issueDate.length > 0 &&
      values.refundAmount.toString().length > 0 &&
      values.payPeriod.length > 0 &&
      values.mail.length > 0 &&
      archive !== null &&
      values.declaration &&
      !errors.mail &&
      !errors.issueDate &&
      !errors.refundAmount
    ) {
      setIsButtonDisabled(false);
    } else setIsButtonDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errors.mail,
    errors.issueDate,
    errors.refundAmount,
    values.file,
    values.issueDate,
    values.refundAmount,
    values.payPeriod,
    values.mail,
    archive,
    values.declaration,
    isAvailable,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsScreenLoading({ state: true });
      const { data, status } = await postInternetForm({
        creatorMail: JSON.parse(sessionStorage.getItem("userData"))?.email,
        values,
        archive,
        declarationText,
        apiToken,
      });
      setIsScreenLoading({ state: false });
      if (data === undefined || status === 500 || status === 400) {
        setShowConfirmationPopup((prevState) => ({
          ...prevState,
          show: "error",
        }));
      } else if (status === 200) {
        appInsightsTracker.logFormEvents(
          "Internet Form events",
          "Submitted form",
          "Send button",
          idForm
        );
        setShowConfirmationPopup((prevState) => ({
          ...prevState,
          show: "success",
          message: (
            <p>
              El formulario se envió<span>correctamente</span>.
            </p>
          ),
        }));
      }
      navigate("/chat");
    } catch (error) {
      console.log(error);
      setIsScreenLoading({ state: false });
      setShowConfirmationPopup((prevState) => ({
        ...prevState,
        show: "error",
      }));
      navigate("/chat", { state: { location } });
    }
  };

  const validateFile = (target) => {
    if (target === undefined || target?.type === "application/pdf") {
      if (target === undefined) {
        setValues({
          ...values,
          archive_upload: "",
        });
        setArchive(null);
      } else {
        setArchive(target);
        setValues({ ...values, archive_upload: target.name });
      }
      setErrors({
        ...errors,
        archive_upload: false,
      });
    } else {
      setValues({
        ...values,
        archive_upload: "Solo se puede subir en formato .pdf",
      });
      setErrors({
        ...errors,
        archive_upload: true,
      });
      setArchive(null);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    validateFile(event.dataTransfer.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    appInsightsTracker.logFormEvents(
      "Internet Form events",
      "Input click",
      "Upload file",
      idForm
    );
    validateFile(event.target.files[0]);
  };

  return (
    <div className="form-container">
      <p className="form-container__title">
        Formulario de <span>Reintegro de Internet</span>
      </p>
      <p className="form-container__subtitle">
        Pedí tu reembolso de gastos de Internet del último mes o el anterior.
        Recordá que podés cargarlo hasta el 15 de cada mes.
      </p>
      <form
        type="submit"
        className="form-container__form"
        onSubmit={handleSubmit}
      >
        <div className="form-container__form__inputs">
          <Input
            type="text"
            name={"Legajo"}
            value={values.file}
            isRequired={true}
            handleInput={(e) => {
              setValues({
                ...values,
                file: e,
              });
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Internet Form events",
                "Input click",
                "File input",
                idForm
              )
            }
          />
          <Input
            type="date"
            name={"Fecha de emisión de la factura"}
            max={new Date().toISOString().split("T")[0]}
            min={
              new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1)
                .toISOString()
                .split("T")[0]
            }
            isRequired={true}
            handleInput={(e) => {
              setValues({
                ...values,
                issueDate: e,
              });
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Internet Form events",
                "Input click",
                "Issue date input",
                idForm
              )
            }
            showError={errors.issueDate}
            errorMessage={"El formato de la fecha es inválido."}
          />
          <Input
            type="number"
            name={"Importe del reintegro"}
            value={values.refundAmount}
            isRequired={true}
            handleInput={(e) => {
              setValues({
                ...values,
                refundAmount: e,
              });
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Internet Form events",
                "Input click",
                "Refund amount input",
                idForm
              )
            }
            showError={errors.refundAmount}
            errorMessage={`El valor debe estar entre 1 y ${maxRefoundAmount}.`}
          />
          <DropdownInput
            id="payPeriod"
            name="Período a rendir"
            valuesArray={getCurrentMonths()}
            isRequired={true}
            handleChange={(e) => {
              setValues({
                ...values,
                payPeriod: e,
              });
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Internet Form events",
                "Input click",
                "Pay period input",
                idForm
              )
            }
            isOpen={openDropdown === "payPeriod"}
            handleToggleDropdown={handleToggleDropdown}
            handleCloseDropdown={handleCloseDropdown}
          />
          <Input
            type="email"
            name={"Mail laboral de quién recibirá el reintegro"}
            value={values.mail}
            isRequired={true}
            handleInput={(e) => {
              setValues({
                ...values,
                mail: e,
              });
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Internet Form events",
                "Input click",
                "Mail input",
                idForm
              )
            }
            topText="Importante: Debe coincidir con el legajo informado (Mail laboral)"
            showError={errors.mail}
            errorMessage={"El formato del mail es inválido."}
          />
        </div>
        <span className="archive-span">
          Subir un archivo <span className="required">*</span>
        </span>
        <label className="archive-label-subtitle">
          Por favor, subí la factura del servicio de internet como PDF{" "}
        </label>
        <label
          className={`archive-btn${errors.archive_upload ? "--error" : ""}`}
          htmlFor="archive"
        >
          <div
            className="drag-drop-container"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <input
              name="archive"
              id="archive"
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
            ></input>
            <p className="archive-text">
              {values.archive_upload && !errors.archive_upload ? (
                values.archive_upload
              ) : (
                <>
                  Arrastra y suelta o{" "}
                  <b>
                    <u>sube el archivo</u>
                  </b>
                  .
                </>
              )}
            </p>
          </div>
        </label>
        <p className="archive-text--error">
          &nbsp;
          {errors.archive_upload && values.archive_upload}
        </p>
        <span className="declaration-span">
          Declaración <span className="required">*</span>
        </span>
        <label htmlFor="Declaration" className="declaration-checkbox">
          <input
            type="checkbox"
            name="Declaration"
            id="Declaration"
            className="declaration-checkbox__input"
            onChange={() => {
              setValues({
                ...values,
                declaration: !values.declaration,
              });
            }}
            onClick={() =>
              appInsightsTracker.logFormEvents(
                "Internet Form events",
                "Input click",
                "Declaration input",
                idForm
              )
            }
          />
          <svg className="declaration-checkbox__check" width="24" height="24">
            <polyline points="15 3 8 15 4 12"></polyline>
          </svg>
          <span>{declarationText}</span>
        </label>
        <div className="form-container__form__button-container">
          <button
            className="form-container__form__button-container__cancelation"
            onClick={() => {
              setShowCustomModal({
                show: true,
                message: {
                  title: "Cancelar solicitud",
                  subtitle:
                    "Se borrarán los datos ingresados, ¿querés continuar?",
                  firstButton: {
                    title: "Cancelar",
                    onClickFunction: () => setShowCustomModal({ show: false }),
                  },
                  secondButton: {
                    title: "Confirmar",
                    onClickFunction: () => {
                      setShowCustomModal({ show: false });
                      navigate("/chat");
                    },
                  },
                },
              });
            }}
            type="button"
          >
            Cancelar
          </button>
          <button
            className="form-container__form__button-container__send"
            disabled={isButtonDisabled}
            type="submit"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormInternetView;
