import { useContext, useEffect, useState } from "react";
import { DeleteOutlineSVG } from "../../styles/assets/icons";
import { AvatarSVG, BackgroundAvatarSVG } from "../../styles/svg/avatarSVG";
import { PopupsContext } from "../../context/popupsContext";
import "./statsPopup.scss";

const StatsPopup = () => {
  const [digiConfig, setDigiConfig] = useState({
    digi: "gray",
    back: "yellow",
    filter: "leaderboardDigi",
  });
  const { setShowPopupStats, showPopupStats } = useContext(PopupsContext);

  useEffect(() => {
    setDigiConfig({
      ...digiConfig,
      digi:
        JSON.parse(showPopupStats.stats.userInfo.customization)?.digi || "gray",
      back:
        JSON.parse(showPopupStats.stats.userInfo.customization)?.back ||
        "yellow",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toHoursAndMinutes = (totalMinutes) => {
    if (totalMinutes && parseInt(totalMinutes) > 0) {
      const minutes = totalMinutes % 60;
      let hours = Math.floor(totalMinutes / 60);
      if (hours === 0) hours = "00";
      return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}h`;
    } else return "0h";
  };

  const handleClose = () => {
    setShowPopupStats({ show: false });
  };

  return (
    <div className="stats-container">
      <div className="stats-container__close">
        <DeleteOutlineSVG onClickFunction={handleClose} />
      </div>
      <div className="stats-container__box">
        <div className="stats-container__box__information">
          <div className="stats-container__box__information__user">
            <div className="stats-container__box__information__user__avatar">
              <AvatarSVG {...digiConfig} />
              <BackgroundAvatarSVG {...digiConfig} />
            </div>
            <div className="stats-container__box__information__user__data">
              <p className="stats-container__box__information__user__data__name">
                {showPopupStats.stats.userInfo?.name}
              </p>
              <p className="stats-container__box__information__user__data__job">
                {showPopupStats.stats.userInfo?.jobTitle}{" "}
                {showPopupStats.stats.userInfo?.jobTitle &&
                showPopupStats.stats.userInfo?.los
                  ? " - "
                  : null}{" "}
                {showPopupStats.stats.userInfo?.los}
              </p>
            </div>
          </div>
          <div className="stats-container__box__information__stats">
            <p className="stats-container__box__information__stats__first-title">
              {`${showPopupStats.isExternal ? "Sus" : "Tus"} estadísticas${
                showPopupStats.isMonthly ? " de este mes:" : "*:"
              }`}
            </p>
            <p className="stats-container__box__information__stats__title">
              Preguntas realizadas
            </p>
            <div className="stats-container__box__information__stats__score">
              <p className="stats-container__box__information__stats__score__number">
                {showPopupStats.stats.userStats?.numQuestions}
              </p>
            </div>
            <p className="stats-container__box__information__stats__title">
              Tiempo en el bot
            </p>
            <div className="stats-container__box__information__stats__score">
              <p className="stats-container__box__information__stats__score__number">
                {toHoursAndMinutes(showPopupStats.stats.userStats.timeSpent)}
              </p>
            </div>
            <p className="stats-container__box__information__stats__title">
              Bugs reportados
            </p>
            <div className="stats-container__box__information__stats__score">
              <p className="stats-container__box__information__stats__score__number">
                {showPopupStats.stats.userStats?.reportedBugs}
              </p>
            </div>
          </div>
          {!showPopupStats.isMonthly && (
            <p className="stats-container__box__information__clarification">
              *Estos valores son históricos.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatsPopup;
