function getCurrentMonths() {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  let actualMonth = new Date().getMonth();
  const lastMonths = [];

  if (actualMonth === 0) actualMonth = 12;

  lastMonths.push(months[actualMonth - 1]);
  lastMonths.push(months[actualMonth === 1 ? 11 : actualMonth - 2]);

  return lastMonths;
}

function getCurrentYear() {
  const actualDate = new Date();
  let years = [];
  if (actualDate.getMonth() === 1)
    years.push((actualDate.getFullYear() - 1).toString());
  years.push(actualDate.getFullYear().toString());

  return years;
}

function isValidEmail(email) {
  if (email.length === 0) return true;
  const expression =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  const result = expression.test(email);
  if (result && email.endsWith("@pwc.com")) return true;
  else return false;
}

function isValidDate(dateString) {
  if (dateString.length === 0) return true;

  const regex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])$/;
  if (!regex.test(dateString)) return false;

  const parts = dateString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  const date = new Date(year, month - 1, day);

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

function convertToNumber(string) {
  const numericString = string.replace(/[^0-9]/g, "");
  return parseInt(numericString, 10);
}

function isValidRefoundAmount(amount, maxAmount) {
  if (amount.toString().length === 0) return true;
  if (amount >= 1 && amount <= maxAmount) return true;
  return false;
}

function autocompleteUrl(userData, baseUrl, entries) {
  return Object.keys(userData).reduce((acc, key) => {
    if (entries[key]) {
      return (acc += `&entry.${entries[key]}=${userData[key]}`);
    }
    return acc;
  }, baseUrl);
}

module.exports = {
  getCurrentMonths,
  getCurrentYear,
  isValidDate,
  isValidEmail,
  convertToNumber,
  isValidRefoundAmount,
  autocompleteUrl,
};
