import { AvatarSVG, BackgroundAvatarSVG } from "../../styles/svg/avatarSVG";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// The props for customization
import getUnlockedCustoms from "./getUnlockedCustoms";
import Loader from "../../commons/loader/Loader";
import ButtonTab from "../../commons/button-tab/ButtonTab";
import { getUserStats } from "../../services/userStats/statsService";
import { getCustom, putCustom } from "../../services/custom/customService";
import { ScreenContext } from "../../context/screenContext";
import CustomLight from "./assets/CustomLight.png";
import CustomDark from "./assets/CustomDark.png";
import AvatarOption from "../../commons/custom-avatar/AvatarOption";
import { appInsightsTracker } from "../../helpers/AppInsights";
import "./customView.scss";
import { SessionContext } from "../../context/sessionContext";
import { UserContext } from "../../context/userContext";
import { deleteToken } from "../../services/login/loginService";

const CustomView = () => {
  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);
  const { apiToken } = useContext(UserContext);
  //Gives the color to the avatar
  const [digiConfig, setDigiConfig] = useState({
    digi: localStorage.getItem("digi") || "gray",
    back: localStorage.getItem("back") || "yellow",
    filter: "customDigi",
  });
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [avatarColors, setAvatarColors] = useState([]);
  const [avatarBackgrounds, setAvatarBackgrounds] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useContext(ScreenContext);

  const customViewContainerClass = isLoading
    ? "custom-container--blurOn"
    : "custom-container";

  useEffect(() => {
    appInsightsTracker.pageView("Personalización");
    //we need the user data first
    if (!sessionStorage.getItem("userData")) return null;
    setUserData(JSON.parse(sessionStorage.getItem("userData")));
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Personalización",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Personalización",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Personalización",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  useEffect(() => {
    if (userData !== undefined) {
      // Get the data from the back
      const getData = async () => {
        try {
          const email = userData.email;
          setIsLoading(true);
          const result = await getUserStats(email, apiToken);
          const points = result.data.response.points;
          setAvatarColors(getUnlockedCustoms(points, "AvatarSVG"));
          setAvatarBackgrounds(
            getUnlockedCustoms(points, "BackgroundAvatarSVG")
          );
          // Digi for the mascot, and back for the background
          if (
            localStorage.getItem("digi") === (undefined || null) ||
            localStorage.getItem("back") === (undefined || null)
          ) {
            const digiResult = await getCustom(email, apiToken);
            // If not customizations was given, it will have a default set of yellow and gray
            if (digiResult.data.response !== (undefined && null)) {
              setDigiConfig({
                ...digiConfig,
                digi: JSON.parse(digiResult.data.response)?.digi || "gray",
                back: JSON.parse(digiResult.data.response)?.back || "yellow",
              });
              localStorage.setItem(
                "digi",
                JSON.parse(digiResult.data.response)?.digi || "gray"
              );
              localStorage.setItem(
                "back",
                JSON.parse(digiResult.data.response)?.back || "yellow"
              );
            }
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          navigate("/error");
          console.log(error);
        }
      };
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (
      localStorage.getItem("digi") === digiConfig.digi &&
      localStorage.getItem("back") === digiConfig.back
    )
      setIsButtonDisabled(true);
    else setIsButtonDisabled(false);
  }, [digiConfig]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const email = userData.email;
    document.getElementById("buttonApply").disabled = true;
    const customJson = { digi: digiConfig.digi, back: digiConfig.back };
    try {
      await putCustom(email, customJson, apiToken);
      localStorage.setItem("digi", digiConfig.digi);
      localStorage.setItem("back", digiConfig.back);
      setIsLoading(false);
      navigate("/chat", { state: { location } });
    } catch (error) {
      setIsLoading(false);
      navigate("/error");
      console.log(error);
    }
  };

  const containerCustomAction = (type) => {
    return (
      <div className="custom-container__holder__second-column__options">
        <AvatarOption
          caseElements={type === "AvatarSVG" ? avatarColors : avatarBackgrounds}
          setDigiConfig={setDigiConfig}
          digiConfig={digiConfig}
        />
      </div>
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={customViewContainerClass}>
        <p className="custom-container__title">Personalización</p>
        <div className="custom-container__holder">
          <div className="custom-container__holder__first-column">
            <img
              className="custom-container__holder__first-column__image"
              src={theme.theme === "dark" ? CustomDark : CustomLight}
              alt="Decorá tu avatar"
            />
            <div className="custom-container__holder__first-column__avatar">
              <AvatarSVG {...digiConfig} />
              <BackgroundAvatarSVG {...digiConfig} />
            </div>
          </div>
          <div className="custom-container__holder__second-column">
            {avatarColors.length > 0 && (
              <ButtonTab
                items={[
                  {
                    name: "Cuerpo",
                    view: containerCustomAction("AvatarSVG"),
                  },
                  {
                    name: "Fondos",
                    view: containerCustomAction("BackgroundAvatarSVG"),
                  },
                ]}
              />
            )}
            <div className="custom-container__holder__second-column__buttons">
              <button
                id="buttonApply"
                className="custom-container__holder__second-column__buttons__apply"
                onClick={onSubmit}
                disabled={isButtonDisabled}
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomView;
