import { useContext, useState, forwardRef, useEffect } from "react";
import "./inputDeck.scss";
import { SocketContext } from "../../../context/socketContext";
import { UserContext } from "../../../context/userContext";
import { WelcomeMessageContext } from "../../../context/welcomeMessageContext";
import { getTopics } from "../../../services/chat/topicsServices";
import sendIcon from "./assets/sendIcon.png";
import { ScreenContext } from "../../../context/screenContext";

// This is were you add input to send the bot, like questions.
const InputDeck = forwardRef((props, ref) => {
  const { isDisabled } = props;
  const { emitMessage, isConnected } = useContext(SocketContext);
  const { user, currentlyInForm, currentTopic, apiToken } =
    useContext(UserContext);
  const { showWelcomeMessage, setShowWelcomeMessage } = useContext(
    WelcomeMessageContext
  );
  const { name } = user;
  const [characterCount, setCharacterCount] = useState(0);
  const [text, setText] = useState("");
  const [countMessages, setCountMessages] = useState(0);
  const [slowmode, setSlowmode] = useState({
    notActive: true,
    timeOut: 15000, //15sec
  });

  const { setScrollRef } = useContext(ScreenContext);
  useEffect(() => {
    setScrollRef(ref);
  }, []);

  const [rawDataHT, setRawDatatHT] = useState();

  const [hotTopics, setHotTopics] = useState({
    topics: [],
  });

  const [subTopics, setSubTopics] = useState({
    currentTopic: "Default",
    subTopics: [],
  });

  useEffect(() => {
    hotTopicsOptions();
  }, [user]);

  useEffect(() => {
    if (![undefined, null, ""].includes(currentTopic)) subTopicsOptions();
  }, [currentTopic]);

  const hotTopicsOptions = async () => {
    let resultHT = await getTopics(user.LOS || user.los, apiToken);
    let rawDataHT = resultHT?.data?.response[0][0];
    let jsonTopics = {};
    let lstTopics = new Array();
    let lastTopic = "";

    setRawDatatHT(rawDataHT);

    rawDataHT?.map((value, index) => {
      if (value.Tema_principal !== null) {
        jsonTopics[value.Tema_principal] = {
          name: value.Tema_principal,
          count:
            jsonTopics[value.Tema_principal]?.count + value.Count ||
            value.Count,
        };
        if (lastTopic !== value.Tema_principal && lastTopic !== "") {
          lstTopics.push(jsonTopics[lastTopic]);
        } else if (index === rawDataHT.length - 1) {
          lstTopics.push(jsonTopics[value.Tema_principal]);
        }

        if (value.Tema_principal !== null) lastTopic = value.Tema_principal;
      }
    });

    let sortedLstTopics = lstTopics
      .sort((a, b) => {
        return a.count - b.count;
      })
      .reverse();

    sortedLstTopics.unshift({ name: "Menú" }, { name: "Forms" });

    setHotTopics({ ...hotTopics, topics: sortedLstTopics });
  };

  const subTopicsOptions = () => {
    let topicValue = currentTopic?.split(" - ")[0].trim();
    if (subTopics.currentTopic !== topicValue)
      ![
        "Index",
        "IndexSecundario",
        "Form",
        "Anything else",
        "Feedback",
      ].includes(topicValue)
        ? setSubTopics({
            currentTopic: topicValue,
            subTopics: getSubTopics(topicValue),
          })
        : setSubTopics({ currentTopic: "Default", subTopics: [] });
  };

  const getSubTopics = (topic) => {
    let lstSubTopic = new Array(),
      sortedLstSubTopic = new Array();
    if (topic === "FAQs") {
      sortedLstSubTopic.push(
        { name: "Menú" },
        { name: "Forms" },
        { name: "¿Cómo descargar UiPath?" },
        { name: "¿Qué es DOT?" },
        { name: "¿Para qué sirve DOT?" },
        { name: "¿Cómo puedo hacer el seguimiento de mi reporte?" },
        { name: "¿Cómo reinicio la conversación?" }
      );
    } else if (topic === "Small talk") {
      sortedLstSubTopic.push(
        { name: "Menú" },
        { name: "Forms" },
        { name: "Hola" },
        { name: "¿Cómo estás?" },
        { name: "Me alegro" },
        { name: "Gracias" },
        { name: "Contame un chiste" },
        { name: "Decime la hora" },
        { name: "¿Quién te creo?" },
        { name: "¿Qué sos?" },
        { name: "¿Sabés cantar?" },
        { name: "¿Tenés alguna película favorita?" }
      );
    } else {
      let filterRawDataHT = rawDataHT?.filter((value) => {
        return value.Tema_principal === topic;
      });
      filterRawDataHT?.map((value, index) => {
        if (value.Subtema_secundario !== null) {
          lstSubTopic.push({
            name: value.Subtema_secundario,
            count: value.Count,
          });
        }
      });

      sortedLstSubTopic = lstSubTopic
        .sort((a, b) => {
          return a.count - b.count;
        })
        .reverse();

      sortedLstSubTopic.unshift({ name: "Menú" }, { name: "Forms" });
    }

    return sortedLstSubTopic;
  };
  // If the user starts to spam (or it's a bot), then there is a slowmode available to slowdown the request that are sent to DF:CX.
  const slowmodeFunction = () => {
    setCountMessages(countMessages + 1);
    if (countMessages >= 3) {
      setSlowmode({ ...slowmode, notActive: false });
      setTimeout(() => {
        setSlowmode({
          ...slowmode,
          notActive: true,
        });
        setCountMessages(0);
      }, slowmode.timeOut);
    }
    setTimeout(() => {
      setCountMessages(0);
    }, 1000);
  };

  const handleChange = ({ target }) => {
    const { value } = target;
    setText(value);
    setCharacterCount(value.length);
  };

  const scrollToTop = () => {
    ref.current.scrollTop = 0;
    ref.current?.scrollIntoView({ behavior: "smooth" });
    setShowWelcomeMessage({ ...showWelcomeMessage, show: false });
  };

  // Function to send the message
  const sendMessage = (option) => {
    if (text && text.trim().length) {
      emitMessage("chat", { name, text });
      setShowWelcomeMessage({ ...showWelcomeMessage, show: true });
      setText("");
      setCharacterCount(0);
    } else if (option) {
      if (option !== "Bienvenida") {
        emitMessage("chat", { name, option });
        setShowWelcomeMessage({ ...showWelcomeMessage, show: true });
      } else scrollToTop();
    }
    setText("");

    slowmodeFunction();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text.trim().length > 0) sendMessage();
  };

  // Send message on the click of enter
  const handleEnterKey = (e) => {
    if (e.code === "Enter" && characterCount < 201) {
      e.preventDefault();
      if (text.trim().length > 0) sendMessage();
    }
  };

  return (
    <div className="input-container">
      <div hidden={slowmode.notActive}>
        {/* When slowmode is active, everything in the input deck is unavailable and will display a message */}
        <p className="slowmode">
          ¡Slowmode activado! Estás enviando muchos mensajes de golpe, en los
          proximos{" "}
          <span className="slowmode__time">
            {slowmode.timeOut / 1000} segundos
          </span>{" "}
          no puedes enviar mensajes.
        </p>
      </div>
      <button
        className="options__button-volver"
        onClick={() => {
          sendMessage("Volver");
        }}
        disabled={
          isDisabled || !isConnected || currentlyInForm || !slowmode.notActive
        }
      >
        Volver al paso anterior 🡩
      </button>
      {/* Options buttons as an alternative to writing the question */}
      <div className="options">
        {/* if not engaged in a specific topic */}
        {subTopics.currentTopic === "Default"
          ? hotTopics.topics?.map((value, i) => {
              return (
                <button
                  className="options__button"
                  id={"Option" + i}
                  key={i}
                  onClick={() => {
                    sendMessage(value?.name);
                  }}
                  disabled={
                    isDisabled ||
                    !isConnected ||
                    currentlyInForm ||
                    !slowmode.notActive
                  }
                >
                  {value?.name}
                </button>
              );
            })
          : subTopics.subTopics?.map((value, i) => {
              {
                /* already talking about a topic */
              }
              return (
                <button
                  className="options__button"
                  id={"Option" + i}
                  key={i}
                  onClick={() => {
                    sendMessage(value?.name);
                  }}
                  disabled={
                    isDisabled ||
                    !isConnected ||
                    currentlyInForm ||
                    !slowmode.notActive
                  }
                >
                  {value?.name}
                </button>
              );
            })}
      </div>
      <form className="input-deck" type="submit" onSubmit={handleSubmit}>
        <div className="input-deck__input-container">
          <textarea
            disabled={
              characterCount > 200 ||
              isDisabled ||
              !isConnected ||
              !slowmode.notActive
            }
            onChange={handleChange}
            maxLength={200}
            className="input-deck__text"
            type="text"
            name="chat"
            id="chat"
            value={text}
            rows={5}
            autoFocus
            placeholder="Acá vas a poder escribir tus preguntas."
            onKeyDown={handleEnterKey}
          ></textarea>
          <button
            className="input-deck__button"
            type="submit"
            disabled={
              characterCount > 200 ||
              isDisabled ||
              !isConnected ||
              !slowmode.notActive
            }
          >
            <span className="input-deck__button-text">Enviar </span>
            <img
              className="input-deck__button-icon"
              src={sendIcon}
              alt="send button"
            ></img>
          </button>
        </div>
      </form>
    </div>
  );
});
export default InputDeck;
