// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-theme=light] {
  --background-empty-medal: var(--lightGray);
}

body[data-theme=dark] {
  --background-empty-medal: var(--darkGray);
}

.empty-box {
  min-width: 9vw;
  min-height: 9vw;
  align-items: center;
  justify-content: center;
  background-color: var(--background-empty-medal);
  display: flex;
  border-radius: 5px;
  padding: 1px;
  box-shadow: 0px 2px 3px #888888;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.1;
  text-align: center;
}

@media (max-width: 576px) {
  .empty-box {
    min-width: 18vw;
    min-height: 18vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/commons/custom-avatar/lockMedalCase.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;AACF;;AAEA;EACE,yCAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,+CAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE;IACE,eAAA;IACA,gBAAA;EACF;AACF","sourcesContent":["body[data-theme=\"light\"] {\n  --background-empty-medal: var(--lightGray);\n}\n\nbody[data-theme=\"dark\"] {\n  --background-empty-medal: var(--darkGray);\n}\n\n.empty-box {\n  min-width: 9vw;\n  min-height: 9vw;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--background-empty-medal);\n  display: flex;\n  border-radius: 5px;\n  padding: 1px;\n  box-shadow: 0px 2px 3px #888888;\n  font-size: 2rem;\n  font-weight: 400;\n  line-height: 1.1;\n  text-align: center;\n}\n\n@media (max-width: 576px) {\n  .empty-box {\n    min-width: 18vw;\n    min-height: 18vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
