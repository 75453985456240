import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./errorPopup.scss";
import LostDigi from "../../../../styles/assets/backgroundSVGsDigi/LostDigi.svg";
import { BotsContext } from "../../../../context/botsContext";

const ErrorPopup = () => {
  const { setShowErrorPopup } = useContext(BotsContext);
  return (
    <div className="error-popup">
      <img
        src={LostDigi}
        alt="Digi Avatar lost"
        className="not-found-container__info__avatar"
      />
      <span className="error-popup__message">Ups, error de conexión</span>
      <span className="error-popup__description">
        No se pudo conectar con el servidor. Por favor, ingresá más tarde.
      </span>
      <div className="error-popup__button-container">
        <button
          className="error-popup__chatbot-button"
          onClick={() => {
            setShowErrorPopup(false);
          }}
        >
          <Link to="/chat" className="error-popup__button-link">
            Ir al chatbot
          </Link>
        </button>
        {
          //<button className="error-popup__refresh-button" onClick={() => window.location.reload()}>Refrescar app</button>
        }
      </div>
    </div>
  );
};

export default ErrorPopup;
