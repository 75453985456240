import { TickSVG } from "../../../styles/assets/icons";
import "./challengeList.scss";

const ChallengeList = ({ title, dataList, type }) => {
  return (
    <div className="challenge-list-container">
      <p className="challenge-list-container__title">{title}</p>
      <ul className="challenge-list-container__list">
        {dataList.map((item, index) => (
          <li className="challenge-list-container__list__item" key={index}>
            <div className="challenge-list-container__list__item__box">
              <p className="challenge-list-container__list__item__box__text">
                {item.type}
              </p>
              <p
                className={`challenge-list-container__list__item__box__text${
                  type === "accomplished" ? "--green" : "--tangerine"
                }`}
              >
                {type === "accomplished" ? <TickSVG /> : null} &nbsp; +{" "}
                {item.value}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChallengeList;
