// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-elements-radio, .container-elements-radio--clicked {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.container-elements-radio[disabled], [disabled].container-elements-radio--clicked {
  opacity: 40%;
  cursor: default;
  pointer-events: none;
}

.radioOptions-radio, .radioOptions-radio--clicked {
  min-width: 25px;
  border-radius: 0.8rem;
}

.radioOptions-radio--clicked {
  border: 7.5px solid orange;
}

.function-column + .function-column {
  margin-top: 10px;
}

.wrapper-containerHorizontalButtons {
  width: 90%;
}

.orientationButtons-radioButtons-modifier {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/commons/chat-components/messages/RadioOptions/radioOptions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AACF;AAAE;EACE,YAAA;EACA,eAAA;EACA,oBAAA;AAEJ;;AAKA;EACE,eAAA;EACA,qBAAA;AAFF;;AAIA;EAEE,0BAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAMA;EACE,UAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;AAHF","sourcesContent":[".container-elements-radio {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  &[disabled]{\n    opacity: 40%;\n    cursor: default;\n    pointer-events: none;\n  }\n}\n.container-elements-radio--clicked {\n  @extend .container-elements-radio;\n}\n\n.radioOptions-radio {\n  min-width: 25px;\n  border-radius: 0.8rem;\n}\n.radioOptions-radio--clicked {\n  @extend .radioOptions-radio;\n  border: 7.5px solid orange;\n}\n\n.function-column + .function-column {\n  margin-top: 10px;\n  \n}\n\n.wrapper-containerHorizontalButtons {\n  width: 90%;\n}\n\n.orientationButtons-radioButtons-modifier {\n  display: flex;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
