import { Link } from "react-router-dom";
import LostDigi from "../../styles/assets/backgroundSVGsDigi/LostDigi.svg";
import "./errorView.scss";

const ErrorInfo = {
  "not-found-error": {
    title: "¡Ups!",
    firstSubtitle: "Error 404 | Lo que estás buscando no se encuentra acá.",
    secondSubtitle: "Podés verificar la dirección URL o volver al inicio.",
  },
  "server-error": {
    title: "¡Ups!",
    firstSubtitle: "Error 500 | Por el momento no estamos disponibles.",
    secondSubtitle:
      "Digi se fue a hacer un café, volvé a  ingresar en un rato.",
  },
};

/**
 * View when the path is not found
 *
 * @param {object} props
 * @param {keyof ErrorInfo} props.errorType The type of error encountered
 * @returns {React.ReactNode}
 */
const ErrorView = ({ errorType }) => {
  const errorInfo = ErrorInfo[errorType] ?? ErrorInfo["server-error"];

  return (
    <div className="not-found-container">
      <div className="not-found-container__info">
        <p className="not-found-container__info__title">{errorInfo.title}</p>
        <p className="not-found-container__info__first-subtitle">
          {errorInfo.firstSubtitle}
        </p>
        <img
          src={LostDigi}
          alt="Digi Avatar lost"
          className="not-found-container__info__avatar"
        />
        <p className="not-found-container__info__second-subtitle">
          {errorInfo.secondSubtitle}
        </p>
        <Link to="/chat" className="not-found-container__info__link">
          <button className="not-found-container__info__button">
            Ir al inicio
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorView;
