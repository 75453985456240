import "./welcomeCard.scss";

/**
 * Card component to make a welcome message, these cards are the items inside the grid
 *
 * @param {object} props
 * @param {{ element: React.ReactNode }[]} props.content
 * @param {number} props.index
 * @returns {React.ReactNode}
 */
const WelcomeCard = ({ content, index }) => {
    return (
        <div className="card_content" id={"card" + index} key={index}>
            {content.map((item, index) => {
                return (
                    <div className="card_content--element" key={index}>
                        {item.element}
                    </div>
                );
            })}
        </div>
    );
};

export default WelcomeCard;
