
/**
 *
 * @param {object} props
 * @param {[string, string, string, string]} props.dots
 * @returns {React.ReactNode}
 */
const DotsSVG = ({ dots }) => {
  return (
    <svg
      width="61"
      height="6"
      viewBox="0 0 61 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="title-rank--dots"
    >
      <circle cx="3" cy="3" r="3" transform="rotate(-90 3 3)" fill={dots[0]} />
      <circle
        cx="21.332"
        cy="3"
        r="3"
        transform="rotate(-90 21.332 3)"
        fill={dots[1]}
      />
      <circle
        cx="39.668"
        cy="3"
        r="3"
        transform="rotate(-90 39.668 3)"
        fill={dots[2]}
      />
      <circle
        cx="58"
        cy="3"
        r="3"
        transform="rotate(-90 58 3)"
        fill={dots[3]}
      />
    </svg>
  );
};

export default DotsSVG;
