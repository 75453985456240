import "../OptionsMessage/optionsMessage.scss";
import "./buttonsSection.scss";
import { useState, useContext } from "react";
import { SocketContext } from "../../../../context/socketContext";
import { UserContext } from "../../../../context/userContext";
import { Link, useLocation } from "react-router-dom";

const ButtonsSection = ({
  options,
  containerDirection,
  indexElementBubble,
  length,
}) => {
  const [indexElementBubbleButton] = useState(indexElementBubble);

  const [lengthButton] = useState(length);
  const { emitMessage } = useContext(SocketContext);
  const { user } = useContext(UserContext);
  const { name } = user;
  const location = useLocation();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const orientationButtons = {
    buttonsContainer:
      options.length > 15 || containerDirection === "vertical"
        ? "containerVerticalButtons" //isn't use
        : indexElementBubbleButton === 0 && lengthButton === 1
        ? "containerHorizontalButtons--unique"
        : indexElementBubbleButton === 0
        ? "containerHorizontalButtons--first"
        : indexElementBubbleButton === lengthButton - 1
        ? "containerHorizontalButtons--last"
        : "containerHorizontalButtons",
    optionButton:
      options.length > 15 || containerDirection === "vertical"
        ? "verticalButton" //isn't use
        : "horizontalButton",
  };
  const handleOption = (option) => {
    if (option) {
      if (option.includes("-")) {
        let arrayResponse = option.split("-");
        option = arrayResponse[1];
      }
      emitMessage("chat", { name, option });
    }
    setButtonDisabled(!isButtonDisabled);
  };

  return (
    <>
      <div className={"wrapper-" + orientationButtons.buttonsContainer}>
        {options?.map((option, idx) => {
          return (
            <button
              className={
                orientationButtons.optionButton +
                " " +
                (idx === 0 ? "tittle-subsection-buttons" : null)
              }
              key={option + idx}
              onClick={() => {
                handleOption(option);
              }}
              disabled={isButtonDisabled}
            >
              {option === "Reportar" ? (
                <Link
                  to={"/bugs"}
                  state={{ location }}
                  className="feedbackReport"
                >
                  {option}
                </Link>
              ) : (
                option
              )}
            </button>
          );
        })}
      </div>
    </>
  );
};
export default ButtonsSection;
