import { avatarBackgrounds, avatarColors } from "../../styles/svg/avatarSVG";

export default function getUnlockedCustoms(points, type) {
  var result = [];
  const lockedItem = {
    level: 1000,
    avatar: { svg: type, color: "gray", unlocked: false },
  };
  const arrayitems = type === "AvatarSVG" ? avatarColors : avatarBackgrounds;
  arrayitems.forEach((ele) => {
    if (ele.level === 1) {
      result.push({ ...ele.avatar, unlocked: true });
    } else if (points >= 100 && ele.level === 2)
      result.push({ ...ele.avatar, unlocked: true });
    else if (points >= 499 && ele.level === 3)
      result.push({ ...ele.avatar, unlocked: true });
    else if (points >= 1499 && ele.level === 4)
      result.push({ ...ele.avatar, unlocked: true });
    else if (points >= 8999 && ele.level === 5)
      result.push({ ...ele.avatar, unlocked: true });
    else result.push(ele.avatar);
  });
  const num = 12 - result.length;
  if (num > 0 && num < 12) {
    for (let i = 0; i < num; i++) {
      result.push(lockedItem);
    }
  }
  return result;
}
