import React from "react";
import { useEffect } from "react";
import "./donutChart.scss";

// size is the size of the SVG, strokeWidth is the width of the SVG, percent is the percent of the SVG

const DonutChart = ({ size, strokewidth, propsDonut }) => {
  const percent = propsDonut.percentage;
  const colorDonut = propsDonut.color;
  const points = propsDonut?.points ? propsDonut.points : 0;
  const halfsize = size * 0.5;
  const radius = halfsize - strokewidth * 0.1;
  const circumference = 2 * Math.PI * radius;
  const strokeval = (percent * circumference) / 100;
  const dashval = strokeval + " " + circumference;
  const trackstyle = { strokeWidth: strokewidth };
  const indicatorstyle = { strokeWidth: strokewidth, strokeDasharray: dashval };
  const rotateval = "rotate(-90 " + halfsize + "," + halfsize + ")";

  const containersClasses = {
    //loading skeleton on the donut
    donutchartTrack:
      propsDonut.color === undefined
        ? "donutchart-track--skeleton"
        : "donutchart-track",
    donutchartIndicator:
      propsDonut.color === undefined
        ? "donutchart-indicator--skeleton"
        : "donutchart-indicator",
  };

  useEffect(() => {
    //set the color of the donut (depends of the level)
    document.documentElement.style.setProperty(
      "--donutProgressColor",
      `${colorDonut}`
    );
  }, [colorDonut]);

  return (
    <div className="container-donut-description">
      <svg width={size} height={size} className="donutchart">
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={trackstyle}
          className={containersClasses.donutchartTrack}
        />
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={indicatorstyle}
          className={containersClasses.donutchartIndicator}
        />
      </svg>

      <div className="container-description-donut">
        <div
          className="donutchart-text"
          x={halfsize}
          y={halfsize}
          style={{ textAnchor: "middle" }}
        >
          <p className="donutchart-text-val" y={halfsize - 10}>
            {points >= 1000
              ? points >= 100000
                ? Math.trunc(points / 1000) + "K"
                : Math.round((points / 1000) * 10) / 10 + "K"
              : Math.trunc(points)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
