import { useContext, useState, useEffect } from "react";
import "./npsPopup.scss";
import { PopupsContext } from "../../context/popupsContext";
import { RemoveCookie, SetCookie } from "../../helpers/cookiesManage";
import { sendNps } from "../../services/nps/npsService";
import { UserContext } from "../../context/userContext";
import { CloseNoCircleSVG } from "../../styles/assets/icons";
import Modal from "react-bootstrap/Modal";
import SelectScoreBar from "../select-score-bar/SelectScoreBar";

/**
 * NPS POPUT
 * Will give the user the option to give a feedback about the app.
 *
 * @param {object} props
 * @param {boolean} [props.showNpsPopup] Currently unused
 * @returns {React.ReactNode}
 */
const NpsPopup = ({ showNpsPopup }) => {
  const [scoreNps, setScoreNps] = useState({
    score: null,
    comment: "",
    continue: false,
  });
  const [userData, setUserData] = useState();
  const { setShowConfirmationPopup } = useContext(PopupsContext);
  const { apiToken } = useContext(UserContext);
  const [colorBottom, setColorBottom] = useState("black");

  useEffect(() => {
    //we need the user data first
    if (sessionStorage.getItem("userData")) {
      setUserData(JSON.parse(sessionStorage.getItem("userData")));
    } else {
      return null;
    }
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "light") {
        setColorBottom("black");
      } else {
        setColorBottom("white");
      }
    }
  }, []);

  async function handleClickEnviar() {
    try {
      setShowConfirmationPopup((prevState) => ({
        ...prevState,
        show: null,
      }));
      const email = userData.email;
      const customJson = {
        scoreNps: scoreNps.score,
        commentNps: scoreNps.comment,
      };
      await sendNps(email, customJson, apiToken);

      //se muestra el popup de confirmacion
      setShowConfirmationPopup((prevState) => ({
        ...prevState,
        show: "npsConfirmationPopup",
      }));
      let dateNps = new Date().toString();

      RemoveCookie("dateLastNps");
      SetCookie("dateLastNps", dateNps);
    } catch (err) {
      console.log(err);
    }
  }

  function handleClickOmitir() {
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
    }));
  }
  function handleClickScore(value) {
    setScoreNps({ ...scoreNps, score: value });
  }

  const onChangeComment = ({ target }) => {
    const { value } = target;
    setScoreNps({ ...scoreNps, comment: value });
  };

  const npsScores = [
    { title: "1", scorePoints: 1 },
    { title: "2", scorePoints: 2 },
    { title: "3", scorePoints: 3 },
    { title: "4", scorePoints: 4 },
    { title: "5", scorePoints: 5 },
    { title: "6", scorePoints: 6 },
    { title: "7", scorePoints: 7 },
    { title: "8", scorePoints: 8 },
    { title: "9", scorePoints: 9 },
    { title: "10", scorePoints: 10 },
  ];

  const handleClose = () =>
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
    }));

  const changeView = () => {
    setScoreNps({ ...scoreNps, continue: true });
  };

  const textAreaNPS = (title) => {
    return (
      <div className="nps-textarea-container">
        <div className="popup-nps-container-header-titles">
          <div className="popup-nps-title">{title}</div>
        </div>
        <textarea
          name="bug_report"
          id=""
          cols="30"
          rows="10"
          placeholder="Tu feedback nos ayuda a mejorar, compartinos tu experiencia."
          value={scoreNps.comment}
          onChange={onChangeComment}
          required
        ></textarea>
        {scoreNps.comment?.length < 10 && scoreNps.comment?.length > 0 ? (
          <div className="validation-text-error">
            *Tu comentario debe tener mínimamente 10 caracteres
          </div>
        ) : (
          <div className="emptyDiv">*</div>
        )}
      </div>
    );
  };

  const scorePointsNPS = () => {
    return (
      <div className="container-scores-bottoms">
        <div className="container-inner-score-elements">
          {npsScores.map(({ title, scorePoints }, i) => {
            return (
              <div
                key={i}
                className={"score-nps-box"}
                onClick={() => handleClickScore(i + 1)}
              >
                {<span className="nps-numbers">{title}</span>}
              </div>
            );
          })}
        </div>
        <SelectScoreBar scoreNps={scoreNps} setScoreNps={setScoreNps} />
        <div className="container-score-subtitles">
          <div className="subtitle-mobile">👎</div>
          <div className="subtitle-desktop">Se puede mejorar 👎</div>
          <div className="subtitle-mobile">
            <p id="value"></p>
          </div>
          <div className="subtitle-mobile">👍</div>
          <div className="subtitle-desktop">Me encanta 👍</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal show={true} onHide={handleClose} centered>
        <Modal.Header>
          {!scoreNps.continue && (
            <div className="popup-nps-container-header-titles">
              <div className="popup-nps-title">
                ¿Cómo puntuarías al asistente digital Digi?
              </div>

              <div className="popup-nps-title-close-bottom">
                <div onClick={handleClickOmitir}>
                  <CloseNoCircleSVG color={colorBottom} />
                </div>
              </div>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          {/* MOBILE */}
          <div className="mobile">
            {!scoreNps.continue && scorePointsNPS()}
            {scoreNps.continue ? (
              scoreNps.score <= 6 ? (
                textAreaNPS("¿Qué fue lo que no te gustó de tu experiencia?")
              ) : (
                textAreaNPS("¿Te gustaría dejar algún comentario adicional?")
              )
            ) : (
              <></>
            )}
          </div>
          {/* DESKTOP VIEW */}
          <div className="desktop">
            {scorePointsNPS()}

            {scoreNps.score !== null ? (
              scoreNps.score <= 6 ? (
                textAreaNPS("¿Qué fue lo que no te gustó de tu experiencia?")
              ) : (
                textAreaNPS("¿Te gustaría dejar algún comentario adicional?")
              )
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* MOBILE VIEW */}
          <div className="mobile">
            <div className="container-buttons">
              {scoreNps.continue ? (
                <button className="omitir-button">
                  <div
                    className="omitir-button-content"
                    onClick={() =>
                      setScoreNps({ ...scoreNps, score: null, continue: false })
                    }
                  >
                    Volver
                  </div>
                </button>
              ) : (
                <button className="omitir-button">
                  <div
                    className="omitir-button-content"
                    onClick={handleClickOmitir}
                  >
                    Omitir
                  </div>
                </button>
              )}
              {scoreNps.continue ? (
                <button
                  className="enviar-button"
                  type="submit"
                  onClick={handleClickEnviar}
                  disabled={
                    scoreNps.score > 6
                      ? scoreNps.comment?.length > 0 &&
                        scoreNps.comment?.length < 10
                      : scoreNps.comment?.length < 10
                  }
                >
                  Enviar
                </button>
              ) : (
                <button
                  className="enviar-button"
                  type="Button"
                  onClick={changeView}
                  disabled={!scoreNps.score || scoreNps.score === null}
                >
                  Continuar
                </button>
              )}
            </div>
          </div>
          {/* DESKTOP VIEW */}
          <div className="desktop">
            <div className="container-buttons">
              {scoreNps.score === null && (
                <button className="omitir-button">
                  <div
                    className="omitir-button-content"
                    onClick={handleClickOmitir}
                  >
                    Omitir
                  </div>
                </button>
              )}

              <button
                className="enviar-button"
                type="submit"
                onClick={handleClickEnviar}
                disabled={
                  scoreNps.score > 6
                    ? scoreNps.comment?.length > 0 &&
                      scoreNps.comment?.length < 10
                    : scoreNps.comment?.length < 10
                }
              >
                Enviar
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NpsPopup;
