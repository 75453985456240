import axios from "axios";

const topicsURL = `${process.env.REACT_APP_BACK_API}chat/topics`;

export const getTopics = async (los, token) => {
  // Axios get request for the hot topics, with their respective counter.
  try {
    const topicsResponse = await axios({
      method: "GET",
      url: topicsURL,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${token}`
      },
      params: {
        los,
      },
    });
    return topicsResponse;
  } catch (err) {
    return err;
  }
};
