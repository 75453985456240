import { useContext, useEffect } from "react";
import NpsPopup from "../nps-popup/NpsPopup";
import { SessionContext } from "../../context/sessionContext";
import { PopupsContext } from "../../context/popupsContext";
import CustomModal from "../custom-modal/CustomModal";
import StatsPopup from "../stats-popup/StatsPopup";

const FullLayoutPopup = () => {
  const {
    showConfirmationPopup,
    setShowConfirmationPopup,
    showCustomModal,
    showPopupStats,
  } = useContext(PopupsContext);
  const { showPopupNps, setShowPopupNps } = useContext(SessionContext);

  useEffect(() => {
    if (
      showPopupNps.monthlyPeriodExpired === true &&
      showPopupNps.alreadyShowedNps === false &&
      showPopupNps.endSession === true &&
      showPopupNps.aboveAverageScreenTime === true
    ) {
      setShowPopupNps((prev) => ({ ...prev, alreadyShowedNps: true }));
      setShowConfirmationPopup((prev) => ({
        ...prev,
        show: "npsPopup",
      }));
    }
    // eslint-disable-next-line
  }, [showPopupNps]);

  return (
    <>
      {showConfirmationPopup.show === "npsPopup" && (
        <>
          <NpsPopup />
        </>
      )}
      {showCustomModal.show && (
        <CustomModal message={showCustomModal.message} />
      )}
      {showPopupStats.show && <StatsPopup />}
    </>
  );
};

export default FullLayoutPopup;
