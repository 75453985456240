import { addAnswerInTempFormData } from "./addAnswerInTempFormData";
import { changeTempFormDataWithEditedResponses } from "./changeTempFormDataWithEditedResponses";
export function addResponsesToTempFormData(
  nameOfKey,
  jsObj,
  data,
  tempFormData,
  tempArrayEditedResponses
) {
  if (nameOfKey.includes("-")) {
    var checkIfSubmit = nameOfKey.split("-")[1];
    var checkIsEditar = nameOfKey.split("-").at(-1);
  }
  if (
    checkIfSubmit !== "Submit" &&
    checkIsEditar !== "Editar datos ingresados" &&
    nameOfKey !== "Form-Editar respuesta"
  ) {
    var isFormAnswer = false;
    if (nameOfKey !== undefined && nameOfKey.includes("-")) {
      isFormAnswer = true;
    }
    if (isFormAnswer && nameOfKey.split("-")[1] === "Checkbox") {
      jsObj[nameOfKey] = data.response?.optionsCheckbox;
    } else {
      jsObj[nameOfKey] = data.response.originalUserText;
    }

    //next block filters data about to be added to the form:
    //checks if the data is !== volver or if its repeated data
    //checks if its data being changed while editing
    var variableArrayEditedResponses = addAnswerInTempFormData(
      nameOfKey,
      jsObj,
      data,
      tempFormData
    );
    if (variableArrayEditedResponses !== undefined) {
      tempArrayEditedResponses.push(variableArrayEditedResponses);
    }
    if (data.response?.intent.includes("-")) {
      if (
        data.response?.intent.split("-")[1] === "Submit" &&
        data.response?.intent_match === "Form - Coded go back to submit" &&
        data.response?.numberOfPageAfterEditar !== -1
      ) {
        tempArrayEditedResponses.forEach((item) =>
          changeTempFormDataWithEditedResponses(item, tempFormData)
        );
      }
    }
  }
  return;
}
