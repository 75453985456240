import "./formStyle.scss";
import Input from "../../commons/input/Input";
import DropdownInput from "../../commons/input/DropdownInput";
import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormContext } from "../../context/formContext";
import { postIssueForm, formURL } from "../../services/forms/issueFormService";
import { appInsightsTracker } from "../../helpers/AppInsights";
import { SessionContext } from "../../context/sessionContext";
import { ScreenContext } from "../../context/screenContext";
import { PopupsContext } from "../../context/popupsContext";
import { UserContext } from "../../context/userContext";
import { deleteToken } from "../../services/login/loginService";

const FormIssueView = () => {
  const [issueValues, setIssueValues] = useState({
    location: null,
    floor: null,
    resource: null,
    resource_id: null,
    incident: null,
    description: "",
    parkingNumber: "",
    licensePlate: "",
    parkingEmergency: "",
    mail: "",
    date: "",
    time: "",
  });

  const [formOptionsValues, setFormOptionsValues] = useState({
    recursosElegidos: [],
    incidentesElegidos: [],
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showParkingInputs, setShowParkingInputs] = useState(false);
  const [descriptionPlaceholder, setDescriptionPlaceholder] = useState(
    "Descripción del incidente"
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [idForm] = useState(Date.now());

  const { setIsScreenLoading } = useContext(ScreenContext);

  const {
    openDropdown,
    handleToggleDropdown,
    handleCloseDropdown,
    issueFormOptions,
    setIssueFormOptions,
  } = useContext(FormContext);

  const { setShowConfirmationPopup, setShowCustomModal } =
    useContext(PopupsContext);

  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);

  const { user, apiToken } = useContext(UserContext);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const locacion = urlParams.getAll("locacion")[0];
    const piso = urlParams.getAll("piso")[0];

    if (locacion?.length > 0 && piso?.length > 0)
      setIssueValues((prevValues) => ({
        ...prevValues,
        location: locacion,
        floor: piso,
      }));

    if (
      issueFormOptions.incidentesIdentificados.length === 0 ||
      issueFormOptions.recursosIdentificados.length === 0
    ) {
      let Incidentes, Identificados;

      const fetchInfraData = async () => {
        try {
          setIsScreenLoading({
            state: true,
            text: "Buscando contenido de reportes",
          });
          const response = await fetch(formURL, {
            method: "POST",
          });
          if (response.status === 200) {
            ({ Incidentes, Identificados } = await response.json());
            setIssueFormOptions({
              incidentesIdentificados: Incidentes,
              recursosIdentificados: Identificados,
            });
          } else {
            setShowCustomModal({
              show: true,
              message: {
                title: "Formulario no disponible",
                subtitle:
                  "No se pudo obtener la información del formulario, intente nuevamente más tarde.",
                secondButton: {
                  title: "Volver con Digi",
                  onClickFunction: () => {
                    setShowCustomModal({ show: false });
                    navigate("/chat");
                  },
                },
              },
            });
          }
          setIsScreenLoading({ state: false });
        } catch (error) {
          console.log("Error:", error);
          setShowCustomModal({
            show: true,
            message: {
              title: "Formulario no disponible",
              subtitle:
                "No se pudo obtener la información del formulario, intente nuevamente más tarde.",
              secondButton: {
                title: "Volver con Digi",
                onClickFunction: () => {
                  setShowCustomModal({ show: false });
                  navigate("/chat");
                },
              },
            },
          });
          setIsScreenLoading({ state: false });
        }
      };
      fetchInfraData();
    }
    appInsightsTracker.pageView("Issue Form");
  }, []);

  useEffect(() => {
    issueFormOptions.recursosIdentificados &&
      setFormOptionsValues({
        ...formOptionsValues,
        recursosElegidos: issueFormOptions.recursosIdentificados.filter(
          (recurso) => {
            return (
              recurso["LOCACION"] === issueValues.location &&
              recurso["PISO"] === issueValues.floor
            );
          }
        ),
      });
  }, [issueFormOptions.recursosIdentificados]);

  useEffect(() => {
    setButtonDisabled(
      issueValues.location === null ||
        issueValues.floor === null ||
        issueValues.resource === null ||
        issueValues.resource_id === null ||
        issueValues.incident === null ||
        issueValues.description === "" ||
        (showParkingInputs &&
          (issueValues.parkingNumber === "" ||
          issueValues.licensePlate === "" ||
          issueValues.parkingEmergency === ""
            ? true
            : false))
        ? true
        : false
    );
  }, [issueValues]);

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Issue Form",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Issue Form",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  useEffect(() => {
    setFormOptionsValues({
      ...formOptionsValues,
      recursosElegidos: issueFormOptions.recursosIdentificados.filter(
        (recurso) => {
          return (
            (!issueValues.location ||
              recurso["LOCACION"] === issueValues.location) &&
            (!issueValues.resource ||
              recurso["RECURSO"] === issueValues.resource) &&
            (!issueValues.floor || recurso["PISO"] === issueValues.floor)
          );
        }
      ),
    });
  }, [issueValues.resource, issueValues.location, issueValues.floor]);

  useEffect(() => {
    if (issueValues.resource !== "COCHERAS") {
      setShowParkingInputs(false);
      setIssueValues((prevValues) => ({
        ...prevValues,
        parkingNumber: "",
        licensePlate: "",
        parkingEmergency: "",
      }));
    }
  }, [issueValues.resource]);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Issue Form",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  const handleIncidentChange = (e) => {
    setIssueValues((prevValues) => ({
      ...prevValues,
      incident: e,
      details: null,
      description: "",
    }));

    if (
      e === "Ocuparon cochera reservada" &&
      issueValues.resource === "COCHERAS"
    ) {
      setShowParkingInputs(true);
    } else {
      setShowParkingInputs(false);
      setIssueValues((prevValues) => ({
        ...prevValues,
        parkingNumber: "",
        licensePlate: "",
        parkingEmergency: "",
      }));
    }

    switch (e) {
      case "Funcionamiento":
        if (issueValues.resource === "BAÑOS")
          setDescriptionPlaceholder(
            "Ej Perdida de agua del inodoro/ Rotura del pulsador de la mochila/ Rotura del asiento del inodoro/ Puertas de los boxes/ Perdida de agua en las bachas/ Otros"
          );
        break;
      case "Falta de insumos":
        if (issueValues.resource === "BAÑOS") {
          setDescriptionPlaceholder(
            "Ej. Escasez de papel sanitario/ Escasez de papel de mano/ Escasez de jabon para manos/ Otros"
          );
        } else if (issueValues.resource === "AREAS COMUNES") {
          setDescriptionPlaceholder("Ej. detergente/ esponja/ papel");
        }
        break;
      default:
        setDescriptionPlaceholder("Descripción del incidente");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const incidentValue = issueValues.incident;
    const currentDate = new Date();
    const formdattedDate = currentDate.toLocaleDateString();
    const formdattedTime = currentDate.toLocaleTimeString();

    if (user?.cloudEmail || user?.email) {
      const values = {
        confirmation_mail: {
          from: "richard.sebastian.gonzalez.cabrera@pwc.com",
          to: user?.cloudEmail || user?.email,
          header: "Confirmación del mail",
          body: {
            location: issueValues.location,
            floor: issueValues.floor,
            resource: issueValues.resource,
            resource_id: issueValues.resource_id,
            incident: issueValues.incident,
            description: issueValues.description,
            mail: user?.cloudEmail || user?.email,
            date: formdattedDate,
            time: formdattedTime,
          },
        },
        issue_mail: {
          from: "richard.sebastian.gonzalez.cabrera@pwc.com",
          to: "richard.sebastian.gonzalez.cabrera@pwc.com",
          header: "Issue mail",
          body: "Issue descripción",
        },
      };

      if (incidentValue === "Ocuparon cochera reservada") {
        values.confirmation_mail.body.parkingNumber = issueValues.parkingNumber;
        values.confirmation_mail.body.licensePlate = issueValues.licensePlate;
        values.confirmation_mail.body.parkingEmergency =
          issueValues.parkingEmergency;
      }

      try {
        setIsScreenLoading({ state: true });
        const { data, status } = await postIssueForm(values, apiToken);

        setIsScreenLoading({ state: false });
        if (data === undefined || status === 500 || status === 400) {
          setShowConfirmationPopup((prevState) => ({
            ...prevState,
            show: "error",
          }));
        } else if (status === 200) {
          appInsightsTracker.logFormEvents(
            "Issue Form events",
            "Submitted form",
            "Send button",
            idForm
          );
          setShowConfirmationPopup((prevState) => ({
            ...prevState,
            show: "success",
            message: (
              <p>
                El formulario se envió<span>correctamente</span>.
              </p>
            ),
          }));
        }
        navigate("/chat");
      } catch (error) {
        console.log(error);
        setIsScreenLoading({ state: false });
        setShowConfirmationPopup((prevState) => ({
          ...prevState,
          show: "error",
        }));
        navigate("/chat", { state: { location } });
      }
    }
  };

  function groupBy(array, property) {
    if (!array || !property || !Array.isArray(array)) return {};

    const hashmap = {};
    array.forEach((element) => {
      const key = element[property];
      if (!hashmap[key]) {
        hashmap[key] = [];
      }
      hashmap[key].push(element);
    });

    return hashmap;
  }

  function unique(array, property, ignoreEmpty = false) {
    if (!array || !property || !Array.isArray(array)) return [];

    if (ignoreEmpty) {
      array = array.filter((object) => {
        let value = object[property];
        return value !== undefined && value !== null && value !== "";
      });
    }

    return Object.keys(groupBy(array, property));
  }

  return (
    <div className="form-container">
      <p className="form-container__title">
        Solicitá<span> ayuda </span> a infraestructura.
      </p>
      <p className="form-container__subtitle">
        Podés reportar sobre{" "}
        <strong>
          {" "}
          problemas en infraestructura, logística, limpieza, entre otros que
          hayas tenido en la oficina.
        </strong>
      </p>
      <form
        type="submit"
        className="form-container__form"
        onSubmit={handleSubmit}
      >
        <div className="form-container__form__inputs">
          <DropdownInput
            id="location"
            name="Locación"
            value={issueValues.location}
            valuesArray={unique(
              issueFormOptions.recursosIdentificados,
              "LOCACION",
              true
            )}
            defaultValue={
              issueValues.location?.length > 0
                ? issueValues.location
                : undefined
            }
            isRequired={true}
            handleChange={(e) => {
              setIssueValues((prevValues) => ({
                ...prevValues,
                location: e,
                resource_id: null,
              }));
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Issue Form events",
                "Input click",
                "Location input",
                idForm
              )
            }
            isOpen={openDropdown === "location"}
            handleToggleDropdown={handleToggleDropdown}
            handleCloseDropdown={handleCloseDropdown}
            showError={false}
            errorMessage="rompió"
          />
          <DropdownInput
            id="floor"
            name="Piso"
            value={issueValues.floor}
            valuesArray={unique(
              issueFormOptions.recursosIdentificados,
              "PISO",
              true
            )}
            defaultValue={
              issueValues.floor?.length > 0 ? issueValues.floor : undefined
            }
            isRequired={true}
            handleChange={(e) => {
              setIssueValues((prevValues) => ({
                ...prevValues,
                floor: e,
                resource_id: null,
              }));
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Issue Form events",
                "Input click",
                "Floor input",
                idForm
              )
            }
            isOpen={openDropdown === "floor"}
            handleToggleDropdown={handleToggleDropdown}
            handleCloseDropdown={handleCloseDropdown}
            disabled={issueValues.location === null}
            showError={false}
            errorMessage="rompió"
          />
          <DropdownInput
            id="resource"
            name="Recurso"
            value={issueValues.resource}
            valuesArray={unique(
              issueFormOptions.incidentesIdentificados,
              "RECURSO",
              true
            )}
            isRequired={true}
            handleChange={(e) => {
              e !== issueValues.resource &&
                setIssueValues((prevValues) => ({
                  ...prevValues,
                  resource: e,
                  resource_id: null,
                  incident: null,
                  description: "",
                }));

              const incidentesRecurso =
                issueFormOptions.incidentesIdentificados.filter(
                  (inicidente) => {
                    return inicidente["RECURSO"] === e;
                  }
                );
              setFormOptionsValues({
                ...formOptionsValues,
                incidentesElegidos: unique(incidentesRecurso, "INCIDENTE"),
              });
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Issue Form events",
                "Input click",
                "Resource input",
                idForm
              )
            }
            isOpen={openDropdown === "resource"}
            handleToggleDropdown={handleToggleDropdown}
            handleCloseDropdown={handleCloseDropdown}
            disabled={issueValues.floor === null}
            showError={false}
            errorMessage="rompió"
          />

          {formOptionsValues.recursosElegidos?.length === 0 ? (
            <Input
              type="text"
              name={"Nombre del recurso"}
              value={issueValues.resource_id}
              isRequired={true}
              placeholder="Ingresá el nombre, código o número de recurso "
              handleInput={(e) => {
                setIssueValues((prevValues) => ({
                  ...prevValues,
                  resource_id: e,
                }));
              }}
              handleClick={() =>
                appInsightsTracker.logFormEvents(
                  "Issue Form events",
                  "Input click",
                  "Resource Name input",
                  idForm
                )
              }
              disabled={issueValues.resource === null}
            />
          ) : (
            <DropdownInput
              id="resource_id"
              name="Nombre del recurso"
              value={issueValues.resource_id}
              valuesArray={unique(formOptionsValues.recursosElegidos, "CODIGO")}
              placeholder="Ingresá el nombre, código o número de recurso "
              isRequired={true}
              handleChange={(e) => {
                e !== issueValues.resource_id &&
                  setIssueValues({
                    ...issueValues,
                    resource_id: e,
                  });
              }}
              handleClick={() =>
                appInsightsTracker.logFormEvents(
                  "Issue Form events",
                  "Input click",
                  "Resource Name input",
                  idForm
                )
              }
              isOpen={openDropdown === "resource_id"}
              handleToggleDropdown={handleToggleDropdown}
              handleCloseDropdown={handleCloseDropdown}
              disabled={issueValues.resource === null}
              showError={false}
              errorMessage="rompió"
            />
          )}
          <DropdownInput
            id="incident"
            name="Incidente"
            value={issueValues.incident}
            valuesArray={formOptionsValues.incidentesElegidos}
            isRequired={true}
            handleChange={handleIncidentChange}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Issue Form events",
                "Input click",
                "Incident input",
                idForm
              )
            }
            isOpen={openDropdown === "incident"}
            handleToggleDropdown={handleToggleDropdown}
            handleCloseDropdown={handleCloseDropdown}
            disabled={issueValues.resource_id === null}
            showError={false}
            errorMessage="rompió"
          />
          <Input
            type="text"
            name={"Descripción del incidente"}
            value={issueValues.description}
            isRequired={true}
            placeholder={descriptionPlaceholder}
            handleInput={(e) => {
              setIssueValues((prevValues) => ({
                ...prevValues,
                description: e,
              }));
            }}
            handleClick={() =>
              appInsightsTracker.logFormEvents(
                "Issue Form events",
                "Input click",
                "Description input",
                idForm
              )
            }
            disabled={issueValues.incident === null}
          />
          {showParkingInputs && (
            <>
              <Input
                type="number"
                name={"Número de cochera"}
                value={issueValues.parkingNumber}
                isRequired={true}
                placeholder="Indicá el nro./ código de la cochera ocupada incorrectamente"
                handleInput={(e) => {
                  setIssueValues((prevValues) => ({
                    ...prevValues,
                    parkingNumber: e,
                  }));
                }}
                handleClick={() =>
                  appInsightsTracker.logFormEvents(
                    "Issue Form events",
                    "Input click",
                    "Incident input",
                    idForm
                  )
                }
              />
              <Input
                type="text"
                name={"Patente del vehículo"}
                value={issueValues.licensePlate}
                isRequired={true}
                placeholder="Indicá la patente del vehiculo que la ocupó incorrectamente"
                handleInput={(e) => {
                  setIssueValues((prevValues) => ({
                    ...prevValues,
                    licensePlate: e,
                  }));
                }}
                handleClick={() =>
                  appInsightsTracker.logFormEvents(
                    "Issue Form events",
                    "Input click",
                    "Incident input",
                    idForm
                  )
                }
              />
              <Input
                type="number"
                name={"Número de cochera ocupada de emergencia"}
                value={issueValues.parkingEmergency}
                isRequired={true}
                placeholder="Indícá el nro/ código de la nueva cochera donde estacionaste"
                handleInput={(e) => {
                  setIssueValues((prevValues) => ({
                    ...prevValues,
                    parkingEmergency: e,
                  }));
                }}
                handleClick={() =>
                  appInsightsTracker.logFormEvents(
                    "Issue Form events",
                    "Input click",
                    "Incident input",
                    idForm
                  )
                }
              />
              <div className="custom-input-container__message">
                <p>
                  Volve a ingresar a{" "}
                  <a
                    href="https://hotelling.lan.pwc.com/desks"
                    target="_blank"
                    rel="noreferrer"
                  >
                    hotelling
                  </a>{" "}
                  y reserva una nueva cochera. En caso de no disponibilidad,
                  dirigirse a cocheras de cortesia en 3er subsuelo.
                </p>
              </div>
            </>
          )}
        </div>
        <div className="form-container__form__button-container">
          <button
            className="form-container__form__button-container__cancelation"
            onClick={() => {
              setShowCustomModal({
                show: true,
                message: {
                  title: "Cancelar solicitud",
                  subtitle:
                    "Se borrarán los datos ingresados, ¿querés continuar?",
                  firstButton: {
                    title: "Cancelar",
                    onClickFunction: () => setShowCustomModal({ show: false }),
                  },
                  secondButton: {
                    title: "Confirmar",
                    onClickFunction: () => {
                      setShowCustomModal({ show: false });
                      navigate("/chat");
                    },
                  },
                },
              });
            }}
            type="button"
          >
            Cancelar
          </button>
          <button
            className="form-container__form__button-container__send"
            setIssueValues
            disabled={buttonDisabled}
            type="submit"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormIssueView;
