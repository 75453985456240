import "./DotMesagge.scss";
import { AvatarSVG } from "../../../../styles/svg/avatarSVG";

// Dot next to the message that indicates it's a Digi message
const DotMessage = (param) => {
  const digiConfig = {
    digi: "dot",
    filter: "dot",
  };
  return (
    <div className={param.type}>
      <AvatarSVG {...digiConfig} />
    </div>
  );
};

export default DotMessage;
