import { useContext, useEffect } from "react";
import { ScreenContext } from "../../../context/screenContext";
import { appInsightsTracker } from "../../../helpers/AppInsights";
import "../paView.scss";
import { SessionContext } from "../../../context/sessionContext";
import { deleteToken } from "../../../services/login/loginService";
import { UserContext } from "../../../context/userContext";

const DigitalAppView = () => {
  const { theme } = useContext(ScreenContext);
  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);
  const { apiToken } = useContext(UserContext);

  useEffect(() => {
    appInsightsTracker.pageView("Digital App");
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Digital App",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Digital App",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Digital App",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  return (
    <div className="pa-container">
      <iframe
        src={`https://apps.powerapps.com/play/e/918694ba-022a-e0b2-808f-1e0b2451f509/a/06b44e72-609e-432d-9a3a-71b3d8b14541?tenantId=513294a0-3e20-41b2-a970-6d30bf1546fa&hidenavbar=true&Theme=${theme.theme}`}
        width="99%"
        height="99%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Digital App"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default DigitalAppView;
