import BarChart from "./bar-chart/BarChart";
import RankingLeaderboard from "./ranking/RankingLeaderboard";
import "./leaderboard.scss";

const Leaderboard = () => {
  const isAvailable = new Date().getDate() > 3;

  return (
    <div className="ranking-barchart-container">
      <BarChart isAvailable={isAvailable} />
      <RankingLeaderboard isAvailable={isAvailable} />
    </div>
  );
};

export default Leaderboard;
