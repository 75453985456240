import "./bugsview.scss";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postBug } from "../../services/bugs/bugService";
import { PopupsContext } from "../../context/popupsContext";
import SelectDropdown from "./SelectDropdown/SelectDropdown";
import { OptionsSelectBugsReport } from "./SelectDropdown/OptionsSelectBugsReport";
import { ScreenContext } from "../../context/screenContext";
import { appInsightsTracker } from "../../helpers/AppInsights";
import { SessionContext } from "../../context/sessionContext";
import { UserContext } from "../../context/userContext";
import { deleteToken } from "../../services/login/loginService";

const BugsView = () => {
  const [errors, setErrors] = useState({
    textarea: false,
    archive_upload: false,
  });
  const [historyChat, setHistoryChat] = useState([]);
  const [archive, setArchive] = useState(null);
  const [values, setValues] = useState({
    email: JSON.parse(sessionStorage.getItem("userData"))?.email || "",
    bug_report: "",
    help: false,
    archive_upload: false,
    category_item: "",
  });
  const { setShowConfirmationPopup } = useContext(PopupsContext);
  const { setIsScreenLoading } = useContext(ScreenContext);
  const { apiToken } = useContext(UserContext);
  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    appInsightsTracker.pageView("Reporte de problemas");
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Reporte de problemas",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Reporte de problemas",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Reporte de problemas",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  // Changes the values data when a modification is done
  // for checkbox, this function is not called since it has another logic
  const onChange = ({ target }) => {
    const { name, value } = target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsScreenLoading({ state: true });
      const { data, status } = await postBug({
        values,
        archive,
        historyChat,
        apiToken,
      });
      setShowConfirmationPopup((prevState) => ({
        ...prevState,
        response: data,
      }));
      setIsScreenLoading({ state: false });
      if (data === undefined || status === 500 || status === 400) {
        setShowConfirmationPopup((prevState) => ({
          ...prevState,
          show: "error",
        }));
      } else if (status === 200) {
        setShowConfirmationPopup((prevState) => ({
          ...prevState,
          show: "success",
          message: (
            <p>
              Tu reporte se envío<span>correctamente</span> ¡Gracias!
            </p>
          ),
        }));
      }
      navigate("/chat");
    } catch (err) {
      setIsScreenLoading({ state: false });
      setShowConfirmationPopup((prevState) => ({
        ...prevState,
        show: "error",
      }));
      navigate("/chat", { state: { location } });
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("chatHistory")) {
      setHistoryChat(JSON.parse(sessionStorage.getItem("chatHistory")));
    }
  }, []);

  const validateFile = (target) => {
    if (
      target === undefined ||
      target?.type.includes("image/png") ||
      target?.type.includes("image/jpeg") ||
      target?.type.includes("image/jpg")
    ) {
      if (target === undefined) {
        setValues({
          ...values,
          archive_upload: "",
        });
        setArchive(null);
      } else if (target?.size <= 10485760) {
        setArchive(target);
        setValues({ ...values, archive_upload: target.name });
        setErrors({
          ...errors,
          archive_upload: false,
        });
      } else {
        setValues({
          ...values,
          archive_upload: "Máximo de 10MB.",
        });
        setArchive(null);
        setErrors({
          ...errors,
          archive_upload: true,
        });
      }
    } else {
      setValues({
        ...values,
        archive_upload: "Solo se puede subir en formato .jgp o .png",
      });
      setErrors({
        ...errors,
        archive_upload: true,
      });
      setArchive(null);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    validateFile(event.dataTransfer.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    validateFile(event.target.files[0]);
  };

  return (
    <div className="bug-report-container">
      <p className="bug-report-container__title">Reporte</p>
      <form
        className="bug-report-container__form"
        type="submit"
        onSubmit={handleSubmit}
      >
        <p className="bug-report-container__form__title">
          Envia tu sugerencia o mensaje al equipo de Digital Assistant.
        </p>
        <p className="bug-report-container__form__subtitle">
          Compartí tu idea o reportá un problema. Siempre podés contactarte con
          nuestro equipo de soporte.
        </p>
        <div className="bug-report-container__form__dropdown-container">
          <SelectDropdown
            title={"Seleccioná una opción"}
            list={OptionsSelectBugsReport}
            name="bug_report"
            setValues={setValues}
          />
        </div>
        <div className="bug-report-container__form__textarea-container">
          <textarea
            name="bug_report"
            id=""
            cols="30"
            rows="10"
            placeholder="Escribí tu comentario acá"
            value={values.bug_report}
            onChange={onChange}
            required
          ></textarea>
          <span className="bug-report-container__form__textarea-container__error-text">
            {values.bug_report?.length < 10 && values.bug_report.length >= 1 ? (
              "*Tu reporte debe tener mínimamente 10 caracteres"
            ) : (
              <>&nbsp;</>
            )}
          </span>
        </div>
        <div className="bug-report-container__form__archive">
          <p className="bug-report-container__form__archive__title">
            Subir un archivo (opcional)
          </p>
          <p className="bug-report-container__form__archive__subtitle">
            Podes adjuntar archivos JPG o PDF. El tamaño del archivo no debe
            superar los 10MB.
          </p>
          <label
            className={`bug-report-container__form__archive__button${
              errors.archive_upload ? "--error" : ""
            }`}
            htmlFor="archive"
          >
            <div
              className="bug-report-container__form__archive__button__drag-drop-container"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <input
                name="archive"
                id="archive"
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileChange}
              ></input>
              <p className="bug-report-container__form__archive__button__drag-drop-container__text">
                {values.archive_upload && !errors.archive_upload ? (
                  values.archive_upload
                ) : (
                  <>
                    Arrastra y suelta o{" "}
                    <b>
                      <u>sube el archivo</u>
                    </b>
                    .
                  </>
                )}
              </p>
            </div>
          </label>
          <p className="bug-report-container__form__archive__error-text">
            &nbsp;
            {errors.archive_upload && values.archive_upload}
          </p>
        </div>
        <label
          htmlFor="Contact"
          className="bug-report-container__form__checkbox-container"
        >
          <input
            type="checkbox"
            name="Contact"
            id="Contact"
            className="bug-report-container__form__checkbox-container__input"
            onChange={() =>
              setValues({
                ...values,
                help: !values.help,
              })
            }
          />
          <svg
            className="bug-report-container__form__checkbox-container__check"
            width="24"
            height="24"
          >
            <polyline points="15 3 8 15 4 12"></polyline>
          </svg>
          <span>
            Doy permiso a ser contactado por el equipo con respecto a este
            reporte que envio.
          </span>
        </label>
        <div className="bug-report-container__form__send-button">
          <button
            disabled={
              values.bug_report?.length < 10 ||
              values.category_item.length === 0
            }
            type="submit"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};
export default BugsView;
