import { useState, useEffect } from "react";
import scorePoints from "../../../helpers/levels/points";
import StarsSVG from "../../../styles/svg/levels/starsSVG";
import DotsSVG from "../../../styles/svg/levels/dotsSVG";
import "./score.scss";

/**
 *
 * @param {object} props
 * @param {unknown} props.statsUser Received over API; check with response io-ts
 * @returns {React.ReactNode}
 */
const Score = (props) => {
  const { statsUser } = props;
  const [userScore, setUserScore] = useState({
    title: "",
    scorePoints: 0,
    dots: [],
    nextScorePoints: 0,
    nextTitle: "",
  });

  useEffect(() => {
    statsUser?.points !== undefined &&
      scorePoints.every((score, i) => {
        if (statsUser.points >= score.scorePoints) {
          i === 0
            ? setUserScore({
                title: score.title,
                scorePoints: score.scorePoints,
              })
            : setUserScore({
                title: score.title,
                scorePoints: score.scorePoints,
                dots: score.dots,
                nextScorePoints:
                  scorePoints[i - 1].scorePoints - statsUser.points,
                nextTitle: scorePoints[i - 1].title,
              });
          return false;
        }
        return true;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsUser]);

  return (
    <>
      <div className={"VR--" + userScore?.title}></div>
      {userScore?.title !== "sensei" ? (
        <div className="title-rank">
          <div style={{ display: "flex", flexDirection: "row" }}>
            Sos{" "}
            <span className={"title-rank--" + userScore?.title}>
              {" " + userScore?.title}
            </span>
          </div>
          <DotsSVG dots={userScore.dots} />
          <div
            style={{
              width: "max-content",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p>
              Estás a {userScore?.nextScorePoints} puntos de convertirte en{" "}
            </p>
            <span className={"title-rank--" + userScore?.nextTitle}>
              {" " + userScore?.nextTitle}
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className="title-rank">
            <div
              style={{
                width: "max-content",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {"¡Wow ya sos "}
                <span className="title-rank--sensei">{"Sensei"}</span>
                {"! "}
              </div>
            </div>
            <StarsSVG />
            <div
              className="title-rank__right-text"
              style={{
                width: "max-content",
                display: "flex",
                flexDirection: "row",
              }}
            >
              Podés ayudarme compartiendo tu sabiduría
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Score;
