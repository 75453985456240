// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-popup {
  position: absolute;
  left: 6.3vw;
  top: 3.2vw;
}`, "",{"version":3,"sources":["webpack://./src/commons/confirmation-popup/confirmationPopup.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;AACF","sourcesContent":[".container-popup {\n  position: absolute;\n  left: 6.3vw;\n  top: 3.2vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
