import React, { useContext, useEffect, useState } from "react";
import calculatingDigiDark from "./assets/CalculatingDigiDark.png";
import calculatingDigiLight from "./assets/CalculatingDigiLight.png";
import { ScreenContext } from "../../../../context/screenContext";
import { getStatsByLos } from "../../../../services/userStats/statsService";
import { UserContext } from "../../../../context/userContext";
import "./barChart.scss";

/**
 *
 * @param {object} props
 * @param {boolean} props.isAvailable
 * @returns {React.ReactNode}
 */
const BarChart = ({ isAvailable }) => {
  const [percentages, setPercentages] = useState([]);
  const { theme, windowSize } = useContext(ScreenContext);
  const [isLoading, setIsLoading] = useState(isAvailable ? true : false);
  const { user, apiToken } = useContext(UserContext);

  const barChartClass = isLoading
    ? "bar-chart__container--blurOn"
    : "bar-chart__container";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getStatsByLos(user.los, apiToken);
        setPercentages(response);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (isAvailable) fetchData();
    else
      setPercentages([
        { totalPoints: 100 },
        { totalPoints: 100 },
        { totalPoints: 100 },
      ]);
  }, [isAvailable, user.los, apiToken]);

  return (
    <div className={barChartClass}>
      <div className="bar-chart__table-header">
        <span className="bar-chart__table-title">
          {windowSize[0] > 768
            ? "Las LoS más activas de este mes - Acceleration Center Buenos Aires"
            : "Top 3 - AC Buenos Aires"}
        </span>
      </div>
      {!isAvailable && (
        <img
          className="bar-chart__image"
          src={
            theme.theme === "dark" ? calculatingDigiDark : calculatingDigiLight
          }
          alt="Digi is calculating"
        />
      )}
      <div
        className="bar-chart"
        style={{
          height: !isAvailable && "fit-content",
          padding: !isAvailable && "1rem 0.4rem 1rem",
        }}
      >
        {percentages.map(({ totalPoints, los }, index) => (
          <div
            key={index}
            className={`barchart-bar bar-${index + 1}`}
            style={{
              height: `${totalPoints + 10}%`,
            }}
          >
            <p className="bar-chart__title">{los}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
