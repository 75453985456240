import { useContext } from "react";
import AutomationCard from "../../../commons/automation-card/AutomationCard";
import { BotsContext } from "../../../context/botsContext";
import digiSearch from "../assets/digiSearch.png";
import books from "../assets/books.png";
import WelcomeDigi from "../../../styles/svg/welcomeSVG";
import { FileSVG, WavyArrowSVG } from "../../../styles/assets/icons";
import "./myAutomationsView.scss";

/**
 *
 * @param {object} props
 * @param {(index: number) => void} [props.setSelectedTab]
 * @returns {React.ReactNode}
 */
const MyAutomationsView = ({ setSelectedTab }) => {
  const { allBots } = useContext(BotsContext);
  const downloadedBots = allBots.filter((bot) => bot.downloaded);

  return (
    <>
      <div className="my-automations-container">
        {downloadedBots.length > 0 ? (
          <div className="my-automations-container__list">
            {downloadedBots.map((bot) => {
              return (
                <>
                  <AutomationCard
                    key={bot.key}
                    title={bot.name}
                    downloaded={bot.downloaded}
                    tags={bot.tags}
                  />
                </>
              );
            })}
          </div>
        ) : (
          <div className="my-automations-container__empty-list">
            <div className="my-automations-container__empty-list__steps">
              <div className="my-automations-container__empty-list__steps__box">
                <img
                  className="my-automations-container__empty-list__steps__box__image"
                  src={digiSearch}
                  alt="Digi searching"
                />
                <div className="my-automations-container__empty-list__steps__box__text">
                  <p className="step-number">1. </p> Buscá la automatización que
                  necesitás en la ventana “Nuevas automatizaciones”.
                </div>
              </div>
              <div className="my-automations-container__empty-list__steps__box">
                <WavyArrowSVG />
              </div>
              <div className="my-automations-container__empty-list__steps__box">
                <img
                  className="my-automations-container__empty-list__steps__box__image"
                  src={books}
                  alt="Digi books"
                />
                <div className="my-automations-container__empty-list__steps__box__text">
                  <p className="step-number">2. </p>Lee la documentación y hacé
                  click en el botón de descargar.
                </div>
              </div>
              <div className="my-automations-container__empty-list__steps__box">
                <WavyArrowSVG />
              </div>
              <div className="my-automations-container__empty-list__steps__box">
                <WelcomeDigi rotation="175.79 deg" width="12rem" />
                <div className="my-automations-container__empty-list__steps__box__text">
                  <p className="step-number">3. </p> Una vez descargada, ejecutá
                  la automatización desde esta ventana.
                </div>
              </div>
            </div>
            <div className="my-automations-container__empty-list__button">
              <button onClick={() => setSelectedTab(0)}>
                Encontrá tu primer automatización
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="my-automations-tips">
        <div className="my-automations-tips__header">
          <div className="my-automations-tips__header__texts">
            <p className="my-automations-tips__header__texts__title">
              📌 Tips para configurar tu automatización
            </p>
            <p className="my-automations-tips__header__texts__subtitle">
              Clickeando “Ver ubicación de la automatización” encontraras varías
              carpetas, las 3 más importantes son:
            </p>
          </div>
          <button
            className="my-automations-tips__header__button"
            onClick={() => {
              window.open(
                "https://pwc.sharepoint.com/:p:/r/sites/Personal244/_layouts/15/Doc.aspx?sourcedoc=%7B2D8B87EA-A995-4257-80AA-56A9EAD85C23%7D&file=User%20guide%20_%20Digital%20Automation.pptx&wdOrigin=TEAMS-MAGLEV.p2p_ns.rwc&action=edit&mobileredirect=true",
                "_blank"
              );
            }}
          >
            Manual de usuario&nbsp;&nbsp;
            <FileSVG />
          </button>
        </div>
        <div className="my-automations-tips__cards-holder">
          <div className="my-automations-tips__cards-holder__card">
            <p className="my-automations-tips__cards-holder__card__title">
              📄 Supporting files
            </p>
            <p className="my-automations-tips__cards-holder__card__subtitle">
              La recomendamos como primera lectura, dentro de esta carpeta vas a
              encontrar un word que contiene el manual de uso, que explica cómo
              modificar el input.
            </p>
          </div>
          <div className="my-automations-tips__cards-holder__card">
            <p className="my-automations-tips__cards-holder__card__title">
              📙 Input
            </p>
            <p className="my-automations-tips__cards-holder__card__subtitle">
              Carpeta en la que se almacenan los archivos de entrada, estos
              suelen ser los archivos que querés modificar.
            </p>
          </div>
          <div className="my-automations-tips__cards-holder__card">
            <p className="my-automations-tips__cards-holder__card__title">
              🎯 Output
            </p>
            <p className="my-automations-tips__cards-holder__card__subtitle">
              Almacena los archivos que se generan al correr la automatización.
              Por ejemplo el word que se crea al convertir un PDF.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAutomationsView;
