// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-theme=light] {
  --background-color-search-input: #ffffff;
  --text-color-search-input: #7d7d7d;
}

body[data-theme=dark] {
  --background-color-search-input: #444444;
  --text-color-search-input: #dededd;
}

.search-input-container {
  display: flex;
  border-radius: 7px;
  padding: 0.5rem;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--background-color-search-input);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.search-input-container__glass {
  padding-right: 0.7rem;
  border-right: 1px solid var(--text-color-search-input);
  height: 1.3rem;
  display: flex;
  margin: auto;
}
.search-input-container__input {
  margin-left: 0.4rem;
  background-color: transparent;
  border-style: none;
  font-family: "PwC Helvetica Neue";
  padding: 0.3rem;
  margin-right: 0.7rem;
  font-size: var(--automation-card-text);
  color: var(--text-color-search-input);
  min-width: 20rem;
}
.search-input-container__button {
  border-style: none;
  background-color: transparent;
  font-size: var(--automation-card-text);
  color: var(--text-color-search-input);
}

@media screen and (max-width: 900px) {
  .search-input-container__input {
    min-width: 12rem;
  }
}
@media screen and (max-width: 768px) {
  .search-input-container__input {
    min-width: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/commons/input/searchInput.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,kCAAA;AACF;;AAEA;EACE,wCAAA;EACA,kCAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,sDAAA;EACA,0CAAA;AACF;AACE;EACE,qBAAA;EACA,sDAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;AACJ;AAEE;EACE,mBAAA;EACA,6BAAA;EACA,kBAAA;EACA,iCAAA;EACA,eAAA;EACA,oBAAA;EACA,sCAAA;EACA,qCAAA;EACA,gBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,6BAAA;EACA,sCAAA;EACA,qCAAA;AADJ;;AAKA;EAEI;IACE,gBAAA;EAHJ;AACF;AAOA;EAEI;IACE,eAAA;EANJ;AACF","sourcesContent":["body[data-theme=\"light\"] {\n  --background-color-search-input: #ffffff;\n  --text-color-search-input: #7d7d7d;\n}\n\nbody[data-theme=\"dark\"] {\n  --background-color-search-input: #444444;\n  --text-color-search-input: #dededd;\n}\n\n.search-input-container {\n  display: flex;\n  border-radius: 7px;\n  padding: 0.5rem;\n  width: fit-content;\n  background-color: var(--background-color-search-input);\n  box-shadow: 0px 1px 4px #00000033;\n\n  &__glass {\n    padding-right: 0.7rem;\n    border-right: 1px solid var(--text-color-search-input);\n    height: 1.3rem;\n    display: flex;\n    margin: auto;\n  }\n\n  &__input {\n    margin-left: 0.4rem;\n    background-color: transparent;\n    border-style: none;\n    font-family: \"PwC Helvetica Neue\";\n    padding: 0.3rem;\n    margin-right: 0.7rem;\n    font-size: var(--automation-card-text);\n    color: var(--text-color-search-input);\n    min-width: 20rem;\n  }\n\n  &__button {\n    border-style: none;\n    background-color: transparent;\n    font-size: var(--automation-card-text);\n    color: var(--text-color-search-input);\n  }\n}\n\n@media screen and (max-width: 900px) {\n  .search-input-container {\n    &__input {\n      min-width: 12rem;\n    }\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .search-input-container {\n    &__input {\n      min-width: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
