import { useState, useContext } from "react";
import "./message.scss";
import FormatMessage from "./FormatMessage";
import { textToURL } from "../../../helpers/validateURL";
import OptionsMessage from "./OptionsMessage/OptionsMessage";
import DatePicker from "./DatePicker/DatePicker";
import { UserContext } from "../../../context/userContext";
import BubbleElement from "./BubbleElement";
import { WelcomeMessageContext } from "../../../context/welcomeMessageContext";
import DotMessage from "./DotMessage/DotMessage";
import { contentWithPF, contentWithRL, contentWithSS, contentWithTC } from "../../../helpers/contentWarning";

/**
 *
 * @param {object} props
 * @param {string[]} props.text
 * @param {string} props.name
 * @param {string} props.type
 * @param {string[]} props.options
 * @param {string} props.intent
 * @param {string} props.inputType Currently unused
 * @param {boolean} props.showAsButtons
 * @param {boolean} props.showACheckbox
 * @param {string[]} props.firstBubbleCheckbox
 * @param {object[]} props.messagesObjectFinal
 * @param {boolean} props.conInicio_
 * @param {number} props.indexMessagesArray
 * @param {string} props.id
 * @returns {React.ReactNode}
 */
const Message = ({
  text,
  name,
  type,
  options,
  intent,
  inputType,
  showAsButtons,
  showACheckbox,
  firstBubbleCheckbox,
  messagesObjectFinal,
  conInicio_,
  indexMessagesArray,
  id,
}) => {
  if (typeof text === "string") text = [text];
  const [showMore, setShowMore] = useState(false);
  const { formData, setOptionsCheckbox } = useContext(UserContext);
  const { showWelcomeMessage } = useContext(WelcomeMessageContext);
  // const { showWelcomeMessage } = useContext(WelcomeMessageContext);

  const checkWarning = {
    checkTC: ["timecapture", "time capture", "tc"],
    checkSS: ["snapshot"],
    checkPF: ["pwc professional framework", "professional framework"],
    checkRL: ["relationship leader", "relationshipleader"]
  }
    
  const messagesOrientation = {
    orientation: name === "Digi" ? "orientation" : "orientation--right",
    container:
      name === "Digi" ? "message-container" : "message-container--right",
    bubble:
      name === "Digi"
        ? type === "options"
          ? "message-info--options"
          : "message-info"
        : "message-info--right",
    dot: name === "Digi" ? "message-dot" : "message-dot--right",
    text: name === "Digi" ? "text" : "text--right",
  };

  return (
    <>
      {/* Common message */}
      {!conInicio_ &&
        text[0].length > 0 &&
        text.map((text, index) => {
          return (
            <div
              className={messagesOrientation.orientation}
              key={index}
              id={id}
              hidden={id === "smallWelcome" && !showWelcomeMessage.show}
            >
              <div className={messagesOrientation.container}>
                <DotMessage type={messagesOrientation.dot} />
                <div className={messagesOrientation.bubble}>
                  <>
                    {/* If it contains an ulr/mail, it will format the message... */}
                    {textToURL(text) ? (
                      <>
                        <FormatMessage
                          text={
                            showMore ||
                            text
                              .split(" ")
                              .filter((e) => !e.includes("http"))
                              .join(" ").length < 250
                              ? text
                              : `${text
                                  .split(" ")
                                  .filter((e) => !e.includes("http"))
                                  .join(" ")
                                  .substring(0, 250)}...`
                          }
                        />
                        {/*The content will not have a hyperlink while the content is in "show less"*/}
                      </>
                    ) : (
                      <>
                        {/* ...otherwise, it will just display the original text */}
                        <p className={messagesOrientation.text}>
                          {showMore || text.length < 250
                            ? text
                            : `${text.substring(0, 250)}...`}
                        </p>
                      </>
                    )}
                    {/* Show more/less when the text is too long */}
                    {text
                      .split(" ")
                      .filter((e) => !e.includes("http"))
                      .join(" ").length > 250 &&
                    type !== "Form-Submit" &&
                    name === "Digi" ? (
                      <button
                        className="message-showBtn"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Mostrar menos" : "Mostrar más"}
                      </button>
                    ) : null}
                    {!conInicio_ &&
                      type?.split("-")[1] === "Fecha de implementación" && (
                        <DatePicker />
                      )}
                  </>
                </div>
              </div>
            </div>
          );
        })}
      {/* For a datepicker message */}
      {!conInicio_ && type?.split("-")[1] === "Fecha de implementación" && (
        <div className={messagesOrientation.orientation}>
          <div className={messagesOrientation.container}>
            <DotMessage type={messagesOrientation.dot} />
            <div className={messagesOrientation.bubble}>
              <DatePicker />
            </div>
          </div>
        </div>
      )}
      {/* For a checkbox message */}
      {!conInicio_ && showACheckbox && (
        <>
          <div className={messagesOrientation.orientation}>
            <div className={messagesOrientation.container}>
              <DotMessage type={messagesOrientation.dot} />
              <div className={messagesOrientation.bubble}>
                {firstBubbleCheckbox.map((optionInArray, index) => {
                  return (
                    <div className="function-column" key={index}>
                      <label
                        htmlFor={optionInArray}
                        className="function-checkbox"
                      >
                        <input
                          type="checkbox"
                          key={optionInArray}
                          name={optionInArray}
                          id={optionInArray}
                          className="checkbox__input"
                          onChange={() =>
                            setOptionsCheckbox((arr) =>
                              arr.indexOf(optionInArray) === (-1 || undefined)
                                ? [...arr, optionInArray]
                                : arr.filter(
                                    (element) => element !== optionInArray
                                  )
                            )
                          }
                        />
                        <svg className="checkbox__check" width="24" height="24">
                          <polyline points="15 3 8 15 4 12"></polyline>
                        </svg>
                        <span> {optionInArray}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
      {/* Buttons & End of conversation messages */}
      {(!conInicio_ && showAsButtons) ||
      (!conInicio_ &&
        type &&
        //To avoid problems of and extra message dot, we need to recognize which type of intent does not need this.
        !["End Session"].includes(type) &&
        ![
          "Small talk",
          "Provides.Confirm",
          undefined,
          "HelpForm",
          "cualquieraIntent",
          "FAQs",
        ].includes(intent?.split("-")[0].trim())) ? (
        <div className={messagesOrientation.orientation}>
          <div className={messagesOrientation.container}>
            <DotMessage type={messagesOrientation.dot} />
            {(options.length > 2 || showAsButtons) && (
              <OptionsMessage
                options={options}
                containerDirection={"horizontal"}
              />
            )}

            {type.split(" - ")[1] === "Get feedback" && (
              <OptionsMessage
                options={["Reportar", "Más adelante"]}
                containerDirection={"horizontal"}
              />
            )}
          </div>
        </div>
      ) : null}
      {/* Form messages */}
      {conInicio_ &&
        messagesObjectFinal.map((element, indexObject) => {
          return (
            <div className={messagesOrientation.orientation} key={indexObject}>
              <div className={messagesOrientation.container}>
                <BubbleElement
                  messageObject={element}
                  name={name}
                  type={type}
                  indexObject={indexObject}
                  length={messagesObjectFinal.length}
                  formData={formData}
                  indexMessagesArray={indexMessagesArray}
                />
              </div>
            </div>
          );
        })}
      {name !== "Digi" && (
        (checkWarning.checkTC.some((/** @type {string} */ v_tc) => 
            text[0]?.toLowerCase()?.includes(v_tc)) && contentWithTC("User")) ||
        (checkWarning.checkSS.some((/** @type {string} */ v_ss) =>
            text[0]?.toLowerCase()?.includes(v_ss)) && contentWithSS("User")) ||
        (checkWarning.checkPF.some((/** @type {string} */ v_pf) =>
            text[0]?.toLowerCase()?.includes(v_pf)) && contentWithPF("User")) || 
        (checkWarning.checkRL.some((/** @type {string} */ v_rl) =>
            text[0]?.toLowerCase()?.includes(v_rl)) && contentWithRL("User"))
      )}
    </>
  );
};

export default Message;
