const scorePoints = [
  {
    title: "sensei",
    scorePoints: 3000,
    color: "#111111",
  },
  {
    title: "gurú",
    scorePoints: 1500,
    color: "#D93954",
    dots: ["#D93954", "#962C3E", "#541E27", "#111111"],
  },
  {
    title: "maestro",
    scorePoints: 500,
    color: "#D04A02",
    dots: ["#D04A02", "#D3441D", "#D63F39", "#D93954"],
  },
  {
    title: "aprendiz",
    scorePoints: 100,
    color: "#EB8C00",
    dots: ["#EB8C00", "#E27601", "#D96001", "#D04A02"],
  },
  {
    title: "novato",
    scorePoints: 0,
    color: "#FFB600",
    dots: ["#FFB600", "#F8A800", "#F29A00", "#EB8C00"],
  },
  {
    title: "unavailable",
    scorePoints: -1,
    color: "transparent",
  },
];

export default scorePoints;
