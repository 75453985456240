import { useState } from "react";
import Loader from "../../../loader/Loader";

const ImageBubble = ({ length, messageObject }) => {
  const [loading, setLoading] = useState(true);
  const imageLoaded = () => {
    setLoading(false);
  };
  return (
    <>
      {messageObject.content.map((url) => {
        return (
          <>
            <div
              style={{
                display: loading ? "none" : "block",
                border: "solid 3px orange",
              }}
            >
              <img src={url} alt="Trees" height="200" onLoad={imageLoaded} />
            </div>
            <div style={{ display: loading ? "block" : "none" }}>
              <Loader />
            </div>
          </>
        );
      })}
    </>
  );
};
export default ImageBubble;
