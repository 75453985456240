import "./input.scss";

/**
 *
 * @param {object} props
 * @param {string} props.type TODO: Refine typedef on type constants
 * @param {string | number | readonly string[]} [props.value]
 * @param {string} props.name
 * @param {string} [props.topText]
 * @param {string} [props.max]
 * @param {string} [props.min]
 * @param {boolean} props.isRequired
 * @param {(value: string | number | readonly string[]) => void} props.handleInput
 * @param {() => void} props.handleClick
 * @param {boolean} [props.showError]
 * @param {string} [props.errorMessage]
 * @param {boolean} [props.disabled]
 * @returns {React.ReactNode}
 */
const Input = ({
  type,
  name,
  handleInput,
  handleClick,
  isRequired,
  value,
  topText,
  showError,
  errorMessage,
  max,
  min,
  disabled,
  placeholder,
}) => {
  return (
    <div className="custom-input-container">
      <label
        htmlFor=""
        className={`custom-input-container__label ${
          disabled ? "disabled" : ""
        }`}
      >
        {name} {isRequired && <span className="required">&nbsp;*</span>}
      </label>
      <p className="custom-input-container__top-text">{topText}</p>
      <input
        type={type}
        className={`custom-input-container__box${showError ? "--error" : ""}`}
        name={name}
        placeholder={placeholder}
        value={value}
        required={isRequired}
        onChange={(e) => handleInput(e.target.value)}
        max={max}
        min={min}
        onClick={() => handleClick()}
        disabled={disabled}
      />
      <label htmlFor="" className="custom-input-container__label-error">
        &nbsp;
        {showError && errorMessage ? errorMessage : ""}
      </label>
    </div>
  );
};

export default Input;
