import { ErrorIconSVG, SuccessIconSVG } from "../../styles/assets/icons";
import { useContext } from "react";
import { PopupsContext } from "../../context/popupsContext";
import "./notification.scss";

/**
 * When sending info, like customization changes or a bug report, it will give a notification that can be green successful or red error
 *
 * @returns {React.ReactNode}
 */
const Notification = () => {
  const { showConfirmationPopup, setShowConfirmationPopup } =
    useContext(PopupsContext);
  const styleNotification = {
    style:
      showConfirmationPopup.show === "success"
        ? "notification__elementsContainer --success"
        : "notification__elementsContainer --error",
    image:
      showConfirmationPopup.show === "success" ? (
        <SuccessIconSVG />
      ) : (
        <ErrorIconSVG />
      ),
  };

  function handleClick() {
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
      message: null,
    }));
  }

  return (
    <div className="notification" onClick={handleClick}>
      <div className={styleNotification.style}>
        <div>{styleNotification.image}</div>
        {showConfirmationPopup.show === "success" ? (
          <div>
            {showConfirmationPopup.message ? (
              showConfirmationPopup.message
            ) : (
              <p>
                Tu reporte se envío<span>correctamente</span> ¡Gracias!
              </p>
            )}
          </div>
        ) : (
          <div>
            <p>
              No fue posible completar tu solicitud. Por favor intentalo
              nuevamente.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
