export default function calculatePercentageDonut(points) {
  let percentage;
  let color;
  let rango;

  switch (true) {
    case points <= 99:
      percentage = (points / 100) * 100;
      color = "#eb8c00";
      rango = "Principiante";
      break;
    case points >= 100 && points <= 499:
      percentage = ((points - 100) / 399) * 100;
      color = "#D04A02";
      rango = "Básico";
      break;
    case points >= 500 && points <= 1499:
      percentage = ((points - 500) / 999) * 100;
      color = "#DB536A";
      rango = "Intermedio";
      break;
    case points >= 1500 && points <= 2999:
      percentage = ((points - 1500) / 1499) * 100;
      color = "#E0301E";
      rango = "Avanzado";
      break;
    case points >= 3000:
      percentage = ((points - 3000) / 5999) * 100;
      color = "#212121";
      rango = "Experto";
      break;
    default:
      break;
  }
  const donutObject = {
    points: points,
    percentage: percentage,
    color: color,
    rango: rango,
  };
  return donutObject;
}
