// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost-container {
  position: relative;
  height: 100%;
}

.scrollhost {
  overflow: auto;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
  border-radius: 4px;
}

.scroll-bar {
  width: 11px;
  height: 100%;
  right: 0;
  top: 0px;
  position: absolute;
  border-radius: 7px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
}

.scroll-thumb {
  width: 9px;
  height: 20px;
  margin-left: 1px;
  position: absolute;
  border-radius: 7px;
  opacity: 1;
  top: 0;
  background-color: rgba(0, 0, 0, 0.55);
}`, "",{"version":3,"sources":["webpack://./src/commons/custom-scroll/onHoverStyle.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;AAEF;;AAAA;EACE,cAAA;EACA,YAAA;EACA,qBAAA;EACA,wBAAA;EACA,kBAAA;EACA,kBAAA;AAGF;;AAAA;EACE,WAAA;EACA,YAAA;EACA,QAAA;EACA,QAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,qCAAA;AAGF;;AAAA;EACE,UAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;EACA,MAAA;EACA,qCAAA;AAGF","sourcesContent":[".scrollhost::-webkit-scrollbar {\n  display: none;\n}\n.scrollhost-container {\n  position: relative;\n  height: 100%;\n}\n.scrollhost {\n  overflow: auto;\n  height: 100%;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  position: relative;\n  border-radius: 4px;\n}\n\n.scroll-bar {\n  width: 11px;\n  height: 100%;\n  right: 0;\n  top: 0px;\n  position: absolute;\n  border-radius: 7px;\n  bottom: 0px;\n  background-color: rgba(0, 0, 0, 0.35);\n}\n\n.scroll-thumb {\n  width: 9px;\n  height: 20px;\n  margin-left: 1px;\n  position: absolute;\n  border-radius: 7px;\n  opacity: 1;\n  top: 0;\n  background-color: rgba(0, 0, 0, 0.55);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
