import axios from "axios";
const loginURL = `${process.env.REACT_APP_BACK_API}login/`;

export const getLogin = async (authCode) => {
  // Axios get request to get the customization of the user.
  try {
    const loginResponse = await axios({
      method: "GET",
      url: loginURL,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        authCode,
      },
    });
    return loginResponse;
  } catch (err) {
    return err;
  }
};

export const deleteToken = async (token) => {
  try {
    await axios({
      method: "DELETE",
      url: loginURL,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        token,
      },
    });
  } catch (err) {
    return err;
  }
};
