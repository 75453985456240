import { useContext } from "react";
import NpsConfirmationPopup from "./nps-confirmation-popup/NpsConfirmationPopup";
import { PopupsContext } from "../../context/popupsContext";

/**
 * NPS confirmation if it's completed
 *
 * @returns {React.ReactNode}
 */
const MainAppPopup = () => {
  const { showConfirmationPopup } = useContext(PopupsContext);

  return (
    <>
      {showConfirmationPopup.show === "npsConfirmationPopup" ? (
        <>{<NpsConfirmationPopup />}</>
      ) : null}
    </>
  );
};

export default MainAppPopup;
