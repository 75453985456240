import { useState, useEffect, useRef } from "react";
import "./selectDropdown.scss";
import { ArrowSVG } from "../../../styles/assets/icons";
import OnHoverScrollContainer from "../../../commons/custom-scroll/CustomScrollDiv";

const SelectDropdown = (props) => {
  const { list, title, setValues } = props; //setValues |  prop to change the values submitted
  const [value, setValue] = useState({
    isListOpen: false,
    headerTitle: title,
    valueSelected: "",
  });
  const containerMain = document.getElementById("layout-children_container");
  const toggleList = () => {
    setValue((prevState) => ({
      ...prevState,
      isListOpen: !prevState.isListOpen,
    }));
  };
  const dropdownRef = useRef();

  const onChange = (item) => {
    const { title, key } = item;

    setValue(
      {
        headerTitle: title,
        isListOpen: false,
        valueSelected: title,
      },
      setValues((values) => ({
        ...values,
        category: key,
        category_item: title,
      }))
    ); //to change the values to submit -- should add a new element in the object in the future
  };

  const closeDropdown = () => {
    setValue((prev) => ({
      ...prev,
      isListOpen: false,
    }));
  };

  const doNothingSoNoWarning = () => {
    //this is to the (no display)input that exist only to make this component required to the form
  };

  useEffect(() => {
    if (value.isListOpen) {
      containerMain.onclick = (event) => {
        if (
          event.target.contains(dropdownRef.current) &&
          event.target !== dropdownRef.current
        ) {
          closeDropdown();
        }
      };
    }
  });

  useEffect(() => {
    if (sessionStorage.getItem("reportCategory")) {
      //Needs timeout, or else BugsView will overwrite it.
      setTimeout(() => {
        onChange(list[9]);
      }, 200);
      sessionStorage.removeItem("reportCategory");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="button-dropdown_outer-container">
      <input
        className="input-required"
        type="text"
        tabIndex={-1}
        autoComplete="off"
        required={true}
        onChange={() => doNothingSoNoWarning()}
        value={value.valueSelected}
        // disabled={disabled}
      />
      <button
        type="button"
        className={
          value.isListOpen
            ? "button-dropdown button-dropdown--open"
            : value.valueSelected
            ? "button-dropdown button-dropdown--header button-dropdown--title-selected"
            : "button-dropdown button-dropdown--header"
        }
        onClick={toggleList}
      >
        <div className="button-dropdown__header-title">{value.headerTitle}</div>
        <div className="button-dropdown__container-svg">
          {value.isListOpen ? (
            <ArrowSVG direction="up" color="#828282" strokeWidth="3" />
          ) : (
            <ArrowSVG direction="down" color="#828282" strokeWidth="3" />
          )}
        </div>
      </button>

      {value.isListOpen && (
        <div className="button-dropdown__list" ref={dropdownRef}>
          <OnHoverScrollContainer className={"button-dropdown__container-list"}>
            <div role="list" className="button-dropdown__container-list">
              {list.map((item, index) => (
                <div key={index}>
                  {item.key === "categoria" ? (
                    <div className="button-dropdown__category">
                      {item.title}
                    </div>
                  ) : (
                    <button
                      type="button"
                      className={
                        item.title === value.headerTitle
                          ? "button-dropdown button-dropdown--selected"
                          : "button-dropdown button-dropdown--options"
                      }
                      key={item.id}
                      onClick={() => onChange(item)}
                    >
                      {item.title}{" "}
                    </button>
                  )}
                </div>
              ))}
            </div>
          </OnHoverScrollContainer>
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
