import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cryptoRandomString from "crypto-random-string";

import { getAccessCodes } from "../services/auth/authService";
import { getLogin } from "../services/login/loginService";

//To avoid strict mode problem
let firstTryLogin = true;

// Authorization and authentication verification of credentials in this file
export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [apiToken, setApiToken] = useState("");
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState([]);
  const [optionsCheckbox, setOptionsCheckbox] = useState([]);
  const [currentlyInForm, setCurrentlyInForm] = useState(false);
  const [disableBubblesButtons, setDisableBubblesButtons] = useState([]);
  const [currentTopic, setCurrentTopic] = useState("");
  const [didLogin, setDidLogin] = useState(false);
  const appState = useRef(null);
  //theme state
  const [theme, setTheme] = useState({
    theme: localStorage.getItem("theme") || "light",
    isTouched: false,
  });

  //REACT ROUTER HOOKS
  // const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    appState.current = cryptoRandomString({ length: 16 });
    getAccessCodes(appState.current);
  }, []);

  useEffect(() => {
    if (firstTryLogin) {
      firstTryLogin = false;
      if (window.location.href.includes("state")) {
        let authorizeResponse = new URLSearchParams(window.location.search);
        let stateFromLocation = authorizeResponse.get("state");

        if (stateFromLocation === sessionStorage.getItem("state")) {
          let code = authorizeResponse.get("code");
          getLogin(code).then((login) => {
            if (login.data?.status === undefined) {
              setUser(login.data.userInfo);
              setApiToken(login.data.apiToken);
              setDidLogin(true);
            } else {
              console.error(login.data.error);
              throw Error(login.data.message);
            }
          });
        } else {
          console.error("Invalid Auth: No state in response");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!didLogin && Object.keys(user).length !== 0) {
      getLogin("refresh").then((login) => {
        setApiToken(login.data.apiToken);
        setDidLogin(true);
      });
    }
  }, [didLogin, user]);

  useEffect(() => {
    if (didLogin) {
      let uri = localStorage.getItem("uri");
      setIsAuthenticated(true);
      setTimeout(() => {
        setIsLoading(false);
        if (uri?.length > 1) navigate(uri);
        else navigate("/chat");
      }, 5000);

      localStorage.removeItem("uri");

      return () => {
        setIsLoading(false);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didLogin]);

  const clearStoredUser = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUser({});
  };

  useEffect(() => {
    if (sessionStorage.getItem("userData")) {
      setIsLoading(false);
    }
  }, []);

  return {
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    setIsLoading,
    user,
    setUser,
    apiToken,
    clearStoredUser,
    theme,
    setTheme,
    formData,
    setFormData,
    optionsCheckbox,
    setOptionsCheckbox,
    currentlyInForm,
    setCurrentlyInForm,
    disableBubblesButtons,
    setDisableBubblesButtons,
    currentTopic,
    setCurrentTopic,
  };
};
