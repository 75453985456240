import { useContext, useEffect, useRef, useState } from "react";
import watch from "./assets/watch.png";
import smallWatch from "./assets/smallWatch.png";
import StatusMessage from "./StatusMessage/StatusMessage";
import { StatusEnum } from "./StatusMessage/getStatusMessage";
import ButtonTab from "../../commons/button-tab/ButtonTab";
import HubView from "./SecondaryViews/HubView";
import MyAutomationsView from "./SecondaryViews/MyAutomationsView";
import { BotsContext } from "../../context/botsContext";
import Loader from "../../commons/loader/Loader";
import "./desktopAutomationView.scss";

const DesktopAutomationView = () => {
  const [contentMessage, setContentMessage] = useState({
    showMessage: false,
    status: StatusEnum.INFO,
    automationName: "PYTHON SCRIPT",
    progress: 0,
    progressTask: "",
    customTitle: null,
    customSubtitle: null,
  });
  const [selectedImage, setSelectedImage] = useState(watch);
  const { setIsDownloading, setIsExecuting, setAllBots } =
    useContext(BotsContext);
  const { isLoading, setIsLoading } = useContext(BotsContext);
  const buttonTabRef = useRef();

  const handleTabChange = (index) => {
    buttonTabRef.current.setSelectedTabParent(index);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1022) setSelectedImage(smallWatch);
      else setSelectedImage(watch);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.ipc.send("sendAzureValues", {
      organization: process.env.REACT_APP_ORGANIZATION,
      projectName: process.env.REACT_APP_PROJECT_NAME,
      token: process.env.REACT_APP_TOKEN,
    });

    window.ipc.send("askForBots");

    const removeAskForBots = window.ipc.receiveBots((event, bots) => {
      setAllBots(bots);
      setIsLoading(false);
    });

    const removeDownloadStatus = window.ipc.downloadStatus((event, data) => {
      setContentMessage({
        showMessage: true,
        status: data.type,
        automationName: data.bot_name,
        progress: data.percentage,
        progressTask: data.message,
        customTitle: data?.title,
        customSubtitle: data?.subtitle,
      });
      if (data.type !== StatusEnum.PROGRESS_CUSTOM) {
        setIsDownloading(false);
        setIsExecuting(false);
      }
      if (
        data.type === StatusEnum.S_CUSTOM ||
        data.type === StatusEnum.S_DOWNLOAD
      ) {
        handleTabChange(1);
      }
    });

    return () => {
      removeAskForBots();
      removeDownloadStatus();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className={`automation-container${isLoading ? "--blur" : ""}`}>
        {contentMessage.showMessage && (
          <StatusMessage
            status={contentMessage.status}
            automationName={contentMessage.automationName}
            percentage={contentMessage.progress}
            progressTask={contentMessage.progressTask}
            closeMessage={() =>
              setContentMessage((prevState) => ({
                ...prevState,
                showMessage: false,
              }))
            }
            customTitle={contentMessage?.customTitle}
            customSubtitle={contentMessage?.customSubtitle}
          />
        )}
        <div className="automation-container__illustration">
          <div className="automation-container__illustration__texts">
            <div className="automation-container__illustration__texts__title">
              Digi te acompaña en tu día a día para que puedas{" "}
              <p className="automation-container__illustration__texts__subtitle">
                ahorrar tiempo y agilizar tus tareas
              </p>
            </div>
          </div>
          <img
            className="automation-container__illustration__image"
            src={selectedImage}
            alt="Flat watch"
          />
        </div>
        <ButtonTab
          items={[
            {
              name: "Conocé nuevas automatizaciones",
              view: <HubView />,
            },
            {
              name: "Mis descargas",
              view: <MyAutomationsView />,
            },
          ]}
          ref={buttonTabRef}
        />
      </div>
    </>
  );
};

export default DesktopAutomationView;
