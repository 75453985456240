import { motion } from "framer-motion";

const LogOutDigiSVG = () => {
  return (
    <motion.div
      className="box"
      animate={{
        rotate: [0, 30, 30, 30, 30, 30, 30, 30, 30, 0],
        y: [0, -2.5, -5, -7.5, -10, -10, -7.5, -5, -2.5, 0],
      }}
      transition={{
        duration: 2,
        ease: "easeInOut",
        times: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        repeat: Infinity,
        repeatDelay: 1,
      }}
    >
      <svg
        class="log-out-digi"
        width="334"
        height="282"
        viewBox="0 0 334 282"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="334"
          height="281.188"
          rx="140.594"
          fill="url(#pattern0_14493_49046)"
          fill-opacity="0.4"
        />
        <motion.path
          className="box"
          style={{ originY: 0, originX: 1 }}
          animate={{
            rotate: [0, 0, -40, 40, -40, 40, -40, 40, 0, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
            repeat: Infinity,
            repeatDelay: 1,
          }}
          d="M112.825 182.815C112.826 182.815 112.827 182.816 112.826 182.817C101.994 185.773 53.4679 194.889 50.9848 185.841C48.5155 176.806 94.9472 159.949 105.752 156.98C116.572 154.025 126.92 157.408 128.861 164.545C130.816 171.68 123.64 179.857 112.825 182.813C112.823 182.813 112.824 182.815 112.825 182.815Z"
          fill="#7D7D7D"
        ></motion.path>
        <path
          d="M252.605 131.745C252.602 131.745 252.599 131.748 252.6 131.752C263.243 171.473 238.551 212.591 197.46 223.609C156.352 234.628 114.402 211.348 103.768 171.625C93.1203 131.902 117.813 90.78 158.921 79.7609C200.012 68.7424 241.96 92.0191 252.611 131.738C252.612 131.742 252.609 131.745 252.605 131.745Z"
          fill="#7D7D7D"
        />
        <path
          d="M205.903 138.66C205.905 138.658 205.904 138.654 205.9 138.655C205.504 138.71 205.079 138.71 204.64 138.667C196.718 137.711 189.639 133.729 184.714 127.448C179.79 121.168 177.606 113.346 178.562 105.424L182.302 74.5936C183.258 66.6718 187.255 59.5922 193.535 54.6678C199.816 49.7435 207.652 47.5596 215.588 48.5159C218.214 48.83 220.084 51.2279 219.77 53.8542C219.442 56.4806 217.058 58.3504 214.432 58.0364C209.05 57.3798 203.741 58.8642 199.459 62.2185C195.191 65.5585 192.479 70.3687 191.822 75.7498L188.083 106.581C187.44 111.962 188.911 117.271 192.265 121.539C195.605 125.807 200.415 128.505 205.796 129.161C208.422 129.475 210.307 131.873 209.978 134.5C209.707 136.694 208.014 138.362 205.906 138.666C205.903 138.666 205.901 138.663 205.903 138.66Z"
          fill="#7D7D7D"
        />
        <path
          d="M220.826 129.275C223.467 147.716 202.313 165.986 173.567 170.111C144.834 174.222 119.399 162.618 116.758 144.191C114.117 125.764 135.285 107.479 164.018 103.354C192.75 99.2292 218.186 110.848 220.826 129.275Z"
          fill="#2D2D2D"
        />
        <path
          d="M134.286 141.122C134.485 142.577 133.472 143.933 132.016 144.148C130.56 144.362 129.204 143.348 128.99 141.878C128.776 140.422 129.804 139.066 131.26 138.852C132.73 138.638 134.071 139.666 134.286 141.122Z"
          fill="#7D7D7D"
        />
        <path
          d="M151.471 139.424C151.685 140.88 150.671 142.236 149.201 142.45C147.731 142.665 146.389 141.651 146.175 140.181C145.975 138.725 146.989 137.369 148.445 137.155C149.915 136.941 151.271 137.954 151.471 139.424Z"
          fill="#7D7D7D"
        />
        <path
          d="M145.69 134.186C145.889 135.642 144.876 136.998 143.42 137.212C141.964 137.426 140.608 136.398 140.394 134.942C140.18 133.472 141.194 132.13 142.664 131.916C144.134 131.702 145.476 132.716 145.69 134.186Z"
          fill="#7D7D7D"
        />
        <path
          d="M138.939 134.813C139.138 136.269 138.125 137.625 136.669 137.839C135.199 138.053 133.857 137.026 133.643 135.57C133.429 134.114 134.457 132.758 135.913 132.544C137.383 132.33 138.724 133.343 138.939 134.813Z"
          fill="#7D7D7D"
        />
        <path
          d="M185.984 133.715C186.198 135.171 185.17 136.527 183.714 136.741C182.244 136.955 180.902 135.927 180.688 134.471C180.474 133.015 181.502 131.659 182.958 131.445C184.428 131.231 185.77 132.245 185.984 133.715Z"
          fill="#7D7D7D"
        />
        <path
          d="M203.184 132.015C203.398 133.471 202.384 134.827 200.914 135.041C199.458 135.255 198.102 134.242 197.888 132.771C197.674 131.316 198.687 129.96 200.158 129.746C201.628 129.531 202.969 130.559 203.184 132.015Z"
          fill="#7D7D7D"
        />
        <path
          d="M197.388 126.777C197.588 128.247 196.575 129.589 195.119 129.803C193.649 130.017 192.293 129.004 192.093 127.534C191.893 126.078 192.906 124.722 194.362 124.508C195.833 124.294 197.189 125.307 197.388 126.777Z"
          fill="#7D7D7D"
        />
        <path
          d="M190.637 127.406C190.851 128.862 189.823 130.218 188.367 130.432C186.897 130.646 185.555 129.633 185.341 128.162C185.141 126.707 186.155 125.351 187.611 125.136C189.081 124.922 190.422 125.936 190.637 127.406Z"
          fill="#7D7D7D"
        />
        <path
          d="M260.634 166.258C266.172 175.993 286.997 220.726 278.847 225.365C270.683 230.004 242.864 189.238 237.325 179.504C231.787 169.769 232.515 158.922 238.953 155.268C245.39 151.613 255.096 156.538 260.634 166.258Z"
          fill="#7D7D7D"
        />
      </svg>
    </motion.div>
  );
};

export { LogOutDigiSVG };
