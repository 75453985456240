import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import "./buttonTab.scss";

/**
 * A tab list of buttons that map to their views
 *
 * @param {object} props
 * @param {{ name: string, view: React.ReactNode }[]} props.items The tabs and their views
 * @returns {React.ReactNode}
 */

const ButtonTab = React.forwardRef(({ items }, ref) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedView, setSelectedView] = useState(
    items[selectedTab].view ? items[selectedTab].view : null
  );
  const [showOverflowing, setShowOverflowing] = useState(false);
  const containerRef = useRef();

  useImperativeHandle(ref, () => ({
    setSelectedTabParent: (index) => {
      setSelectedTab(index);
    },
  }));

  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100;
    }
  };

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100;
    }
  };

  const updateShowOverflowing = () => {
    if (containerRef.current) {
      const showOverflow =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setShowOverflowing(showOverflow);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      updateShowOverflowing();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setSelectedView(items[selectedTab].view ? items[selectedTab].view : null);
  }, [selectedTab, items]);

  return (
    <div className="button-tab-container">
      <div className="button-tab-container__all-tabs">
        <button
          className={`button-tab-container__all-tabs__arrow${
            showOverflowing ? " overflowingArrow" : ""
          }`}
          onClick={handleScrollLeft}
        >
          &#8249;
        </button>
        <div
          className={`button-tab-container__all-tabs__item-tabs${
            showOverflowing ? " overflowingTab" : ""
          }`}
          ref={containerRef}
        >
          {items.map((el, index) => (
            <button
              key={index}
              className={`button-tab-container__all-tabs__item-tabs__button-tab${
                index === selectedTab ? " tabSelected" : ""
              }`}
              onClick={() => {
                setSelectedTab(index);
                setSelectedView(items[index].view ? items[index].view : null);
              }}
            >
              {el?.name}
            </button>
          ))}
        </div>
        <button
          className={`button-tab-container__all-tabs__arrow${
            showOverflowing ? " overflowingArrow" : ""
          }`}
          onClick={handleScrollRight}
        >
          &#8250;
        </button>
      </div>
      <div className="button-tab-container__view">
        {React.cloneElement(selectedView, { setSelectedTab })}
      </div>
    </div>
  );
});

export default ButtonTab;
