import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { createNewSession } from "../services/session/sessionService";
import { UserContext } from "../context/userContext";
import { getLastDateNPS } from "../services/nps/npsService";
import { getGlobalScreenAVG } from "../services/appInsight/appInsightService";
import { getUserScreenAVG } from "../services/appInsight/appInsightService";

export const useSession = () => {
  const [sessionValue, setSessionValue] = useState("");
  const [npsMetrics, setNpsMetrics] = useState({
    averageScreenGlobal: 0,
    averageScreenUser: 0,
  });

  const [countInactivity, setCountInactivity] = useState({
    firstSessionPassed: false,
    count: 0,
  });

  const [showPopupNps, setShowPopupNps] = useState({
    monthlyPeriodExpired: false,
    alreadyShowedNps: false,
    endSession: false,
    aboveAverageScreenTime: false,
  });

  const { user, apiToken } = useContext(UserContext);
  const [activeSession, setActiveSession] = useState({
    navigated: false,
    chatted: false,
    isActive: null,
    tenSecs: false,
  });

  const timeout = 300000;
  //const timeoutNPS = 300000;
  const location = useLocation();

  useEffect(() => {
    setActiveSession({ ...activeSession, navigated: true });
  }, [location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let userMail = user.cloudEmail || user.email;
        const lastSeenDateResponse = await getLastDateNPS(userMail, apiToken);
        let lastSeenDate = lastSeenDateResponse.data.date;
        lastSeenDate = new Date(lastSeenDate).getTime();
        const currentDate = new Date().getTime();
        const thirtySeconds = 30 * 24 * 60 * 60 * 1000; //30 dias en milisegundos
        if (!lastSeenDate || currentDate - lastSeenDate > thirtySeconds) {
          setShowPopupNps((prev) => ({
            ...prev,
            monthlyPeriodExpired: true,
          }));
        }
      } catch (error) {}
    };
    if (user?.email !== undefined && sessionValue) fetchData();
  }, [user, sessionValue]);

  useEffect(() => {
    if (
      !activeSession.isActive &&
      (activeSession.navigated || activeSession.chatted)
    ) {
      setActiveSession({
        ...activeSession,
        isActive: true,
        navigated: false,
        chatted: false,
      });
      //we create a new session that will NOT be used (it just to store in the db the time the user is in the app)
      async function functionNewSession() {
        await createNewSession(user.cloudEmail || user.email, apiToken);
      }

      functionNewSession();

      setTimeout(() => {
        setActiveSession({
          ...activeSession,
          isActive: false,
          navigated: false,
          chatted: false,
        });
      }, timeout);
    }
  }, [activeSession]);

  // We obtain the averages of time on screen and how many times "anything else" is reached, both for users and globally.
  useEffect(() => {
    const fetchAverageScreenGlobal = async () => {
      try {
        await getGlobalScreenAVG().then((value) => {
          setNpsMetrics((prev) => ({
            ...prev,
            averageScreenGlobal: +value?.data?.tables[0]?.rows[0][0] >>> 0,
          }));
        });
      } catch (err) {
        return err;
      }
    };

    const fetchAverageScreenUser = async () => {
      try {
        await getUserScreenAVG(user?.uid).then((value) => {
          if (value?.data?.tables[0]?.rows[0] !== undefined) {
            setNpsMetrics((prev) => ({
              ...prev,
              averageScreenUser:
                +JSON.stringify(value?.data?.tables[0]?.rows[0][1]) >>> 0,
            }));
          }
        });
      } catch (err) {
        return err;
      }
    };

    if (user?.uid !== undefined) {
      fetchAverageScreenGlobal();
      fetchAverageScreenUser();
    }
  }, [user]);

  useEffect(() => {
    const checkMetrics = () => {
      setShowPopupNps((prev) => ({
        ...prev,
        aboveAverageScreenTime:
          npsMetrics.averageScreenUser >= npsMetrics.averageScreenGlobal
            ? true
            : false,
      }));
    };
    checkMetrics();
  }, [npsMetrics]);

  useEffect(() => {
    if (!activeSession.isActive) {
      setActiveSession({ ...activeSession, isActive: true });
      setTimeout(() => {
        setActiveSession({
          ...activeSession,
          isActive: false,
          navigated: false,
          chatted: false,
        });
        console.log("----------------SE TERMINO LA SESION-----------------");
        setCountInactivity((prev) => ({
          ...prev,
          firstSessionPassed: true,
        }));
        setShowPopupNps((prev) => ({
          ...prev,
          endSession: true,
        }));
      }, timeout);
    }
  }, [activeSession, npsMetrics]);

  return {
    sessionValue,
    setSessionValue,
    activeSession,
    setActiveSession,
    countInactivity,
    setCountInactivity,
    showPopupNps,
    setShowPopupNps,
    npsMetrics,
    setNpsMetrics,
  };
};
