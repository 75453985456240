export function checkValueForm(data) {
  if (data?.response?.intent_match === "Index - Forms") {
    return "cleanPreviousValues";
  }

  if (data?.response?.isForm) {
    return true;
  }
  return false;
}
