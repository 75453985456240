/**
 *
 * @param {object} props
 * @param {string} props.rotation
 * @param {boolean} props.mobile Currently unused
 * @returns {React.ReactNode}
 */
const WelcomeDigi = ({ rotation, mobile }) => {
  return (
    <svg
      rotate={rotation}
      width="50%"
      viewBox="0 0 370 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="welcome-digi-avatar"
    >
      <path
        d="M298.491 149.85C310.361 140.689 359.247 96.1858 351.659 86.1627C344.069 76.1559 288.675 112.089 276.806 121.239C264.933 130.397 260.164 144.228 266.158 152.121C272.146 160.023 286.618 159.007 298.491 149.85Z"
        fill="#7D7D7D"
      />
      <path
        d="M83.0491 129.006C79.0575 184.159 121.682 232.247 178.26 236.413C234.837 240.578 283.945 199.245 287.937 144.092C291.928 88.9389 249.296 40.8542 192.718 36.6883C136.141 32.5224 87.0407 73.8527 83.0491 129.006Z"
        fill="#7D7D7D"
      />
      <path
        d="M145.916 126.334C146.463 126.308 147.014 126.209 147.575 126.036C157.713 122.769 165.991 115.716 170.862 106.18C175.744 96.6412 176.648 85.763 173.4 75.5398L160.775 35.7593C157.539 25.5409 150.537 17.2038 141.072 12.283C131.607 7.36614 120.806 6.45803 110.66 9.72479C107.292 10.807 105.447 14.436 106.518 17.8248C107.597 21.2141 111.195 23.0814 114.562 22.003C121.444 19.7833 128.78 20.4013 135.213 23.7414C141.646 27.0815 146.393 32.744 148.597 39.6855L161.222 79.4621C163.418 86.4068 162.813 93.795 159.492 100.275C156.182 106.748 150.562 111.538 143.673 113.754C140.305 114.836 138.452 118.465 139.531 121.854C140.43 124.693 143.089 126.468 145.916 126.334Z"
        fill="#7D7D7D"
      />
      <path
        d="M124.038 117.714C125.203 142.731 157.479 161.529 196.145 159.712C234.803 157.892 265.207 136.144 264.041 111.128C262.875 86.1149 230.588 67.3128 191.934 69.1299C153.276 70.9468 122.872 92.7016 124.038 117.714Z"
        fill="#2D2D2D"
      />
      <path
        d="M240.334 111.518C240.433 113.501 242.098 115.031 244.07 114.943C246.034 114.85 247.549 113.169 247.461 111.183C247.373 109.2 245.697 107.665 243.733 107.758C241.761 107.85 240.245 109.539 240.334 111.518Z"
        fill="#7D7D7D"
      />
      <path
        d="M217.396 113.613C217.484 115.595 219.153 117.126 221.125 117.034C223.097 116.942 224.612 115.261 224.524 113.278C224.424 111.295 222.759 109.761 220.795 109.853C218.824 109.945 217.301 111.626 217.396 113.613Z"
        fill="#7D7D7D"
      />
      <path
        d="M223.657 105.241C223.756 107.22 225.421 108.758 227.393 108.666C229.356 108.574 230.88 106.889 230.784 104.906C230.696 102.92 229.027 101.389 227.056 101.481C225.084 101.573 223.569 103.255 223.657 105.241Z"
        fill="#7D7D7D"
      />
      <path
        d="M232.658 104.348C232.758 106.327 234.423 107.862 236.387 107.769C238.358 107.677 239.874 105.992 239.786 104.009C239.697 102.027 238.021 100.496 236.057 100.588C234.086 100.677 232.57 102.362 232.658 104.348Z"
        fill="#7D7D7D"
      />
      <path
        d="M170.779 114.793C170.867 116.776 172.544 118.307 174.507 118.218C176.479 118.126 177.995 116.441 177.907 114.458C177.818 112.476 176.142 110.945 174.178 111.034C172.207 111.126 170.691 112.807 170.779 114.793Z"
        fill="#7D7D7D"
      />
      <path
        d="M147.836 116.884C147.924 118.866 149.593 120.397 151.565 120.305C153.529 120.212 155.052 118.531 154.956 116.545C154.868 114.566 153.199 113.031 151.227 113.124C149.256 113.216 147.74 114.901 147.836 116.884Z"
        fill="#7D7D7D"
      />
      <path
        d="M154.101 108.51C154.201 110.497 155.866 112.027 157.829 111.934C159.801 111.846 161.328 110.162 161.228 108.175C161.129 106.192 159.464 104.657 157.5 104.75C155.529 104.842 154.009 106.527 154.101 108.51Z"
        fill="#7D7D7D"
      />
      <path
        d="M163.103 107.62C163.191 109.602 164.868 111.133 166.832 111.041C168.803 110.952 170.319 109.267 170.231 107.281C170.131 105.298 168.466 103.763 166.502 103.856C164.531 103.948 163.011 105.633 163.103 107.62Z"
        fill="#7D7D7D"
      />
      <path
        d="M105.31 118.396C97.8739 105.339 60.5858 50.6755 49.7392 56.9623C38.8925 63.2601 66.8906 123.304 74.3271 136.361C81.7526 149.422 94.7181 155.981 103.272 151.026C111.832 146.059 112.744 131.455 105.31 118.396Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

export default WelcomeDigi;
