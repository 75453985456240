import "./selectScoreBar.scss";

/**
 * @typedef {object} Nps
 * @property {number | null} score
 * @property {boolean} continue
 * @property {string} comment
 */

/**
 * Scorebar for the nps.
 *
 * @param {object} props
 * @param {(nps: Nps) => void} props.setScoreNps
 * @param {Nps} props.scoreNps
 * @returns {React.ReactNode}
 */
const SelectScoreBar = ({ setScoreNps, scoreNps }) => {
  const npsScores = [
    { scorePoints: 0 },
    { scorePoints: 1 },
    { scorePoints: 2 },
    { scorePoints: 3 },
    { scorePoints: 4 },
    { scorePoints: 5 },
    { scorePoints: 6 },
    { scorePoints: 7 },
    { scorePoints: 8 },
    { scorePoints: 9 },
  ];

  const handleClick = (value) => {
    document.getElementById("value").innerText = value;
    setScoreNps({ ...scoreNps, score: value });
  };

  return (
    <>
      <div className="container-nps-score-bar">
        <div className="nps-score-bar"></div>
        {npsScores.map(({ scorePoints }, i) => {
          return (
            <div
              key={scorePoints}
              className={
                scorePoints + 1 === scoreNps.score
                  ? "progress__step progress__step--selected progress__step--" +
                    scorePoints
                  : "progress__step progress__step--" + scorePoints
              }
              onClick={() => handleClick(scorePoints + 1)}
            ></div>
          );
        })}
      </div>
      <input
        type="range"
        min="1"
        max="10"
        defaultValue="5"
        className="slider"
        id="myRange"
        onChange={(e) => handleClick(e.target.value)}
      />
    </>
  );
};

export default SelectScoreBar;
