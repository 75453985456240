// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.challenges-container {
  display: flex;
  height: 100%;
  min-height: 20rem;
}
.challenges-container__first-column, .challenges-container__second-column {
  padding: 0.5rem;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .challenges-container {
    flex-direction: column;
  }
  .challenges-container__first-column, .challenges-container__second-column {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/commons/my-points/challenges/challenges.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,iBAAA;AACF;AACE;EAEE,eAAA;EACA,UAAA;AAAJ;;AAIA;EACE;IACE,sBAAA;EADF;EAEE;IAEE,WAAA;EADJ;AACF","sourcesContent":[".challenges-container {\n  display: flex;\n  height: 100%;\n  min-height: 20rem;\n\n  &__first-column,\n  &__second-column {\n    padding: 0.5rem;\n    width: 50%;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .challenges-container {\n    flex-direction: column;\n    &__first-column,\n    &__second-column {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
