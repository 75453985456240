import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.scss";
import { useState, useContext } from "react";
import { UserContext } from "../../../../context/userContext";
import { SocketContext } from "../../../../context/socketContext";
//import { objToString } from "@microsoft/applicationinsights-core-js";
const DatePickerContainer = () => {
  const [startDate, setStartDate] = useState(null);
  //  const [endDate, setEndDate] = useState(null);
  const { /* sessionData,  */ emitMessage } = useContext(SocketContext);
  const { user } = useContext(UserContext);
  const { name } = user;
  const onChange = (textDate) => {
    let dateText = new Date(textDate).toISOString().split("T")[0];
    let dateTextDivided = dateText.split("-");
    let day = dateTextDivided[2];
    let month = dateTextDivided[1];
    let year = dateTextDivided[0];
    let text = month + "/" + day + "/" + year;
    setStartDate(text);
    if (text) {
      emitMessage("chat", { name, text });
      setStartDate(null);
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };
  const days = ["Lu", "Ma", "Mi", "Ju", "Vi", "Sa", "Do"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  return (
    <div className="seccion-date">
      <DatePicker
        locale={locale}
        selected={startDate}
        onChange={(date) => onChange(date)}
        //startDate={startDate}
        //endDate={endDate}
        //selectsRange
        selectsDisabledDaysInRange={false}
        inline
        disabledKeyboardNavigation
        filterDate={isWeekday}
        //excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]} // funcionalidad para feriados?
      />
    </div>
    //<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
  );
};
export default DatePickerContainer;
