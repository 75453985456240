// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desktop-app-popUp {
  padding: 2rem 4.375rem 1rem 4.375rem;
  width: 100%;
  height: 100%;
  font-family: "PwC Helvetica Neue";
}`, "",{"version":3,"sources":["webpack://./src/views/Automations-view/automationsView.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,WAAA;EACA,YAAA;EACA,iCAAA;AACF","sourcesContent":[".desktop-app-popUp {\n  padding: 2rem 4.375rem 1rem 4.375rem;\n  width: 100%;\n  height: 100%;\n  font-family: \"PwC Helvetica Neue\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
