import { LogOutDigiSVG } from "../../styles/svg/logOutSVG";
import "./logOutView.scss";

const LogOutView = () => {
  return (
    <>
      <div className="log-out-container">
        <LogOutDigiSVG />
        <h1 className="log-out-title">&iexcl;Hasta la pr&oacute;xima!</h1>
      </div>
    </>
  );
};

export default LogOutView;
