import Loader from "../../commons/loader/Loader";
import "./loadingView.scss";

const LoadView = ({ texto = "¡Estamos procesando tu solicitud!" }) => {
  return (
    <>
      <Loader loadingPage={true} />
      <div className="loading-container">
        <p className="loading-container__text">{texto}</p>
      </div>
    </>
  );
};

export default LoadView;
