import "./iconInfo.scss"

export const IconInfo = () => {

    return(
            <div className="message-icon-info">
                <span>No podrás ejecutar esta automatización desde esta plataforma. Para entender cómo se ejecuta, podés leer el archivo ".doc" dentro de la carpeta Supporting Files.</span>
            </div>
    );
};
