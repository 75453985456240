import "./lockMedalCase.scss";

const LockMedalCase = () => {
  return (
    <div className="empty-box">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-60 0 210 108.24"
        fill="#BEBEBE"
      >
        <path d="M44.9,108.23c-11.93,0-23.87,0-35.8,0-.82,0-1.65-.03-2.47-.15C2.4,107.48,.06,104.82,.02,100.5c-.04-4.6-.01-9.2-.01-13.79,0-14.41,0-28.83,0-43.24q0-2.89,2.81-2.89c4.6,0,9.2-.03,13.79,.02,1.27,.02,1.69-.4,1.71-1.69,.06-5.01-.26-10.02,.22-15.02C19.7,11.7,29.3,1.79,41.53,.22c13.7-1.75,26.39,7.04,29.46,20.43,.67,2.93,.8,5.92,.76,8.93-.04,3,.07,5.99-.04,8.99-.05,1.53,.38,2.08,1.99,2.05,4.8-.1,9.61,0,14.41-.06,1.41-.02,1.9,.44,1.9,1.86-.03,19.32,0,38.64-.03,57.96,0,5.03-2.98,7.81-8.34,7.84-7.03,.05-14.05,.01-21.08,.01-5.22,0-10.43,0-15.65,0Zm.1-7.25c11.98,0,23.96-.02,35.94,.02,1.29,0,1.82-.27,1.81-1.7-.05-16.58-.04-33.15,0-49.73,0-1.39-.46-1.74-1.79-1.73-23.96,.03-47.92,.04-71.89,0-1.46,0-1.82,.48-1.82,1.87,.04,16.47,.05,32.95,0,49.42,0,1.53,.5,1.89,1.95,1.89,11.93-.05,23.86-.03,35.79-.03Zm-.08-60.4c5.63,0,11.25-.04,16.88,.03,1.37,.02,1.78-.44,1.75-1.79-.08-3.71-.01-7.43-.03-11.15-.04-9.79-7.54-18.26-18.38-18.51-8.9-.21-17.03,6.77-18.33,15.55-.69,4.67-.11,9.38-.33,14.06-.07,1.47,.48,1.84,1.87,1.83,5.52-.06,11.05-.03,16.57-.03Z" />
        <path d="M49.71,74.9c0,3.25-.07,6.5,.03,9.75,.05,1.59-.52,2.09-2.06,2-1.95-.11-3.92-.06-5.88-.02-1.07,.03-1.47-.42-1.47-1.46,.02-6.96,.02-13.93,0-20.89,0-1.06,.44-1.46,1.49-1.44,2.11,.05,4.23,.06,6.34,0,1.15-.03,1.56,.43,1.55,1.54-.03,3.51-.01,7.02,0,10.52Z" />
      </svg>
    </div>
  );
};

export default LockMedalCase;
