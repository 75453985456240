import React, { useState, useContext } from "react";
import { textToURL } from "../../../helpers/validateURL";
import FormatMessage from "./FormatMessage";
import OptionsMessage from "./OptionsMessage/OptionsMessage";
import { UserContext } from "../../../context/userContext";
import DatePicker from "./DatePicker/DatePicker";
import ButtonsSection from "./ButtonsSection/ButtonsSection";
import RadioOptions from "./RadioOptions/RadioOptions";
import ImageBubble from "./ImageBubble/ImageBubble";
import DotMessage from "./DotMessage/DotMessage";
import { contentWithTC } from "../../../helpers/contentWarning";

/**
 * The dialog bubble inside the chat
 *
 * @param {object} props
 * @param {object} props.messageObject
 * @param {string} props.name
 * @param {string} props.type
 * @param {number} props.indexObject
 * @param {number} props.length
 * @param {string[]} props.formData
 * @param {number} props.indexMessagesArray
 * @returns {React.ReactNode}
 */
const BubbleElement = ({
  messageObject,
  name,
  type,
  indexObject,
  length,
  formData,
  indexMessagesArray,
}) => {
  const { setOptionsCheckbox } = useContext(UserContext);
  const [showMore, setShowMore] = useState(false);
  const [indexElementBubble] = useState(indexObject);

  const checkTC = ["timecapture", "time capture"];
  const messagesOrientation = {
    orientation: name === "Digi" ? "orientation" : "orientation--right",
    container:
      name === "Digi" ? "message-container" : "message-container--right",
    bubble:
      name === "Digi"
        ? indexElementBubble === 0 && length === 1
          ? "message-info--unique"
          : indexElementBubble === 0 && length > 1
          ? "message-info--first"
          : indexElementBubble === length - 1 && length > 1
          ? "message-info--last"
          : "message-info"
        : "message-info--right",
    dot: name === "Digi" ? "message-dot" : "message-dot--right",
    text: name === "Digi" ? "text" : "text--right",
  };

  // To edit data in a form
  function isEditarDatosIngresados() {
    if (type.includes("-")) {
      if (type.split("-").at(-1) === "Editar datos ingresados") {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * @param {object} props
   * @param {boolean} props.showOptions
   * @param {object} props.resultFormPreviousResponses
   * @returns {React.ReactNode}
   */
  function OptionsEditFieldsForm(props) {
    const show = props.showOptions;
    if (show) {
      return (
        <OptionsMessage
          options={props.resultFormPreviousResponses}
          containerDirection={"horizontal"}
        />
      );
    }
    return null;
  }
  /**
   * Make sure is not any of these types of messages
   *
   * @param {string} e The key name to filter
   * @returns {boolean}
   */
  function filterUndefinedAndFormName(e) {
    if (e !== "ined" && e !== "Form" && e !== "Submit") return true;
    return false;
  }

  var resultFormPreviousResponsesRaw = formData
    .map((ele) => Object.keys(ele).toString().slice(5))
    .filter(filterUndefinedAndFormName);
  var resultFormPreviousResponses = resultFormPreviousResponsesRaw;

  if (resultFormPreviousResponses.length === 0)
    resultFormPreviousResponses = ["Form modificado"];

  switch (messageObject.type) {
    // For normal text
    case "text_bubble":
      return (
        <div className={messagesOrientation.container}>
          <DotMessage
            type={
              indexElementBubble === 0
                ? messagesOrientation.dot
                : "message-dot--invisible"
            }
          />
          <div className={messagesOrientation.bubble}>
            {messageObject.content.map((text, idx) => (
              <React.Fragment key={idx}>
                {textToURL(text) ? (
                  <FormatMessage text={text} key={idx} />
                ) : (
                  <>
                    <p className={messagesOrientation.text} key={idx}>
                      {showMore || type === "Form-Submit"
                        ? text
                        : text.length > 250
                        ? `${text.substring(0, 250)}...`
                        : `${text.substring(0, 250)}`}
                    </p>
                    {text.length > 250 &&
                    type !== "Form-Submit" &&
                    name === "Digi" ? (
                      <button
                        className="message-showBtn"
                        onClick={() => setShowMore(!showMore)}
                        key={idx}
                      >
                        {showMore ? "Mostrar menos" : "Mostrar más"}
                      </button>
                    ) : null}
                  </>
                )}
              </React.Fragment>
            ))}
            {checkTC.map(
              (/** @type {string} */ v_tc) =>
                messageObject?.content[0]?.toLowerCase()?.includes(v_tc) &&
                contentWithTC("Digi")
            )}
          </div>
        </div>
      );
    // When there are options to select
    case "buttons_bubble":
      return (
        <>
          <DotMessage
            type={
              indexElementBubble === 0
                ? messagesOrientation.dot
                : "message-dot--invisible"
            }
          />

          <OptionsMessage
            options={messageObject.content}
            containerDirection={"horizontal"}
            length={length}
            indexElementBubble={indexElementBubble}
            indexMessagesArray={indexMessagesArray}
          />
        </>
      );
    // Checkbox options (For form only)
    case "checkbox_bubble":
      return (
        <>
          <div className={messagesOrientation.container}>
            <DotMessage
              type={
                indexElementBubble === length - 1
                  ? messagesOrientation.dot
                  : "message-dot--invisible"
              }
            />

            <div className={messagesOrientation.bubble}>
              {messageObject.content.map((optionInArray, index) => {
                return (
                  <div className="function-column" key={index}>
                    <label
                      htmlFor={optionInArray}
                      className="function-checkbox"
                    >
                      <input
                        type="checkbox"
                        key={optionInArray}
                        name={optionInArray}
                        id={optionInArray}
                        className="checkbox__input"
                        onChange={() =>
                          setOptionsCheckbox((arr) =>
                            arr.indexOf(optionInArray) === (-1 || undefined)
                              ? [...arr, optionInArray]
                              : arr.filter(
                                  (element) => element !== optionInArray
                                )
                          )
                        }
                      />
                      <svg className="checkbox__check" width="24" height="24">
                        <polyline points="15 3 8 15 4 12"></polyline>
                      </svg>
                      <span> {optionInArray}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      );
    // Date picker (For form only)
    case "date_bubble":
      return (
        <div className={messagesOrientation.container}>
          <DotMessage
            type={
              indexElementBubble === length - 1
                ? messagesOrientation.dot
                : "message-dot--invisible"
            }
          />

          <div className={messagesOrientation.bubble}>
            <DatePicker />
          </div>
        </div>
      );
    // Edit data (Form only)
    case "edit_bubble":
      return (
        <>
          <div className={messagesOrientation.container}>
            <DotMessage
              type={
                indexElementBubble === length - 1
                  ? messagesOrientation.dot
                  : "message-dot--invisible"
              }
            />

            <OptionsEditFieldsForm
              showOptions={isEditarDatosIngresados()}
              resultFormPreviousResponses={resultFormPreviousResponses}
            />
          </div>
        </>
      );
    case "img_bubble":
      return (
        <>
          <div className={messagesOrientation.container}>
            <DotMessage
              type={
                indexElementBubble === length - 1
                  ? messagesOrientation.dot
                  : "message-dot--invisible"
              }
            />

            <div className={messagesOrientation.bubble}>
              <ImageBubble messageObject={messageObject} />
            </div>
          </div>
        </>
      );
    case "buttons_section":
      return (
        <>
          <div className={messagesOrientation.container}>
            <DotMessage
              type={
                indexElementBubble === length - 1
                  ? messagesOrientation.dot
                  : "message-dot--invisible"
              }
            />

            <ButtonsSection
              options={messageObject.content}
              containerDirection={"horizontal"}
              length={length}
              indexElementBubble={indexElementBubble}
            />
          </div>
        </>
      );
    // Radios type selection (Form only)
    case "radios_bubble":
      return (
        <>
          <div className={messagesOrientation.container}>
            <DotMessage
              type={
                indexElementBubble === length - 1
                  ? messagesOrientation.dot
                  : "message-dot--invisible"
              }
            />
            <RadioOptions
              containerDirection={"horizontal"}
              length={length}
              indexElementBubble={indexElementBubble}
              messageObject={messageObject}
            />
          </div>
        </>
      );
    // To add a file
    case "file_bubble":
      return (
        <>
          <DotMessage
            type={
              indexElementBubble === length - 1
                ? messagesOrientation.dot
                : "message-dot--invisible"
            }
          />
          <RadioOptions
            containerDirection={"horizontal"}
            length={length}
            indexElementBubble={indexElementBubble}
            messageObject={messageObject}
          />
        </>
      );
    // When there is a need of a dropdown
    case "dropdown_bubble":
      return (
        <>
          <DotMessage
            type={
              indexElementBubble === length - 1
                ? messagesOrientation.dot
                : "message-dot--invisible"
            }
          />
          <RadioOptions
            containerDirection={"horizontal"}
            length={length}
            indexElementBubble={indexElementBubble}
            messageObject={messageObject}
          />
        </>
      );
    default:
      return null;
  }
};
export default BubbleElement;
