import { useState, useContext } from "react";
import { SocketContext } from "../../../../context/socketContext";
import { UserContext } from "../../../../context/userContext";
import "./radioOptions.scss";

/**
 * Exclusive selection component for radial "checkboxes"
 *
 * @param {object} props
 * @param {'vertical' | 'horizontal'} props.containerDirection
 * @param {number} props.indexElementBubble
 * @param {number} props.length
 * @param {{ content: string[] }} props.messageObject
 * @returns {React.ReactNode}
 */
const RadioOptions = ({
  containerDirection,
  indexElementBubble,
  length,
  messageObject,
}) => {
  const [indexElementBubbleButton] = useState(indexElementBubble);
  const [lengthButton] = useState(length);
  const { emitMessage } = useContext(SocketContext);
  const [clickedRadio, setClickedRadio] = useState(/** @type {number | null} */ (null));
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const { user, optionsCheckbox } = useContext(UserContext);
  const { name } = user;
  var options = messageObject.content;
  const orientationButtons = {
    buttonsContainer:
      options.length > 15 || containerDirection === "vertical"
        ? "containerVerticalButtons" //isn't use
        : indexElementBubbleButton === 0 && lengthButton === 1
        ? "containerHorizontalButtons--unique"
        : indexElementBubbleButton === 0
        ? "containerHorizontalButtons--first"
        : indexElementBubbleButton === lengthButton - 1
        ? "containerHorizontalButtons--last"
        : "containerHorizontalButtons",
    optionButton:
      options.length > 15 || containerDirection === "vertical"
        ? "verticalButton" //isn't use
        : "horizontalButton",
  };

  /**
   * Click handler for option elements
   *
   * @param {string} option
   * @param {number} index
   */
  const handleOption = (option, index) => {
    if (option) {
      //this is when we have for ex: "beneficios-gympass", we want only the second part
      if (option.includes("-")) {
        let arrayResponse = option.split("-");
        option = arrayResponse[1];
      }
      emitMessage("chat", { name, option, optionsCheckbox });
    }
    setClickedRadio(index);
    setButtonDisabled(!isButtonDisabled);
  };

  return (
    <>
      <div
        className={
          "wrapper-" +
          orientationButtons.buttonsContainer +
          " orientationButtons-radioButtons-modifier"
        }
      >
        {messageObject.content.map((option, index) => {
          return (
            <div className="function-column" key={index}>
              <label htmlFor={option} className="function-checkbox">
                <div
                  className={
                    clickedRadio === index
                      ? "container-elements-radio--clicked"
                      : "container-elements-radio"
                  }
                  onClick={() =>
                    !isButtonDisabled && handleOption(option, index)
                  }
                  disabled={isButtonDisabled}
                >
                  <svg
                    className={
                      clickedRadio === index
                        ? "checkbox__check radioOptions-radio--clicked"
                        : "checkbox__check radioOptions-radio"
                    }
                    width="24"
                    height="24"
                  >
                    <polyline points="15 3 8 15 4 12"></polyline>
                  </svg>
                  <span> {option}</span>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default RadioOptions;
