import { useContext, useEffect, useState } from "react";
import "./sideMenu.scss";
import { motion } from "framer-motion";
import { Nav } from "react-bootstrap";
import { ScreenContext } from "../../context/screenContext";
import {
  ArrowLeftSVG,
  DarkThemeSVG,
  LightThemeSVG,
  CopySVG,
} from "../../styles/svg/sidemenuSVG";
import { UserContext } from "../../context/userContext";
import MenuActions from "./constants";
import pJson from "./../../../package.json";
import DropdownMenu from "../../commons/dropdown-menu/DropdownMenu";
import NewsItem from "../../commons/news-menu-item/NewsItem";

const SideMenu = () => {
  const {
    barSize,
    handleBarSize,
    nextTheme,
    setTheme,
    handleScreenColsSize,
    getBarAndScreenSize,
    handleMenuIsOpen,
    menuIsOpen,
  } = useContext(ScreenContext);

  const { user, setUser } = useContext(UserContext);
  const { navItems, newsItems } = MenuActions();
  const [rotate, setRotate] = useState(false);
  const [showToolsTip, setShowToolsTip] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleableClasses = {
    menu: barSize > 1 ? "menu-container" : "menu-container--small",
    header:
      barSize > 1
        ? "actions-container__header"
        : "actions-container__header--small",
    sections: barSize > 1 ? "actions-container" : "actions-container--small",
    toggleButton: barSize > 1 ? "toggle-sidebar" : "toggle-sidebar--small",
    mainContainer: barSize > 1 ? "main-container" : "main-container--small",
    border:
      barSize > 1
        ? "actions-container__border"
        : "actions-container__border--small",
  };

  useEffect(() => {
    if (sessionStorage.getItem("userData")) {
      setUser(JSON.parse(sessionStorage.getItem("userData")));
    }
    if (menuIsOpen) setRotate(true);
    else setRotate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsOpen]);

  const getInitials = (fullName) => {
    if (!fullName) return "";
    const names = fullName.split(" ");
    const initials = names.map((name) => name.charAt(0).toUpperCase());
    return initials.join("");
  };

  const initials = getInitials(user?.name);

  const handleDropdownToggle = (dropdownId) => {
    if (openDropdown === dropdownId && menuIsOpen) setOpenDropdown(null);
    else setOpenDropdown(dropdownId);
  };

  const iconSize = navItems.length < 5 ? "32px" : "27px";
  document.documentElement.style.setProperty("--iconSize", iconSize);

  return (
    <>
      <Nav
        defaultActiveKey="/home"
        className={`${toggleableClasses.mainContainer} flex-row`}
      >
        <div className={toggleableClasses.menu}>
          <div className={toggleableClasses.sections}>
            {/* User information */}
            <div className={toggleableClasses.header}>
              <div className="actions-container__tab__avatar">
                <span
                  className="actions-container__tab__avatar__initials"
                  style={{
                    fontSize: `${initials.length > 3 ? "0.95rem" : "1.5rem"}`,
                  }}
                >
                  {initials}
                </span>
              </div>
              <div className="actions-container__tab-info">
                <h3 className="actions-container__tab-name">{user?.name}</h3>
                <p className="actions-container__tab-job">
                  {user?.upwcjobtitle?.split("-")[3] !== undefined
                    ? user?.upwcjobtitle?.split("-")[2] +
                      " " +
                      user?.upwcjobtitle?.split("-")[3]
                    : user?.upwcjobtitle?.split("-")[2]}
                </p>
                {user?.file && (
                  <div className="actions-container__tab-file">
                    <p className="actions-container__tab-file-number">
                      Legajo: {user.file}
                    </p>
                    <div
                      className="actions-container__tab-file-icon"
                      onMouseEnter={() => setShowToolsTip(0)}
                      onMouseLeave={() => setShowToolsTip(null)}
                    >
                      <CopySVG
                        onClickFunction={() => {
                          navigator.clipboard.writeText(user.file);
                        }}
                      />
                    </div>
                  </div>
                )}
                {showToolsTip === 0 ? (
                  <p className="tooltiptext-copy">Copiar al portapapeles</p>
                ) : (
                  <p className="tooltiptext-copy-invisible">&nbsp;</p>
                )}
              </div>
            </div>
            {/* Navigation options */}
            {navItems.map(
              (
                {
                  topTitle,
                  title,
                  helpText,
                  icon,
                  onClickFunction,
                  options,
                  isSelected,
                },
                i
              ) => {
                return (
                  <DropdownMenu
                    topTitle={topTitle}
                    title={title}
                    helpText={helpText}
                    icon={icon}
                    onClickFunction={onClickFunction}
                    options={options}
                    isSelected={isSelected}
                    isOpen={openDropdown === `dropdown${i}`}
                    onToggle={handleDropdownToggle}
                    id={`dropdown${i}`}
                    key={i}
                  />
                );
              }
            )}
            <div className={`${toggleableClasses.border} bottom`}></div>
            <div className="actions-container__news-container">
              {newsItems.map(
                (
                  { icon, title, subtitle, onClickFunction, isNew, isSelected },
                  i
                ) => {
                  return (
                    <NewsItem
                      icon={icon}
                      title={title}
                      subtitle={subtitle}
                      onClickFunction={onClickFunction}
                      isNew={isNew}
                      keyValue={i}
                      isSelected={isSelected}
                      key={i}
                    />
                  );
                }
              )}
            </div>
          </div>
        </div>
        {/* Fold/show the menu */}
        <div className={toggleableClasses.toggleButton}>
          <button
            className="toggle-sidebar__button"
            onClick={() => {
              let width = window.innerWidth;
              const sizes = getBarAndScreenSize(width);
              if (menuIsOpen) {
                handleBarSize(sizes.close.barCols);
                handleScreenColsSize(sizes.close.screenCols);
              } else {
                handleBarSize(sizes.open.barCols);
                handleScreenColsSize(sizes.open.screenCols);
              }
              handleMenuIsOpen(!menuIsOpen);
            }}
          >
            <motion.div
              animate={{ rotate: rotate ? 180 : 0 }}
              transition={{ delay: 0.15 }}
            >
              <div className="toggle-sidebar__arrow">
                <ArrowLeftSVG />
              </div>
            </motion.div>
          </button>
        </div>
        {/* Theme button */}
        <div className={toggleableClasses.menu}>
          <div className={toggleableClasses.sections}>
            <DropdownMenu
              title={nextTheme === "dark" ? "Modo oscuro" : "Modo claro"}
              icon={
                nextTheme === "dark" ? (
                  <DarkThemeSVG size={iconSize} />
                ) : (
                  <LightThemeSVG size={iconSize} />
                )
              }
              onClickFunction={() => {
                setTheme({ isTouched: true, theme: nextTheme });
                localStorage.setItem("theme", nextTheme);
              }}
              isSelected={false}
              isOpen={openDropdown === "dropdownTheme"}
              onToggle={handleDropdownToggle}
              id="dropdownTheme"
            />
          </div>
        </div>
        <p className="version-text">Version: {pJson.version}</p>
      </Nav>
      <Nav defaultActiveKey="/home">
        {!menuIsOpen && (
          <button
            className="toggle-sidebar__button--mobile"
            onClick={() => {
              let width = window.innerWidth;
              const sizes = getBarAndScreenSize(width);
              if (menuIsOpen) {
                handleBarSize(sizes.close.barCols);
                handleScreenColsSize(sizes.close.screenCols);
              } else {
                handleBarSize(sizes.open.barCols);
                handleScreenColsSize(sizes.open.screenCols);
              }
              handleMenuIsOpen(!menuIsOpen);
            }}
          >
            <motion.div
              animate={{ rotate: rotate ? 180 : 0 }}
              transition={{ delay: 0.15 }}
            >
              <div className="toggle-sidebar__arrow">
                <ArrowLeftSVG />
              </div>
            </motion.div>
          </button>
        )}
      </Nav>
    </>
  );
};

export default SideMenu;
