import { useContext, useState, useEffect } from "react";
import "./npsConfirmationPopup.scss";
import { PopupsContext } from "../../../context/popupsContext";
import { CloseNoCircleSVG } from "../../../styles/assets/icons";
import Modal from "react-bootstrap/Modal";

const NpsConfirmationPopup = () => {
  const { setShowConfirmationPopup } = useContext(PopupsContext);
  const [colorBottom, setColorBottom] = useState("black");
  useEffect(() => {
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "light") {
        setColorBottom("black");
      } else {
        setColorBottom("white");
      }
    }
  }, []);
  function handleClick() {
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
    }));
  }
  function handleClickClose() {
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
    }));
  }
  return (
    <>
      <Modal show={true} centered backdrop={true}>
        <Modal.Body>
          <div className="container-body-confirmation-nps">
            <div className="container-close-bottom" onClick={handleClickClose}>
              <CloseNoCircleSVG color={colorBottom} />
            </div>
            <div className="nps-confirmation-title">¡Gracias!</div>
            <div className="nps-confirmation-body-text">
              <p>Tus comentarios ayudan a mejorar la plataforma</p>
            </div>
            <div className="container-buttons container-buttons--nps-confirmation">
              <button
                className="enviar-button"
                type="submit"
                onClick={handleClick}
              >
                Cerrar
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NpsConfirmationPopup;
