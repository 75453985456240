import axios from "axios";

export const postFormIdea = async (ideaFormResponses, userData, token) => {
  const typeForm = Object.values(ideaFormResponses[0])[0]
    .substring(0, 4)
    .toLowerCase();

  const ideaURL = `${process.env.REACT_APP_BACK_API}form/${typeForm}`;

  const jsonData = {};

  ideaFormResponses.forEach(function (item) {
    let key = Object.keys(item)[0].split("-")[1];
    let value = Object.values(item)[0];
    jsonData[key] = value;
  });

  for (const [key, value] of Object.entries(JSON.parse(userData))) {
    jsonData["user" + key] = value;
  }
  // Axios post request to save the idea form data from chatview input
  try {
    const responseIdea = await axios({
      method: "POST",
      url: ideaURL,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: jsonData,
    });

    return responseIdea;
  } catch (err) {
    console.log(err);
    return err;
  }
};
