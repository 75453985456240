import "./functionsView.scss";
// JSON with the options
import options from "./functions.json";
import { useState, useEffect, useContext } from "react";
import { postFunctions } from "../../services/function/functionService";
import { useNavigate, useLocation } from "react-router-dom";
import { PopupsContext } from "../../context/popupsContext";
import ProposalDigi from "./ProposalDigi.png";
import { ScreenContext } from "../../context/screenContext";
import { appInsightsTracker } from "../../helpers/AppInsights";
import { SessionContext } from "../../context/sessionContext";
import { UserContext } from "../../context/userContext";
import { deleteToken } from "../../services/login/loginService";

const FunctionsView = () => {
  const { setShowConfirmationPopup } = useContext(PopupsContext);
  const { setIsScreenLoading } = useContext(ScreenContext);
  const { apiToken } = useContext(UserContext);
  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);
  const [email, setEmail] = useState("");
  // Stores checkboxs selected values
  const [option, setOption] = useState([]);
  const [otherOption, setOtherOption] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (sessionStorage.getItem("userData")) {
      const jsonSessionData = JSON.parse(sessionStorage.getItem("userData"));
      setEmail(jsonSessionData["email"]);
    } else {
      return null;
    }

    appInsightsTracker.pageView("Dejanos tu propuesta");
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Dejanos tu propuesta",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Dejanos tu propuesta",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Dejanos tu propuesta",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("buttonSend").disabled = true;
    setIsScreenLoading({ state: true });
    try {
      const { data, status } = await postFunctions({
        option,
        email,
        otherOption,
        apiToken,
      });
      setShowConfirmationPopup((prevState) => ({
        ...prevState,
        response: data,
      }));
      setIsScreenLoading({ state: false });
      if (data === undefined || status === 500 || status === 400) {
        setShowConfirmationPopup((prevState) => ({
          ...prevState,
          show: "error",
        }));
      } else if (status === 200) {
        setShowConfirmationPopup((prevState) => ({
          ...prevState,
          show: "success",
          message: (
            <p>
              Tu reporte se envío<span>correctamente</span> ¡Gracias!
            </p>
          ),
        }));
      }
      navigate("/chat");
    } catch (err) {
      setIsScreenLoading({ state: false });
      setShowConfirmationPopup((prevState) => ({
        ...prevState,
        show: "error",
      }));
      navigate("/chat", { state: { location } });
    }
  };

  return (
    <div className="functions-container">
      <div className="functions-container__items">
        <p className="functions-container__items__title">
          Dejanos tu propuesta
        </p>
        <form
          type="submit"
          className="functions-container__items__form"
          onSubmit={handleSubmit}
        >
          <p className="functions-container__items__form__first-subtitle">
            ¿Te gustaría agregar una funcionalidad a la plataforma?
          </p>
          <p className="functions-container__items__form__second-subtitle">
            Seleccioná en las opciones de abajo para dejarnos tu opinión. Podés
            elegir más de una opción.
          </p>
          {/* Form options */}
          {options.map((optionInArray, index) => {
            return (
              <div className="function-column" key={index}>
                <label
                  htmlFor={optionInArray.name}
                  className="function-checkbox"
                >
                  <input
                    type="checkbox"
                    key={optionInArray.name}
                    name={optionInArray.name}
                    id={optionInArray.name}
                    className="function-checkbox__input"
                    onChange={() =>
                      optionInArray.name !== "otro" &&
                      setOption((arr) =>
                        arr.indexOf(optionInArray.text) === (-1 || undefined)
                          ? [...arr, optionInArray.text]
                          : arr.filter(
                              (element) => element !== optionInArray.text
                            )
                      )
                    }
                  />
                  <svg
                    className="function-checkbox__check"
                    width="24"
                    height="24"
                    viewBox="0 0 20 20"
                  >
                    <polyline points="15 5 8 15 5 12"></polyline>
                  </svg>
                  <span> {optionInArray.text}</span>
                </label>
              </div>
            );
          })}
          <p className="function-checkbox__textArea-title">
            ¿Tenés otra idea? Escribila acá:
          </p>
          <textarea
            id="otherOption"
            className="function-checkbox__textArea"
            placeholder="Tu mensaje..."
            onChange={(e) => {
              setOtherOption(e.target.value);
            }}
          ></textarea>
          <div className="functions-container__items__form__button-container">
            <button
              className="functions-container__items__form__button-container__button"
              id="buttonSend"
              disabled={
                option.length > 0 || otherOption.length > 0 ? false : true
              }
              type="submit"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
      <img
        className="functions-container__image"
        alt="Proposal Digi"
        src={ProposalDigi}
      />
    </div>
  );
};

export default FunctionsView;
