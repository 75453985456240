import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./confirmationLogOut.scss";
import { PopupsContext } from "../../../context/popupsContext";
import Modal from "react-bootstrap/Modal";
import { DeleteSVG } from "../../../styles/assets/icons";
import { SessionContext } from "../../../context/sessionContext";
import { UserContext } from "../../../context/userContext";
import { deleteToken } from "../../../services/login/loginService";

const ConfirmationLogOut = () => {
  const { setShowConfirmationPopup, showConfirmationPopup } =
    useContext(PopupsContext);
  const [colorBottom, setColorBottom] = useState("black");
  const navigate = useNavigate();
  const { activeSession, setActiveSession, setLogOut } =
    useContext(SessionContext);
  const { apiToken } = useContext(UserContext);

  useEffect(() => {
    if (localStorage.getItem("theme")) {
      if (localStorage.getItem("theme") === "light") {
        setColorBottom("black");
      } else {
        setColorBottom("white");
      }
    }
  }, []);

  const clearCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    });
  };

  function handleClick(value) {
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
    }));
    if (value) {
      sessionStorage.clear();
      setActiveSession({ ...activeSession, activeSession: false });
      setLogOut(true);
      deleteToken(apiToken);
      clearCookies();
      navigate("/logout");
    }
  }

  const handleClose = () =>
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: null,
    }));

  return (
    <div className="container-popup">
      <Modal
        show={showConfirmationPopup.show === "logOut"}
        onHide={handleClose}
      >
        <Modal.Header>
          <div className="container-popup--close" onClick={handleClose}>
            <DeleteSVG color={colorBottom} />
          </div>
          <Modal.Title>Cerrar Sesión</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="logout-confirmation-body">
            Estás a punto de cerrar sesión ¿Estás seguro?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button-confirmation button-confirmation--no"
            onClick={() => handleClick(false)}
          >
            NO
          </button>
          <button
            className="button-confirmation button-confirmation--yes"
            onClick={() => handleClick(true)}
          >
            SI
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmationLogOut;
