import React, { useState, useEffect, useContext } from "react";
import logo from "./assets/logo.png";
import logoWhite from "./assets/logoPwcWhite.png";
import "./loader.scss";
import { UserContext } from "../../context/userContext";

/**
 * Loading screen for a view change
 *
 * @param {object} props
 * @param {boolean} props.loadingPage
 * @returns {React.ReactNode}
 */
export default function Loader({ loadingPage }) {
  const { theme } = useContext(UserContext);
  const [loaderImg, setLoaderImg] = useState();

  useEffect(() => {
    if (loadingPage) setLoaderImg(logoWhite);
    else {
      //we first check with windows preference
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? setLoaderImg(logoWhite)
        : setLoaderImg(logo);
      //check web preference
      document.body.dataset.theme === "dark"
        ? setLoaderImg(logoWhite)
        : setLoaderImg(logo);
    }
  }, [loadingPage, theme]);

  return (
    <div className="container-loader">
      <div className="loader-loader"></div>
      <img className="logo-loader" src={loaderImg} alt="logo-pwc" />
    </div>
  );
}
