import { createContext, useState } from "react";

export const WelcomeMessageContext = createContext({});
const { Provider } = WelcomeMessageContext;

const WelcomeMessageProvider = ({ children }) => {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState({
    show: false,
    refresh: false,
  });

  return (
    <Provider value={{ showWelcomeMessage, setShowWelcomeMessage }}>
      {children}
    </Provider>
  );
};

export default WelcomeMessageProvider;
