const StarsSVG = () => {
  return (
    <svg
      width="73"
      height="17"
      viewBox="0 0 75 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="title-rank--stars"
    >
      <g clipPath="url(#clip0_9044_71600)">
        <path
          d="M3.56164 18.9991C3.6172 18.3793 3.85055 17.7016 4.03057 17.0098C4.38615 15.645 4.75284 14.2847 5.12398 12.9245C5.18398 12.704 5.15732 12.5717 4.97508 12.4231C3.56831 11.2695 2.17043 10.1019 0.770329 8.93901C0.503643 8.71617 0.259181 8.47245 0.101392 8.15212C-0.118624 7.70877 0.0258307 7.31881 0.47253 7.13543C0.812555 6.99616 1.17258 6.9567 1.53038 6.94045C2.80826 6.8801 4.08835 6.83832 5.36844 6.78725C5.84403 6.76868 6.31962 6.73619 6.79521 6.7269C6.98856 6.72458 7.07301 6.63637 7.13524 6.45532C7.72195 4.78405 8.31532 3.11511 8.90426 1.44616C9.03982 1.06084 9.1865 0.680166 9.41985 0.348234C9.74209 -0.111365 10.2266 -0.118328 10.5622 0.329664C10.9955 0.909965 11.16 1.62025 11.4022 2.28876C11.8956 3.65363 12.3801 5.02314 12.8512 6.39497C12.9379 6.64798 13.0646 6.71994 13.3135 6.73154C15.1958 6.81046 17.076 6.90563 18.9561 6.99616C19.1717 7.00545 19.3739 7.07972 19.5673 7.16561C19.9984 7.36059 20.1206 7.78537 19.8739 8.20783C19.625 8.63261 19.2495 8.92276 18.8828 9.22684C17.6138 10.2783 16.347 11.3322 15.0736 12.3767C14.8602 12.5508 14.8114 12.7086 14.8869 12.9895C15.3781 14.7745 15.8425 16.5642 16.3204 18.3538C16.4048 18.6649 16.4448 18.9805 16.427 19.3032C16.4026 19.8069 16.0937 20.0645 15.6159 19.9694C15.2447 19.8951 14.9247 19.7001 14.6136 19.4842C13.1868 18.4884 11.76 17.4973 10.3421 16.4899C10.0999 16.3181 9.93322 16.2972 9.67542 16.4806C8.15309 17.5623 6.6152 18.6207 5.07953 19.6839C4.93952 19.7814 4.78173 19.8556 4.62394 19.9137C3.93723 20.162 3.55498 19.8672 3.56164 19.0037V18.9991Z"
          fill="#EB8C00"
        />
      </g>
      <g clipPath="url(#clip1_9044_71600)">
        <path
          d="M30.5616 18.9991C30.6172 18.3793 30.8506 17.7016 31.0306 17.0098C31.3861 15.645 31.7528 14.2847 32.124 12.9245C32.184 12.704 32.1573 12.5717 31.9751 12.4231C30.5683 11.2695 29.1704 10.1019 27.7703 8.93901C27.5036 8.71617 27.2592 8.47245 27.1014 8.15212C26.8814 7.70877 27.0258 7.31881 27.4725 7.13543C27.8126 6.99616 28.1726 6.9567 28.5304 6.94045C29.8083 6.8801 31.0883 6.83832 32.3684 6.78725C32.844 6.76868 33.3196 6.73619 33.7952 6.7269C33.9886 6.72458 34.073 6.63637 34.1352 6.45532C34.7219 4.78405 35.3153 3.11511 35.9043 1.44616C36.0398 1.06084 36.1865 0.680166 36.4198 0.348234C36.7421 -0.111365 37.2266 -0.118328 37.5622 0.329664C37.9955 0.909965 38.16 1.62025 38.4022 2.28876C38.8956 3.65363 39.3801 5.02314 39.8512 6.39497C39.9379 6.64798 40.0646 6.71994 40.3135 6.73154C42.1958 6.81046 44.076 6.90563 45.9561 6.99616C46.1717 7.00545 46.3739 7.07972 46.5673 7.16561C46.9984 7.36059 47.1206 7.78537 46.8739 8.20783C46.625 8.63261 46.2495 8.92276 45.8828 9.22684C44.6138 10.2783 43.347 11.3322 42.0736 12.3767C41.8602 12.5508 41.8114 12.7086 41.8869 12.9895C42.3781 14.7745 42.8425 16.5642 43.3204 18.3538C43.4048 18.6649 43.4448 18.9805 43.427 19.3032C43.4026 19.8069 43.0937 20.0645 42.6159 19.9694C42.2447 19.8951 41.9247 19.7001 41.6136 19.4842C40.1868 18.4884 38.76 17.4973 37.3421 16.4899C37.0999 16.3181 36.9332 16.2972 36.6754 16.4806C35.1531 17.5623 33.6152 18.6207 32.0795 19.6839C31.9395 19.7814 31.7817 19.8556 31.6239 19.9137C30.9372 20.162 30.555 19.8672 30.5616 19.0037V18.9991Z"
          fill="#EB8C00"
        />
      </g>
      <g clipPath="url(#clip2_9044_71600)">
        <path
          d="M57.5616 18.9991C57.6172 18.3793 57.8506 17.7016 58.0306 17.0098C58.3861 15.645 58.7528 14.2847 59.124 12.9245C59.184 12.704 59.1573 12.5717 58.9751 12.4231C57.5683 11.2695 56.1704 10.1019 54.7703 8.93901C54.5036 8.71617 54.2592 8.47245 54.1014 8.15212C53.8814 7.70877 54.0258 7.31881 54.4725 7.13543C54.8126 6.99616 55.1726 6.9567 55.5304 6.94045C56.8083 6.8801 58.0883 6.83832 59.3684 6.78725C59.844 6.76868 60.3196 6.73619 60.7952 6.7269C60.9886 6.72458 61.073 6.63637 61.1352 6.45532C61.7219 4.78405 62.3153 3.11511 62.9043 1.44616C63.0398 1.06084 63.1865 0.680166 63.4198 0.348234C63.7421 -0.111365 64.2266 -0.118328 64.5622 0.329664C64.9955 0.909965 65.16 1.62025 65.4022 2.28876C65.8956 3.65363 66.3801 5.02314 66.8512 6.39497C66.9379 6.64798 67.0646 6.71994 67.3135 6.73154C69.1958 6.81046 71.076 6.90563 72.9561 6.99616C73.1717 7.00545 73.3739 7.07972 73.5673 7.16561C73.9984 7.36059 74.1206 7.78537 73.8739 8.20783C73.625 8.63261 73.2495 8.92276 72.8828 9.22684C71.6138 10.2783 70.347 11.3322 69.0736 12.3767C68.8602 12.5508 68.8114 12.7086 68.8869 12.9895C69.3781 14.7745 69.8425 16.5642 70.3204 18.3538C70.4048 18.6649 70.4448 18.9805 70.427 19.3032C70.4026 19.8069 70.0937 20.0645 69.6159 19.9694C69.2447 19.8951 68.9247 19.7001 68.6136 19.4842C67.1868 18.4884 65.76 17.4973 64.3421 16.4899C64.0999 16.3181 63.9332 16.2972 63.6754 16.4806C62.1531 17.5623 60.6152 18.6207 59.0795 19.6839C58.9395 19.7814 58.7817 19.8556 58.6239 19.9137C57.9372 20.162 57.555 19.8672 57.5616 19.0037V18.9991Z"
          fill="#EB8C00"
        />
      </g>
      <defs>
        <clipPath id="clip0_9044_71600">
          <rect width="20.8962" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_9044_71600">
          <rect
            width="20.8962"
            height="20"
            fill="white"
            transform="translate(27)"
          />
        </clipPath>
        <clipPath id="clip2_9044_71600">
          <rect
            width="20.8962"
            height="20"
            fill="white"
            transform="translate(54)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarsSVG;
