import { useState, useContext, useEffect } from "react";
import {
  MenuDotIcon,
  HomeSVG,
  RefreshSVG,
  ToggleSVG,
  IconInformationSVG,
  LogOutSVG,
} from "../../../styles/assets/icons";
import "../menu-dot/MenuDot.scss";
import { PopupsContext } from "../../../context/popupsContext";
import { WelcomeMessageContext } from "../../../context/welcomeMessageContext";
import { ScreenContext } from "../../../context/screenContext";
import { NewsletterInformation } from "./NewsletterInformation/NewsletterInformation";
import { sendToggleValue } from "./NewsletterInformation/newsletter";
import { UserContext } from "../../../context/userContext";
import { getToggleValue } from "./NewsletterInformation/newsletter";

export const MenuDot = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [toggleActive, setToggleActive] = useState(false);
  const { setShowConfirmationPopup } = useContext(PopupsContext);
  const { user, apiToken } = useContext(UserContext);
  const { showWelcomeMessage, setShowWelcomeMessage } = useContext(
    WelcomeMessageContext
  );
  const { scrollRef } = useContext(ScreenContext);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
    setShowPopUp(false);
  };

  //Renderiza el contenido de WelcomeMessage al seleccionar la opción "Mostrar Bienvenida"
  const handleOptionClick = (option) => {
    if (option === "Mostrar Bienvenida") {
      setShowWelcomeMessage(true);
      setShowMenu(false);
    }
  };

  //Realiza el scroll al inicio del chat cuando WelcomeMessage aparece al seleccionar la opción "Mostrar bienvenida"
  const showWelcome = () => {
    scrollRef.current.scrollTop = 0;
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    setShowWelcomeMessage({ ...showWelcomeMessage, show: false });
    setShowMenu(false);
    setShowPopUp(false);
  };

  //"Reiniciar chat"
  function handleReset(e) {
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: "resetChat",
    }));
    setShowPopUp(false);
  }

  //Logica para que el menu se cierre al realizar click afuera del menu de opciones
  const handleClickOutside = (event) => {
    if ((showMenu || showPopUp) && !event.target.closest(".menu")) {
      setShowMenu(false);
      setShowPopUp(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu, showPopUp]);

  useEffect(() => {
    try {
      const setValue = async () => {
        let value = await getToggleValue(
          user?.cloudEmail || user?.email,
          apiToken
        );
        // if (![200, 304].includes(value?.status))
        //   throw Error("Error with credentials: User not authorized");
        setToggleActive(value.data?.response);
      };
      if (user?.email !== undefined) {
        setValue();
      }
    } catch (err) {
      console.log(err);
    }
  }, [user, apiToken]);

  //Activa y desactiva el toggle
  //Lógica para enviar solicitud al backend
  const handleToggleClick = (event) => {
    let userMail = user.cloudEmail || user.email;
    event.stopPropagation();
    setToggleActive(!toggleActive);
    sendToggleValue(userMail, +!toggleActive);
    setShowPopUp(false);
  };

  //Activa y desactiva el icono de informacion sobre el newsletter
  const handleInfoClick = (event) => {
    event.stopPropagation();
    setShowPopUp(!showPopUp);
  };

  const handleNewsletterClick = (event) => {
    event.stopPropagation();
  };

  const handleLogOutClick = () => {
    setShowConfirmationPopup((prevState) => ({
      ...prevState,
      show: "logOut",
    }));
    setShowPopUp(false);
  };

  return (
    <div className="menu">
      <div className="menu-icon" onClick={handleMenuClick}>
        <svg className="menu-icon-svg">
          <MenuDotIcon />
        </svg>
        {showMenu && (
          <div className="menu-options" onClick={handleNewsletterClick}>
            <div onClick={handleReset}>
              <RefreshSVG onClickFunction={handleReset} />
              <span>Reiniciar chat</span>
            </div>
            <div onClick={showWelcome}>
              <HomeSVG />
              <span>Mostrar bienvenida</span>
            </div>
            <div onClick={handleToggleClick} className="toggleSection">
              <ToggleSVG active={toggleActive} />
              <span>Quiero recibir novedades</span>
              <div onClick={handleInfoClick} className="iconInfo">
                <IconInformationSVG active={showPopUp} />
              </div>
            </div>
            <hr />
            <div onClick={handleLogOutClick}>
              <LogOutSVG onClickFunction={handleLogOutClick} />
              <span>Log Out</span>
            </div>
          </div>
        )}
        {showPopUp && (
          <div className="popup" onClick={handleNewsletterClick}>
            <NewsletterInformation />
          </div>
        )}
      </div>
    </div>
  );
};
