import { QuestionSVG } from "../../styles/assets/icons";
import { useState } from "react";
import "./globalHeader.scss";

/**
 *
 * @param {object} props
 * @param {object} [props.selectedItem]
 * @param {string} props.selectedItem.headerTitle
 * @param {string | null} props.selectedItem.headerHelp
 * @param {React.ReactNode} props.selectedItem.headerSubtitle
 * @param {React.ReactNode} props.selectedItem.icon
 * @param {string} props.selectedItem.title
 * @param {React.ReactNode} [props.selectedItem.headerRight]
 * @param {(() => void) | null} props.selectedItem.onClickFunction
 * @returns {React.ReactNode}
 */
const GlobalHeader = ({ selectedItem }) => {
  const [showToolsTip, setShowToolsTip] = useState(false);

  return (
    <div
      className={`global-header-container${selectedItem ? "" : "--notShow"}`}
    >
      {selectedItem && (
        <>
          <div className="global-header-container__left">
            <div className="global-header-container__left__title-container">
              <p className="global-header-container__left__title-container__title">
                {selectedItem.headerTitle}
              </p>
              {selectedItem.headerHelp && (
                <div
                  className="global-header-container__left__title-container__help"
                  onMouseEnter={() => setShowToolsTip(true)}
                  onMouseLeave={() => setShowToolsTip(false)}
                >
                  <QuestionSVG />
                  {showToolsTip && (
                    <div className="global-header-container__left__title-container__help__tooltip">
                      {selectedItem.headerHelp}
                    </div>
                  )}
                </div>
              )}
            </div>
            {selectedItem.headerSubtitle}
          </div>
          {selectedItem.headerRight}
        </>
      )}
    </div>
  );
};

export default GlobalHeader;
