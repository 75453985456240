import React from "react";
import { AvatarSVG, BackgroundAvatarSVG } from "../../../styles/svg/avatarSVG";
import { motion } from "framer-motion";
import "./avatarButtonAction.scss";
import { MenuDot } from "../menu-dot/MenuDot";

// Avatar configuration (takes the customization the user selected) + Reset chat action.
const AvatarButtonAction = () => {
  const digiConfig = {
    digi: localStorage.getItem("digi") || "",
    back: localStorage.getItem("back") || "",
    filter: "headerDigi",
  };

  return (
    <div className="digi-avatar-reset-container">
      <div className="digi-avatar-reset-container__avatar">
        {/* Motion div to animate Digi */}
        <motion.div
          className="box"
          animate={{
            rotate: [0, 20, 20, 20, -20, -20, 20, 20, 20, 0],
            y: [0, -2.5, -5, -7.5, -10, -10, -7.5, -5, -2.5, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
          }}
        >
          <AvatarSVG {...digiConfig} />
        </motion.div>
        <BackgroundAvatarSVG {...digiConfig} />
      </div>
      <MenuDot />
    </div>
  );
};

export default AvatarButtonAction;
