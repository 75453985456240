import "./NewsletterInformation.scss"
import { PopUpInformationSVG } from "../../../../styles/assets/icons"

export const NewsletterInformation = () => {

    return(
        <div>
            <PopUpInformationSVG />
            <div className="newsletterContent">
                <h4>👉 Newsletter del Digital Assistant</h4>
                <p>Recibe las <b>últimas novedades del Digital Assistant del AC Buenos Aires</b> antes que nadie. No dudes en cancelar tu suscripción en cualquier momento que lo desees.</p>
            </div>
        </div>
    );
};
