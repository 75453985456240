import { useNavigate } from "react-router-dom";
import { ArrowRedirectSVG } from "../../styles/assets/icons";
import { RobotSVG } from "../../styles/svg/sidemenuSVG";
import { useContext, useEffect, useState } from "react";
import { PopupsContext } from "../../context/popupsContext";
import { appInsightsTracker } from "../../helpers/AppInsights";
import { deleteToken } from "../../services/login/loginService";
import DesktopAutomationView from "./DesktopAutomationView";
import "./automationsView.scss";
import { SessionContext } from "../../context/sessionContext";
import ErrorPopup from "./modals/ErrorPopup/ErrorPopup";
import { UserContext } from "../../context/userContext";

const AutomationsView = () => {
  const { setShowCustomModal } = useContext(PopupsContext);
  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);
  const { apiToken } = useContext(UserContext);
  const [device] = useState(window.ipc ? "desktop" : "web");
  const [hasPython, setHasPython] = useState(true);
  const [botListError, setbotListError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!window?.ipc) return;

    const hasBotListError = window.ipc.botListError((event) =>
      setbotListError(true)
    );

    const removeHasPython = window.ipc.hasPython((event, hasPythonBool) =>
      setHasPython(hasPythonBool)
    );

    return () => {
      removeHasPython();
      hasBotListError();
    };
  }, []);

  useEffect(() => {
    if (device === "web") {
      setShowCustomModal({
        show: true,
        message: {
          title: (
            <>
              <ArrowRedirectSVG /> Abrir aplicación de escritorio
            </>
          ),
          subtitle:
            "Automatizaciones está disponible solamente en versión escritorio. ¿Querés abrir la aplicación ahora?",
          firstButton: {
            title: "Volver con Digi",
            onClickFunction: () => {
              setShowCustomModal({ show: false });
              navigate("/chat");
            },
          },
          secondButton: {
            title: <>&nbsp;&nbsp;&nbsp;Abrir&nbsp;&nbsp;&nbsp;</>,
            onClickFunction: () => window.open("DigiDesktop://", "_blank"),
          },
        },
      });
      appInsightsTracker.pageView("Automation");
    } else if (!hasPython) {
      setShowCustomModal({
        show: true,
        message: {
          title: (
            <>
              <RobotSVG /> Antes de seguir, instalá Alteryx
            </>
          ),
          subtitle:
            "Ten en cuenta que el Digital Assistant sólo puede descargar y ejecutar automatizaciones correctamente si tenés Alteryx instalado en tu equipo.",
          firstButton: {
            title: "Volver con Digi",
            onClickFunction: () => {
              setShowCustomModal({ show: false });
              navigate("/chat");
            },
          },
          secondButton: {
            title: <>&nbsp;&nbsp;&nbsp;Instalar&nbsp;&nbsp;&nbsp;</>,
            onClickFunction: () => {
              window.ipc.send("installAlteryx");
            },
          },
        },
      });
    }
  }, [navigate, setShowCustomModal, device, hasPython]);

  useEffect(() => {
    appInsightsTracker.pageView("Automation");
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Automation",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Automation",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Automation",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  return (
    <>
      {device === "desktop" ? (
        <>
          {botListError && <ErrorPopup />}
          <DesktopAutomationView />
        </>
      ) : (
        <div className="desktop-app-popUp"></div>
      )}
    </>
  );
};

export default AutomationsView;
