import React, { useEffect, useState, useContext } from "react"; //useContext
import Loader from "../../commons/loader/Loader";
import ButtonTab from "../../commons/button-tab/ButtonTab";
import Challenges from "../../commons/my-points/challenges/Challenges";
import DonutChart from "../../commons/levels/donut-chart/DonutChart";
import Score from "../../commons/levels/score/Score";
import RankDigiSVG from "../../styles/svg/levels/rankDigiSVG";
import useWindowDimensions from "../../services/screenSize/getWindowDimension";
import calculatePercentageDonut from "../../helpers/calculatePercentageDonut";
import { CalculateProgressScorebar } from "../../helpers/CalculateProgressScorebar";
import { getUserStats } from "../../services/userStats/statsService";
import { useNavigate } from "react-router-dom";
import { PopupsContext } from "../../context/popupsContext";
import Leaderboard from "../../commons/my-points/ranking/Leaderboard";
import { appInsightsTracker } from "../../helpers/AppInsights";
import "./myPointsView.scss";
import { SessionContext } from "../../context/sessionContext";
import { UserContext } from "../../context/userContext";
import { deleteToken } from "../../services/login/loginService";

const MyPointsView = () => {
  const [statsUser, setStatsUser] = useState({});
  const [propsDonut, setPropsDonut] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState();
  const { setShowPopupStats } = useContext(PopupsContext);
  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);
  const { apiToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { width: screenWidth, height: screenHeight } = useWindowDimensions();
  //we use these to calculate the size of the donut
  const size = screenHeight * 0.03 + screenWidth * 0.03;
  const strokewidth = screenHeight * 0.005 + screenWidth * 0.005;

  useEffect(() => {
    // Get the user data first
    setUserData(JSON.parse(sessionStorage.getItem("userData")));
    appInsightsTracker.pageView("Mis Puntos");
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Mis Puntos",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Mis Puntos",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Mis Puntos",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  useEffect(() => {
    if (userData !== undefined) {
      // Get data from the back
      async function getViewStats() {
        try {
          setIsLoading(true);
          const email = userData.email;
          const result = await getUserStats(email, apiToken);
          setStatsUser(result.data?.response);
          const points = result.data.response?.points;
          //get percent and values of donut
          const percentagePoints = calculatePercentageDonut(points);
          //set color progress number
          document.documentElement.style.setProperty(
            "--pointsColor",
            `${percentagePoints.color}`
          );
          setPropsDonut(percentagePoints);
          var { percentScoreBarSubSection } = CalculateProgressScorebar(points);
          if (percentScoreBarSubSection > 100) percentScoreBarSubSection = 99;
          document.documentElement.style.setProperty(
            "--positionArrowBar",
            `${percentScoreBarSubSection}%`
          );
          //set the value for the progress of the level (next to the donut)
          const truncPercentage = Math.trunc(percentagePoints.percentage); //the animation of the level progress only works with natural numbers
          document.documentElement.style.setProperty(
            "--to",
            `${truncPercentage}`
          );
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          navigate("/error");
          console.log(error);
        }
      }
      getViewStats();
    }
  }, [navigate, userData]);

  const handleClick = () => {
    setShowPopupStats({
      show: true,
      stats: {
        userInfo: statsUser.userInfo,
        userStats: statsUser.userStats,
      },
      isMonthly: false,
      isExternal: false,
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={`my-points-container${isLoading ? "--blur" : ""}`}>
        <div className="my-points-header">
          <p className="my-points-header__title">Mis Puntos</p>
          <div
            className="window-leadersboard__row window-leadersboard__row--user-header leaderboard--header"
            onClick={handleClick}
            style={{ borderTop: 0, padding: "0.5rem 1rem" }}
          >
            <p>Conocer mis estadisticas</p>
          </div>
        </div>
        <div className="score-container">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <DonutChart
              size={size || 186}
              strokewidth={strokewidth || 36}
              propsDonut={propsDonut}
              isLoading={isLoading}
            />
            <Score statsUser={statsUser} />
          </div>
          <div
            className="window-leadersboard__row window-leadersboard__row--user-header leaderboard--banner"
            style={{
              marginBottom: "0.5rem",
              borderTop: 0,
              height: "fit-content",
              padding: "0.5rem 0",
            }}
            onClick={handleClick}
          >
            <span>Conocer mis estadísticas</span>
          </div>
          <div className="score-container-rank-digi">
            <RankDigiSVG />
          </div>
        </div>
        {/* Button tabs */}
        <ButtonTab
          items={[
            {
              name: "Tabla de posiciones",
              view: <Leaderboard />,
            },
            { name: "Desafíos", view: <Challenges dataStats={statsUser} /> },
          ]}
        />
      </div>
    </>
  );
};
export default MyPointsView;
