// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinLoader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container-loader {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  z-index: 999;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.loader-loader {
  position: absolute;
  width: 150px;
  height: 150px;
  border: 10px solid transparent;
  border-left-color: var(--orangeColor);
  border-top: 10px solid var(--orangeColor);
  border-bottom-width: 0;
  border-radius: 50%;
  z-index: 2;
  animation-name: spinLoader;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.logo-loader {
  width: 130px;
}

@media screen and (max-height: 700px) {
  .loader-loader {
    width: 90px;
    height: 90px;
  }
  .logo-loader {
    width: 70px;
  }
}
@media screen and (max-width: 576px) {
  .loader-loader {
    width: 90px;
    height: 90px;
  }
  .logo-loader {
    width: 70px;
  }
}`, "",{"version":3,"sources":["webpack://./src/commons/loader/loader.scss"],"names":[],"mappings":"AAAA;EACE;IAEE,uBAAA;EACF;EACA;IAEE,yBAAA;EACF;AACF;AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,6BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,OAAA;EACA,YAAA;EACA,QAAA;EACA,iBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,qCAAA;EACA,yCAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;EACA,0BAAA;EACA,wBAAA;EACA,mCAAA;EACA,iCAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE;IACE,WAAA;IACA,YAAA;EAAF;EAGA;IACE,WAAA;EADF;AACF;AAIA;EACE;IACE,WAAA;IACA,YAAA;EAFF;EAKA;IACE,WAAA;EAHF;AACF","sourcesContent":["@keyframes spinLoader {\n  from {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  to {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n\n.container-loader {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  background-color: transparent;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  left: 0;\n  z-index: 999;\n  right: 0;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.loader-loader {\n  position: absolute;\n  width: 150px;\n  height: 150px;\n  border: 10px solid transparent;\n  border-left-color: var(--orangeColor);\n  border-top: 10px solid var(--orangeColor);\n  border-bottom-width: 0;\n  border-radius: 50%;\n  z-index: 2;\n  animation-name: spinLoader;\n  animation-duration: 0.9s;\n  animation-iteration-count: infinite;\n  animation-timing-function: linear;\n}\n\n.logo-loader {\n  width: 130px;\n}\n\n@media screen and (max-height: 700px) {\n  .loader-loader {\n    width: 90px;\n    height: 90px;\n  }\n\n  .logo-loader {\n    width: 70px;\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .loader-loader {\n    width: 90px;\n    height: 90px;\n  }\n\n  .logo-loader {\n    width: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
