import React, { useContext, useEffect, useState } from "react";
import "./layout.scss";
import SideMenu from "../Side-menu/SideMenu";
import { UserContext } from "../../context/userContext";
import LoadingView from "../Loading-view/FirstLoadingView";
import { PwCSVG } from "../Loading-view/loading-assets/icon-pwc";
import FullLayoutPopup from "../../commons/full-layout-popup/FullLayoutPopup";
import MainAppPopup from "../../commons/main-app-popup/MainAppPopup";
import GlobalHeader from "../../commons/global-header/GlobalHeader";
import MenuActions from "../Side-menu/constants";
import { PopupsContext } from "../../context/popupsContext";
import LoadView from "../Loading-view/LoadingView";
import { ScreenContext } from "../../context/screenContext";
import ErrorPopup from "../Automations-view/modals/ErrorPopup/ErrorPopup";
import { BotsContext } from "../../context/botsContext";
import { SessionContext } from "../../context/sessionContext";

/**
 * This view will be used while another View is loading (Leaderboard for example)
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode}
 */
const Layout = ({ children }) => {
  const { isLoading } = useContext(UserContext);
  const { showCustomModal, showPopupStats } = useContext(PopupsContext);
  const { isScreenLoading } = useContext(ScreenContext);
  const [showLogoPreview, setShowLogoPreview] = useState(true);
  const { navItems, newsItems } = MenuActions();
  const { showErrorPopup, setShowErrorPopup } = useContext(BotsContext);
  const { logOut } = useContext(SessionContext);

  const playLogoPreview = () => {
    setTimeout(() => {
      setShowLogoPreview(false);
    }, 1000);
  };

  useEffect(() => {
    playLogoPreview();
  }, []);

  return (
    <div className="layout-container">
      {/* It will show the PwC Logo at first, but just when it starts to load, it will change to the loading view */}
      {isLoading ? (
        showLogoPreview ? (
          <div className="firm-logo">
            <PwCSVG />
          </div>
        ) : (
          <LoadingView />
        )
      ) : (
        // Two different layouts
        <>
          {/* Sidemenu layout */}
          {!logOut ? (
            <div
              className={`layout-sidemenu_container${
                showCustomModal.show ||
                showPopupStats.show ||
                isScreenLoading.state ||
                showErrorPopup
                  ? "--blurOn"
                  : ""
              }`}
            >
              <SideMenu />
            </div>
          ) : null}
          {/* Main layout */}
          <div
            className={`layout-children_container${
              showCustomModal.show ||
              showPopupStats.show ||
              isScreenLoading.state ||
              showErrorPopup
                ? "--blurOn"
                : ""
            }`}
            id="layout-children_container"
          >
            <GlobalHeader
              selectedItem={navItems
                .concat(newsItems)
                .find((element) => element.isSelected)}
            />
            <div className="layout-children_container__screen">{children}</div>
            <MainAppPopup />
          </div>
          {isScreenLoading.state && <LoadView texto={isScreenLoading?.text} />}
        </>
      )}
      {showErrorPopup && (
        <ErrorPopup onClose={() => setShowErrorPopup(false)} />
      )}
      <FullLayoutPopup />
    </div>
  );
};

export default Layout;
