import { useContext } from "react";
import "./newsItem.scss";
import { ScreenContext } from "../../context/screenContext";
import { ChevronSVG, EllipseSVG } from "../../styles/svg/sidemenuSVG";

/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.icon
 * @param {string} props.title
 * @param {string} props.subtitle
 * @param {() => void} props.onClickFunction
 * @param {boolean} props.isNew
 * @param {number} props.keyValue
 * @param {boolean} props.isSelected
 * @returns {React.ReactNode}
 */
const NewsItem = ({
  icon,
  title,
  subtitle,
  onClickFunction,
  isNew,
  keyValue,
  isSelected,
}) => {
  const { menuIsOpen } = useContext(ScreenContext);
  return (
    <div
      className={`news-item-container${menuIsOpen ? "" : "--small"} ${
        isSelected ? "selectedNewsItem" : ""
      }`}
    >
      {isNew && (
        <div className={`ellipse-container${menuIsOpen ? "" : "--small"}`}>
          <EllipseSVG />
        </div>
      )}
      {menuIsOpen ? (
        <div
          className="news-item-container__box"
          onClick={onClickFunction}
          key={keyValue}
        >
          {icon}
          <div className="news-item-container__box__texts">
            <p className="news-item-container__box__texts__title">{title}</p>
            <p className="news-item-container__box__texts__subtitle">
              {subtitle}
            </p>
          </div>
          <div className="news-item-container__box__chevron-container">
            <ChevronSVG />
          </div>
        </div>
      ) : (
        icon
      )}
    </div>
  );
};

export default NewsItem;
