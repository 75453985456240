import React, { useContext } from "react";
import { isOpenFromMobile } from "../../helpers/isMobile";
import { appInsightsTracker } from "../../helpers/AppInsights";
//Here we import the svg icons, that are in the sidemenuSVG file
import {
  ChatLineSVG,
  LeaguesSVG,
  RobotSVG,
  AcademySVG,
  AssistanceSVG,
  FormSVG,
  DigitalContactAppSVG,
} from "../../styles/svg/sidemenuSVG";
import { useLocation, useNavigate } from "react-router-dom";
import { ScreenContext } from "../../context/screenContext";
import { PwCLogoSVG } from "../../styles/assets/icons";
import AvatarButtonAction from "../../commons/chat-components/avatar-button-action/AvatarButtonAction";

const MenuActions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menuIsOpen, isChatLoading } = useContext(ScreenContext);

  const iconSize =
    document.documentElement.style.getPropertyValue("--iconSize");

  const handleOnClick = (url, typePage, pageName) => {
    navigate(url);
    appInsightsTracker.events("Click", typePage, pageName);
  };

  /* Items for the navigation bar.
   headerTitle: name of the header
   headerHelp: help text if needed .
   headerSubtitle: text under the title
   headerRight: component to render on the right part of the header
   topTitle: you can add a title above the item
   title: name of the item
   helpText: will give a text when we hover over the item
   icon: will use the ones imported in the sidemenuSVG
   onClickFunction: function that is triggered when you click on the item
   options: list of subitems that will appear as a dropdown menu 
   isSelected: if the item is selected by the user 
   */
  const navItems = [
    {
      headerTitle: "Digital Assistant",
      headerHelp:
        "En esta sección vas a poder tener un pantallazo de todo lo que sabe Digi de la firma, desde LoS hasta beneficios, preguntale lo que quieras saber.",
      headerSubtitle: isChatLoading ? (
        <p className="global-header-container__left__subtitle">
          Escribiendo...
        </p>
      ) : (
        <p className="global-header-container__left__subtitle">
          Powered by <PwCLogoSVG /> Acceleration Center
        </p>
      ),
      headerRight: <AvatarButtonAction />,
      topTitle: null,
      title: "Chatbot",
      helpText: "Escribile al chat",
      icon: <ChatLineSVG size={iconSize} />,
      onClickFunction: () => navigate("/chat"),
      isSelected: ["/chat"].includes(location.pathname),
    },
    {
      headerTitle: "Ligas",
      headerHelp:
        "Desafiate, recordá tus logros y entendé qué necesitás para llegar a donde querés en esta sección. Las acciones que realices con el asistente te traerán tesoros.",
      headerSubtitle: (
        <p className="global-header-container__left__subtitle">
          Powered by <PwCLogoSVG /> Acceleration Center
        </p>
      ),
      topTitle: null,
      title: "Ligas",
      helpText: null,
      icon: <LeaguesSVG size={iconSize} />,
      onClickFunction: null,
      options: [
        {
          title: "Mis puntos",
          icon: null,
          onClick: () =>
            handleOnClick("/my-points", "Opcion Secundaria", "Mis puntos"),
          helpText: "Mirá tus estadísticas",
          isSelected: ["/my-points"].includes(location.pathname),
        },
        {
          title: "Personalización",
          icon: null,
          onClick: () =>
            handleOnClick("/custom", "Opcion Secundaria", "Personalización"),
          helpText: "Editá tu avatar",
          isSelected: ["/custom"].includes(location.pathname),
        },
      ],
      isSelected: ["/my-points", "/leaderboard", "/custom"].includes(
        location.pathname
      ),
    },
    {
      headerTitle: "Asistencia",
      headerHelp:
        "Solicitá ayuda, información, avisa al equipo de desarrollo cuando algo no funcione desde reportes o simplemente dejá una propuesta de mejora.",
      headerSubtitle: (
        <p className="global-header-container__left__subtitle">
          Powered by <PwCLogoSVG /> Acceleration Center
        </p>
      ),
      topTitle: null,
      title: "Asistencia",
      helpText: null,
      icon: <AssistanceSVG size={iconSize} />,
      onClickFunction: null,
      options: [
        {
          title: "Reporte de problemas",
          icon: null,
          onClick: () =>
            handleOnClick("/bugs", "Opcion Secundaria", "Reporte de problemas"),
          helpText: "¿Tuviste algún problema con la aplicación?",
          isSelected: ["/bugs"].includes(location.pathname),
        },
        {
          title: "Dejanos tu propuesta",
          icon: null,
          onClick: () =>
            handleOnClick(
              "/functions",
              "Opcion Secundaria",
              "Dejanos tu propuesta"
            ),
          helpText: "Envíanos tus sugerencias",
          isSelected: ["/functions"].includes(location.pathname),
        },
      ],
      isSelected: ["/bugs", "/functions"].includes(location.pathname),
    },
    {
      headerTitle: "Formularios",
      headerHelp:
        "Completa formularios, los cuales pueden variar desde reintegros hasta reportes de problemas.",
      headerSubtitle: (
        <p className="global-header-container__left__subtitle">
          Powered by <PwCLogoSVG /> Acceleration Center
        </p>
      ),
      topTitle: null,
      title: "Formularios",
      helpText: null,
      icon: <FormSVG size={iconSize} />,
      onClickFunction: null,
      options: [
        {
          title: "Reintegro de Internet",
          icon: null,
          onClick: () =>
            handleOnClick(
              "/internet-form",
              "Opcion Secundaria",
              "Reintegro de Internet"
            ),
          helpText:
            "solicitar tu reintegro durante los periódos en los que está habilitado.",
          isSelected: ["/internet-form"].includes(location.pathname),
        },
        {
          title: "Incidente en la oficina",
          icon: null,
          onClick: () =>
            handleOnClick(
              "/issue-form",
              "Opcion Secundaria",
              "Incidente en la oficina"
            ),
          helpText: "Reportar algún problema que tengas en la oficina.",
          isSelected: ["/issue-form"].includes(location.pathname),
        },
      ],
      isSelected: ["/internet-form", "/issue-form"].includes(location.pathname),
    },
  ];
  if (!isOpenFromMobile()) {
    navItems.splice(1, 0, {
      headerTitle: "Digi Automation",
      headerHelp:
        "Acá podés explorar todas las automatizaciones disponibles para simplificar tus tareas diarias. Descarga las que necesites y Digi se encarga del resto😉",
      headerSubtitle: (
        <p className="global-header-container__left__subtitle">
          Powered by <PwCLogoSVG /> Acceleration Center
        </p>
      ),
      topTitle: null,
      title: "Automatizaciones",
      helpText: null,
      icon: <RobotSVG size={iconSize} />,
      onClickFunction: () => navigate("/automations"),
      isSelected: ["/automations"].includes(location.pathname),
    });
  }

  const newsItems = [
    {
      headerTitle: "Digital Contact App",
      headerHelp: null,
      headerSubtitle: (
        <p className="global-header-container__left__subtitle">
          Powered by <PwCLogoSVG /> Acceleration Center
        </p>
      ),
      icon: (
        <DigitalContactAppSVG
          width={menuIsOpen ? "43px" : iconSize}
          height={menuIsOpen ? "23px" : iconSize}
          onClickFunction={() =>
            handleOnClick("/digital-app", "Formulario", "Digital App")
          }
        />
      ),
      title: "Digital Contact App",
      subtitle:
        "Gestioná solicitudes, cambios, accesos y reportá bugs al Digital Team.",
      onClickFunction: () =>
        handleOnClick("/digital-app", "Formulario", "Digital App"),
      isNew: false,
      isSelected: ["/digital-app"].includes(location.pathname),
    },
    {
      headerTitle: "Virtual Digital Academy",
      headerHelp: null,
      headerSubtitle: (
        <p className="global-header-container__left__subtitle">
          Powered by <PwCLogoSVG /> Acceleration Center
        </p>
      ),
      icon: (
        <AcademySVG
          width={menuIsOpen ? "43px" : iconSize}
          height={menuIsOpen ? "23px" : iconSize}
          onClickFunction={() =>
            handleOnClick(
              "/virtual-digital-academy",
              "Formulario",
              "Virtual Digital Academy"
            )
          }
        />
      ),
      title: "Virtual Digital Academy",
      subtitle:
        "Conocé cuál es el estado de tu currícula y el de tus colegas acá.",
      onClickFunction: () =>
        handleOnClick(
          "/virtual-digital-academy",
          "Formulario",
          "Virtual Digital Academy"
        ),
      isNew: false,
      isSelected: ["/virtual-digital-academy"].includes(location.pathname),
    },
  ];

  return { navItems, newsItems };
};

export default MenuActions;
