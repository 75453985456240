import axios from "axios";

//Lógica para enviar solicitud al backend
const newsURL = `${process.env.REACT_APP_BACK_API}user/newsletter/update`;

const newsGetURL = `${process.env.REACT_APP_BACK_API}user/newsletter/get`;

export const sendToggleValue = async (mail, value, token) => {
  try {
    const response = await axios({
      method: "PUT",
      url: newsURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        mail,
        value,
      },
    });
    return response;
  } catch (error) {
    console.error("Error al enviar datos del backend: ", error);
  }
};

export const getToggleValue = async (mail, token) => {
  try {
    const response = await axios({
      method: "GET",
      url: newsGetURL,
      headers: {
        "Content-Type": "aplication/json",
        Authorization: `Bearer ${token}`,
      },

      params: {
        mail,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};
