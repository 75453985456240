function addAnswerInTempFormData(nameOfKey, jsObj, data, tempFormData) {
  //this functions checks whether to replace an answer in the state, add a new one or don´t change anything at all
  if (data.response.originalUserText.toLowerCase() !== "volver") {
    let index = tempFormData.findIndex(
      (item) => Object.keys(item).toString() === nameOfKey
    );
    var objEditedResponse;
    if (data.response?.currentlyEditingFromBack) {
      objEditedResponse = {
        camp: jsObj,
        index: index,
      };
    } else {
      if (index !== -1) {
        //replace object
        tempFormData.splice(index, 1, jsObj);
      } else {
        //new object
        tempFormData.push(jsObj);
      }
    }
    return objEditedResponse;
  }
}

module.exports = { addAnswerInTempFormData };
