// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  z-index: 100;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-container__text {
  text-align: center;
  padding: 0.5rem;
  font-size: calc(var(--internet-form-text) * 1.5);
  font-weight: 500;
  color: #ffffff;
  text-shadow: 1px 1px 2px black;
}`, "",{"version":3,"sources":["webpack://./src/views/Loading-view/loadingView.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AACF;AACE;EACE,kBAAA;EACA,eAAA;EACA,gDAAA;EACA,gBAAA;EACA,cAAA;EACA,8BAAA;AACJ","sourcesContent":[".loading-container {\n  z-index: 100;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 80%;\n  position: absolute;\n  top: 30%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n\n  &__text {\n    text-align: center;\n    padding: 0.5rem;\n    font-size: calc(var(--internet-form-text) * 1.5);\n    font-weight: 500;\n    color: #ffffff;\n    text-shadow: 1px 1px 2px black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
