// Here we add achievements sorted by type

// Question the user asks to the app
const questionsAchievements = [
  {
    type: "Resolviste 50 preguntas con el chatbot",
    score: 50 * 2.5,
    value: 50,
  },
  {
    type: "Resolviste 100 preguntas con el chatbot",
    score: 50 * 2.5,
    value: 100,
  },
  {
    type: "Resolviste 150 preguntas con el chatbot",
    score: 150 * 2.5,
    value: 150,
  },
  {
    type: "Resolviste 300 preguntas con el chatbot",
    score: 300 * 2.5,
    value: 300,
  },
  {
    type: "Resolviste 500 preguntas con el chatbot",
    score: 500 * 2.5,
    value: 500,
  },
  {
    type: "Resolviste 750 preguntas con el chatbot",
    score: 750 * 2.5,
    value: 750,
  },
  {
    type: "Resolviste 1000 preguntas con el chatbot",
    score: 1000 * 2.5,
    value: 1000,
  },
  {
    type: "Resolviste 1500 preguntas con el chatbot",
    score: 1500 * 2.5,
    value: 1500,
  },
];

// Amount of time the user is using the app
const timeAchievements = [
  { type: "Utilizaste la aplicación 30 minutos", score: 30, value: 30 },
  { type: "Utilizaste la aplicación 50 minutos", score: 50, value: 50 },
  { type: "Utilizaste la aplicación 100 minutos", score: 100, value: 100 },
  { type: "Utilizaste la aplicación 150 minutos", score: 150, value: 150 },
  { type: "Utilizaste la aplicación 300 minutos", score: 300, value: 300 },
  { type: "Utilizaste la aplicación 500 minutos", score: 500, value: 500 },
  { type: "Utilizaste la aplicación 750 minutos", score: 750, value: 750 },
  { type: "Utilizaste la aplicación 1000 minutos", score: 1000, value: 1000 },
  { type: "Utilizaste la aplicación 1500 minutos", score: 1500, value: 1500 },
];

// Amount of bug reported
const bugsAchievements = [
  { type: "Reportaste 1 bug", score: 5, value: 1 },
  { type: "Reportaste 3 bugs", score: 15, value: 3 },
  { type: "Reportaste 5 bugs", score: 25, value: 5 },
  { type: "Reportaste 10 bugs", score: 50, value: 10 },
  { type: "Reportaste 15 bugs", score: 75, value: 15 },
  { type: "Reportaste 25 bugs", score: 125, value: 25 },
  { type: "Reportaste 50 bugs", score: 250, value: 50 },
  { type: "Reportaste 75 bugs", score: 375, value: 75 },
  { type: "Reportaste 100 bugs", score: 500, value: 100 },
];

// To get the achievements completed and incompleted
const getAchievements = (statsUser) => {
  var completedAchievementsData = [];
  var incompletedAchievementsData = [];
  const questions = statsUser.numQuestions;
  const time = statsUser.timeSpent;
  const reported_bugs = statsUser.reportedBugs;

  //questions achievements
  questionsAchievements.map((element) => {
    if (questions >= element.value)
      return completedAchievementsData.push(element);
    else return incompletedAchievementsData.push(element);
  });

  //time achievements
  timeAchievements.map((element) => {
    if (time >= element.value) return completedAchievementsData.push(element);
    else return incompletedAchievementsData.push(element);
  });

  //reported_bugs achievements
  bugsAchievements.map((element) => {
    if (reported_bugs >= element.value)
      return completedAchievementsData.push(element);
    else return incompletedAchievementsData.push(element);
  });

  return {
    completed: completedAchievementsData,
    incompleted: incompletedAchievementsData,
  };
};

export default getAchievements;
