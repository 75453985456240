import { useContext } from "react";
import "./welcomeMessage.scss";
import { UserContext } from "../../../../context/userContext";
import { WelcomeMessageContext } from "../../../../context/welcomeMessageContext";
import cards from "./welcomeCardsContent";
import WelcomeCard from "./Cards/WelcomeCard";
import { Carousel } from "react-bootstrap";

//The whole welcome message component, this component is the grid
const WelcomeMessage = () => {
  const { user } = useContext(UserContext);
  const { showWelcomeMessage } = useContext(WelcomeMessageContext);
  const userName = user?.name?.split(" ")[0];
  return (
    <div
      className="welcome_message"
      hidden={showWelcomeMessage?.show || showWelcomeMessage?.refresh}
    >
      <h4 className="title">
        ¡Hola {userName}!🤖 Soy Digi, el nuevo asistente virtual del AC Buenos
        Aires
      </h4>
      {/* Responsive mobile version */}
      <Carousel className="carousel" slide={false}>
        {cards.map((content, i) => {
          return (
            <Carousel.Item key={i}>
              <WelcomeCard
                className="d-block"
                content={content}
                index={i}
                key={i}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
      {/* Responsive web version */}
      <div className="wrapper">
        {cards.map((content, i) => {
          return <WelcomeCard content={content} index={i} key={i} />;
        })}
      </div>
    </div>
  );
};

export default WelcomeMessage;
