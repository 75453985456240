import axios from "axios";

/**
 * @param {Object} props
 * @param {Object} props.values
 * @param {string} props.creatorMail
 * @param {application/pdf} props.archive
 * @param {string} props.declarationText
 * @param {string} props.values.file
 * @param {string} props.values.issueDate
 * @param {string} props.values.refundAmount
 * @param {string} props.values.payPeriod
 * @param {string} props.values.mail
 * @param {string} props.values.archive_upload
 * @returns {AxiosResponse<any, any>}
 */

export const postInternetForm = async ({
  creatorMail,
  values,
  archive,
  declarationText,
  token,
}) => {
  const formURL = `${process.env.REACT_APP_BACK_API}form/internet`;

  const { archive_upload, file, issueDate, refundAmount, payPeriod, mail } =
    values;

  const formData = new FormData();

  formData.append("mail", creatorMail);
  formData.append("file", file);
  formData.append("issueDate", issueDate);
  formData.append("refundAmount", refundAmount);
  formData.append("payPeriod", payPeriod);
  formData.append("recipientMail", mail);
  formData.append("archive", archive);
  formData.append("declaration", declarationText);
  formData.append("archiveName", archive_upload);
  // Axios post request to insert data into sql table
  try {
    const responseIdea = await axios({
      method: "POST",
      url: formURL,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    });

    return responseIdea;
  } catch (err) {
    console.log(err);
    return err;
  }
};
