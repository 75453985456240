import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronSVG, RobotSVG } from "../../styles/svg/sidemenuSVG";
import { PopupsContext } from "../../context/popupsContext";
import { BotsContext } from "../../context/botsContext";
import { SocketContext } from "../../context/socketContext";
import "./automationCard.scss";
import { IconInformationSVG } from "../../styles/assets/icons";
import { IconInfo } from "./IconInfo";

/**
 * Automation card for use in Digi Automation native app scope
 *
 * @param {object} props The automation properties
 * @param {string} props.title The title of the automation
 * @param {string} [props.subtitle] The automation's subtitle
 * @param {string} props.description A descriptive text of the automation
 * @param {string} props.tags A space-separated, hashtag-prefixed list of tags
 * @param {string} props.version The version string of the automation
 * @param {string} props.versionTimestamp The unix timestamp of the automation
 * @param {string} props.los The line of service scope of the automation
 * @param {string} props.downloaded Whether the automation is downloaded
 * @param {string} [props.type] The type of automation - either "soon" or "request"
 * @returns {React.ReactNode}
 */
const AutomationCard = ({
  title,
  subtitle,
  description,
  tags,
  version,
  versionTimestamp,
  los,
  downloaded,
  type,
}) => {
  const navigate = useNavigate();
  const { emitMessage } = useContext(SocketContext);
  const { setShowCustomModal } = useContext(PopupsContext);
  const { isDownloading, isExecuting, setIsDownloading, setIsExecuting } =
    useContext(BotsContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const [showIconInfo, setshowIconInfo] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const tooltip = tooltipRef.current;
      if (tooltip) {
        const tooltipHeight = tooltip.offsetHeight;
        tooltip.style.top = `calc(2rem + -${tooltipHeight + 43}px)`;
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showTooltip, isDownloading, isExecuting]);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const downloadBotButton = useCallback(() => {
    setIsDownloading(true);
    window.ipc.send("downloadBot", title);
  }, [title, setIsDownloading]);

  const openFolder = useCallback(() => {
    window.ipc.send("openFolder", title);
  }, [title]);

  const deleteBotButton = useCallback(() => {
    setShowCustomModal({
      show: true,
      message: {
        title: (
          <>
            <RobotSVG /> Eliminar automatización
          </>
        ),
        subtitle:
          "¿Estás seguro de que deseas eliminar ésta automatización de tu panel de descargas? Tené en cuenta que la podes volver a descargar desde el Hub.",
        firstButton: {
          title: "Cancelar",
          onClickFunction: () => setShowCustomModal({ show: false }),
        },
        secondButton: {
          title: <>&nbsp;&nbsp;&nbsp;Eliminar&nbsp;&nbsp;&nbsp;</>,
          onClickFunction: () => {
            setShowCustomModal({ show: false });
            window.ipc.send("deleteBot", title);
          },
        },
      },
    });
  }, [title, setShowCustomModal]);

  /**
   *
   * @param {string} versionDate A unix-timestamp number, stringified
   * @returns {string} The formatted day/month/year date
   */
  function setVersionDate(versionDate) {
    if (versionDate) {
      const newDate = new Date(Number(versionDate) * 1000);
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      const formattedDate = `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`;
      return formattedDate;
    }
    return "";
  }

  const moreInformationButton = useCallback(() => {
    setShowCustomModal({
      show: true,
      message: {
        title: (
          <>
            {title} <br />
            <p className="extra-info">
              Tags: {tags} | LoS: {los} | Version {version} | Updated Date:{" "}
              {setVersionDate(versionTimestamp)}
            </p>
          </>
        ),
        subtitle: description,
        tags: tags,
        firstButton: {
          title: "Cerrar",
          onClickFunction: () => setShowCustomModal({ show: false }),
        },
        secondButton: {
          title: <>&nbsp;&nbsp;&nbsp;Descargar&nbsp;&nbsp;&nbsp;</>,
          onClickFunction: () => {
            setShowCustomModal({ show: false });
            downloadBotButton();
          },
        },
      },
    });
  }, [
    title,
    description,
    tags,
    version,
    versionTimestamp,
    los,
    downloadBotButton,
    setShowCustomModal,
    isDownloading,
    isExecuting,
  ]);

  const runBotButton = useCallback(() => {
    setIsExecuting(true);
    window.ipc.send("runBot", title);
  }, [title, setIsExecuting]);

  return (
    <div
      className={`automation-card-container ${
        downloaded ? "downloaded" : type === "soon" ? "soon" : ""
      }`}
      style={{
        border: `${type === "request" ? "2px solid var(--tangerine)" : null}`,
      }}
    >
      <div className="automation-card-container__text-holder">
        <h1
          className={`automation-card-container__text-holder__title${
            type === "soon" ? "--soon" : ""
          }`}
        >
          {title}
          {!tags || tags.toLowerCase().includes("python") ? null : (
            <span
              className="automation-card-container__text-holder__title__icon-info"
              onMouseEnter={() => setshowIconInfo(true)}
              onMouseLeave={() => setshowIconInfo(false)}
            >
              <IconInformationSVG />
              {showIconInfo && <IconInfo />}
            </span>
          )}
        </h1>
        <p
          className={`automation-card-container__text-holder__subtitle${
            type === "soon" ? "--soon" : ""
          }`}
          style={
            !type ? { textDecoration: "underline", cursor: "pointer" } : null
          }
          onClick={() => {
            if (downloaded) openFolder();
            else if (!type) moreInformationButton();
          }}
          tabIndex={!type ? 0 : null}
        >
          {type && subtitle
            ? subtitle
            : downloaded
            ? "Ver ubicación de la automatización"
            : "Más información"}
        </p>
      </div>
      <div className="automation-card-container__button-holder">
        {type === "request" ? (
          <button
            className="first-button"
            onClick={() => {
              navigate("/chat");
              const name = JSON.parse(sessionStorage.getItem("userData")).name;
              emitMessage("chat", {
                name: name,
                option: "Forms",
              });
              setTimeout(() => {
                emitMessage("chat", {
                  name: name,
                  option: "Idea Form",
                });
              }, 2000);
            }}
          >
            Pedila acá
          </button>
        ) : type === "soon" ? null : downloaded ? (
          <>
            <div className="tooltip-and-button">
              {showTooltip && (isExecuting || isDownloading) && (
                <div
                  className="automation-card-container__button-holder__tooltip"
                  ref={tooltipRef}
                >
                  {isExecuting
                    ? "Tené en cuenta que solo podés ejecutar una automatización a la vez."
                    : "Podrás ejecutar esta automatización una vez que finalice la descarga."}
                </div>
              )}
              {tags.toLowerCase().includes("python") && (
                <button
                  className={`first-button${
                    isDownloading || isExecuting ? "--disabled" : ""
                  }`}
                  onClick={() => {
                    if (!isDownloading && !isExecuting) runBotButton();
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Ejecutar <ChevronSVG />
                </button>
              )}
            </div>
            <button
              className={`second-button${isExecuting ? "--disabled" : ""}`}
              onClick={() => {
                if (!isExecuting) deleteBotButton();
              }}
            >
              Eliminar
            </button>
          </>
        ) : (
          <div className="tooltip-and-button">
            {showTooltip && (isExecuting || isDownloading) && (
              <div
                className="automation-card-container__button-holder__tooltip"
                ref={tooltipRef}
              >
                {isDownloading
                  ? "Tené en cuenta que solo podés descargar una automatización a la vez."
                  : "Para descargar, no debés tener ningúna automatización ejecutándose."}
              </div>
            )}
            <button
              className={`first-button${
                isDownloading || isExecuting ? "--disabled" : ""
              }`}
              onClick={() => {
                if (!isDownloading && !isExecuting) downloadBotButton();
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Descargar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationCard;
