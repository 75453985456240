import { useContext, useEffect } from "react";
import { ScreenContext } from "../../../context/screenContext";
import { appInsightsTracker } from "../../../helpers/AppInsights";
import "../paView.scss";
import { SessionContext } from "../../../context/sessionContext";
import { UserContext } from "../../../context/userContext";
import { deleteToken } from "../../../services/login/loginService";

const VdaView = () => {
  const { theme } = useContext(ScreenContext);
  const {
    activeSession,
    startTime,
    setStartTime,
    activeSessionAppInsights,
    setActiveSessionAppInsights,
  } = useContext(SessionContext);
  const {apiToken} = useContext(UserContext)
  useEffect(() => {
    appInsightsTracker.pageView("Virtual Digital Academy");
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    appInsightsTracker.logScreenTime(
      "Virtual Digital Academy",
      startTime,
      activeSessionAppInsights
    );
    deleteToken(apiToken);
  };

  //On view load, setting the start time and sends metric
  useEffect(() => {
    setStartTime(new Date());

    return () => {
      appInsightsTracker.logScreenTime(
        "Virtual Digital Academy",
        startTime,
        activeSessionAppInsights
      );
    };
  }, []);

  //Creates an eventListener for closing app. The old event listener must be removed when the session is not active, to make a new one with the changed variable.
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      return;
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [activeSession.isActive, activeSessionAppInsights]);

  //Send time metric on session close, and change variable of active session for the metric
  useEffect(() => {
    if (!activeSession.isActive && activeSessionAppInsights) {
      appInsightsTracker.logScreenTime(
        "Virtual Digital Academy",
        startTime,
        activeSessionAppInsights
      );
      setActiveSessionAppInsights(false);
      setStartTime(new Date());
    }
  }, [activeSession.isActive]);

  return (
    <div className="pa-container">
      <iframe
        src={`https://apps.powerapps.com/play/e/396dffe1-f0b9-eb9e-ad94-2cc9b1957807/a/602321cb-d669-410c-9ec6-2dfdbda2ef6d?tenantId=513294a0-3e20-41b2-a970-6d30bf1546fa&hint=dd9e853e-ed23-4832-86bc-a1821636b7a4&sourcetime=1714142057932&source=portal&hidenavbar=true&Theme=${theme.theme}`}
        width="99%"
        height="99%"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Virtual Digital Academy App"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default VdaView;
