import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./views/Layout/Layout";

/* Views */
import ChatView from "./views/Chat-view/ChatView";
import BugsView from "./views/Bugs-view/BugsView";
import CustomView from "./views/Custom-view/CustomView";
import ErrorView from "./views/Error-view/ErrorView";
import FunctionsView from "./views/Functions-view/FunctionsView";
import AutomationsView from "./views/Automations-view/AutomationsView";

/* Context provider */
import UserProvider from "./context/userContext";
import FormProvider from "./context/formContext";
import SocketProvider from "./context/socketContext";
import ScreenProvider from "./context/screenContext";
import PopupsProvider from "./context/popupsContext";
import SessionProvider from "./context/sessionContext";
import WelcomeMessageProvider from "./context/welcomeMessageContext";

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./helpers/AppInsights";
import { isOpenFromMobile } from "./helpers/isMobile";
import FormInternetView from "./views/Form-view/FormInternetView";
import FormIssueView from "./views/Form-view/FormIssueView";
// import VdaView from "./views/Vda-view/VdaView";
import VdaView from "./views/PowerApp/Vda-view/VdaView";
import DigitalAppView from "./views/PowerApp/DigitalApp-view/DigitalAppView";
import MyPointsView from "./views/My-points-view/MyPointsView";
import BotsProvider from "./context/botsContext";
import LogOutView from "./views/LogOut-view/LogOutView";

const App = () => {
  useEffect(() => {
    if (
      window.location.pathname?.length > 1 &&
      window.location.pathname !== "/login"
    )
      localStorage.setItem(
        "uri",
        window.location.pathname + window.location.search
      );
  });

  return (
    <UserProvider>
      <SessionProvider>
        <SocketProvider>
          <ScreenProvider>
            <FormProvider>
              <PopupsProvider>
                <WelcomeMessageProvider>
                  <BotsProvider>
                    <Layout>
                      <Routes>
                        {/* Views paths */}
                        <Route path="/chat" element={<ChatView />} />
                        <Route path="/bugs" element={<BugsView />} />
                        <Route path="/custom" element={<CustomView />} />
                        <Route path="/my-points" element={<MyPointsView />} />
                        <Route path="/functions" element={<FunctionsView />} />
                        <Route
                          path="/internet-form"
                          element={<FormInternetView />}
                        />
                        <Route path="/issue-form" element={<FormIssueView />} />
                        {!isOpenFromMobile() && (
                          <Route
                            path="/automations"
                            element={<AutomationsView />}
                          />
                        )}
                        <Route
                          path="/virtual-digital-academy"
                          element={<VdaView />}
                        />
                        <Route
                          path="/digital-app"
                          element={<DigitalAppView />}
                        />
                        <Route path="/logout" element={<LogOutView />} />
                        <Route path="/" element={<Navigate to="/chat" />} />
                        <Route
                          path="/error"
                          element={<ErrorView errorType={"server-error"} />}
                        />
                        <Route
                          path="*"
                          element={<ErrorView errorType={"not-found-error"} />}
                        />
                      </Routes>
                    </Layout>
                  </BotsProvider>
                </WelcomeMessageProvider>
              </PopupsProvider>
            </FormProvider>
          </ScreenProvider>
        </SocketProvider>
      </SessionProvider>
    </UserProvider>
  );
};
export default withAITracking(reactPlugin, App);
