import { createContext, useState } from "react";
export const FormContext = createContext(null);
const { Provider } = FormContext;

const FormProvider = ({ children }) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const [issueFormOptions, setIssueFormOptions] = useState({
    incidentesIdentificados: [],
    recursosIdentificados: [],
  });

  const handleToggleDropdown = (id) => {
    setOpenDropdown((prev) => (prev === id ? null : id));
  };

  const handleCloseDropdown = (id) => {
    if (openDropdown === id) {
      setOpenDropdown(null);
    }
  };

  return (
    <Provider
      value={{
        openDropdown,
        setOpenDropdown,
        handleToggleDropdown,
        handleCloseDropdown,
        issueFormOptions,
        setIssueFormOptions,
      }}
    >
      {children}
    </Provider>
  );
};

export default FormProvider;
