//IDENTITY ENDPOINTS
export const authorizationURL =
  process.env.REACT_APP_AUTHORIZATION_ENDPOINT_URL;
export const accessTokenURL = process.env.REACT_APP_ACCESS_TOKEN_ENDPOINT_URL;
export const userInfoURL = process.env.REACT_APP_USER_INFO_ENDPOINT_URL;

//REDIRECT_URI MAY CHANGE ACCORDING TO THE ENVIRONMENT
export const redirect_uri = process.env.REACT_APP_REDIRECT_URI;

//APP CREDENTIALS
export const authCredentials = {
  client_id: process.env.REACT_APP_CLIENT_ID,
};

//DOT API ENDPOINTS
export const dotApiURL = process.env.REACT_APP_DOT_API_URI;
