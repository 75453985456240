export const OptionsSelectBugsReport = [
  {
    id: 0,
    title: "Problema de contenido",
    key: "categoria",
  },
  {
    id: 1,
    title: "Los links no funcionan",
    key: "Problema de contenido",
  },
  {
    id: 2,
    title: "La información está desactualizada",
    key: "Problema de contenido",
  },
  {
    id: 3,
    title: "No encontré lo que estaba buscando",
    key: "Problema de contenido",
  },
  {
    id: 4,
    title: "Falta información",
    key: "Problema de contenido",
  },
  {
    id: 5,
    title: "Respuesta fuera de contexto",
    key: "Problema de contenido",
  },
  {
    id: 6,
    title: "Problema con la aplicación",
    key: "categoria",
  },
  {
    id: 7,
    title: "Espera prolongada/carga lenta",
    key: "Problema con la aplicación",
  },
  {
    id: 8,
    title: "Tengo problemas para acceder al menú de información",
    key: "Problema con la aplicación",
  },
  {
    id: 9,
    title: "Comportamiento inesperado",
    key: "Problema con la aplicación",
  },
  {
    id: 10,
    title: "No puedo customizar el avatar",
    key: "Problema con la aplicación",
  },
  {
    id: 11,
    title: "Problema con una automatización",
    key: "Problema con la aplicación",
  },
];
