import { authCredentials, redirect_uri, authorizationURL } from "./constants";

// Credentials for the login in the app
export const getAccessCodes = (appState) => {
  const { client_id } = authCredentials;
  const storage = window.sessionStorage;

  const data = {
    response_type: "code",
    state: appState,
    client_id,
    redirect_uri,
    scope: "openid email profile cloudEmail LOS upwcjobtitle role",
  };
  const params = new URLSearchParams(data);

  if (!storage.getItem("isExecuted")) {
    storage.setItem("isExecuted", true);
    storage.setItem("state", appState);
    window.location.href = `${authorizationURL}?${params}`;
  }
};
