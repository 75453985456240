import axios from "axios";
const bugURL = `${process.env.REACT_APP_BACK_API}bugs/new`;
// Axios post request to save the bug form data from BugsView
export const postBug = async ({ values, archive, historyChat, token }) => {
  const formData = new FormData();
  const { email, bug_report, help, category, category_item } = values;

  formData.append("email", email);
  formData.append("bug_report", bug_report);
  formData.append("help", help);
  formData.append("category", category);
  formData.append("category_item", category_item);
  formData.append("image", archive);
  // We will be saving the chat history to verify how the talk went.
  formData.append("historyChat", JSON.stringify(historyChat));

  try {
    const responseBug = await axios({
      method: "POST",
      url: bugURL,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    });

    return responseBug;
  } catch (err) {
    if (err) {
    }
    return err;
  }
};
