import { useEffect, useState } from "react";
import ChallengeList from "./ChallengeList";
import "./challenges.scss";
import getAchievements from "./getAchievements";

const Challenges = ({ dataStats }) => {
  const [completedAchievementsData, setCompletedAchievementsArray] = useState(
    []
  );
  const [incompletedAchievementsData, setIncompletedAchievementsArray] =
    useState([]);

  useEffect(() => {
    let achievementsData = [];
    for (let i = 0; i < 6; i++) {
      achievementsData.push({
        type: "Desafío por desbloquear",
        score: 500,
        mockup: true,
      });
    }
    setIncompletedAchievementsArray(achievementsData);
    achievementsData = [];
    for (let i = 0; i < 6; i++) {
      achievementsData.push({
        type: "Desafío desbloqueado",
        score: 100,
        mockup: true,
      });
    }
    setCompletedAchievementsArray(achievementsData);
  }, []);

  useEffect(() => {
    //we replace the mockups with the real values
    if (dataStats.userStats !== undefined) {
      const { completed, incompleted } = getAchievements(dataStats.userStats);
      setCompletedAchievementsArray(completed);
      setIncompletedAchievementsArray(incompleted);
    }
  }, [dataStats]);

  return (
    <div className="challenges-container">
      <div className="challenges-container__first-column">
        <ChallengeList
          title={"Desafíos en progreso"}
          dataList={incompletedAchievementsData}
          type={"in progress"}
        />
      </div>
      <div className="challenges-container__second-column">
        <ChallengeList
          title={"Desafíos logrados"}
          dataList={completedAchievementsData}
          type={"accomplished"}
        />
      </div>
    </div>
  );
};

export default Challenges;
