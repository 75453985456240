import { useState, useEffect, useRef } from "react";
import "./dropdownInput.scss";
import { ArrowSVG } from "../../styles/assets/icons";

/**
 *
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.name
 * @param {string} [props.value]
 * @param {string[]} props.valuesArray
 * @param {string} [props.defaultValue]
 * @param {boolean} props.isRequired
 * @param {(option: string) => void} props.handleChange
 * @param {() => void} props.handleClick
 * @param {boolean} props.isOpen
 * @param {(id: string) => void} props.handleToggleDropdown
 * @param {(id: string) => void} props.handleCloseDropdown
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.showError]
 * @param {string} [props.errorMessage]
 * @returns {React.ReactNode}
 */

const DropdownInput = ({
  id,
  name,
  value,
  valuesArray,
  defaultValue,
  isRequired,
  handleChange,
  handleClick,
  isOpen,
  handleToggleDropdown,
  handleCloseDropdown,
  disabled,
  showError,
  errorMessage,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    defaultValue !== undefined && handleOptionClick(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    value ? setSelectedValue(value) : setSelectedValue("");
  }, [value]);

  /**
   *
   * @param {string} option
   */

  const handleOptionClick = (option) => {
    setSelectedValue(option);
    handleChange(option);
    handleCloseDropdown(id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleCloseDropdown(id);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, handleCloseDropdown, id]);

  return (
    <div className="custom-dropdown-container" ref={dropdownRef}>
      <label
        htmlFor=""
        className={`custom-dropdown-label ${disabled ? "disabled" : ""}`}
      >
        {name} {isRequired && <span className="required">&nbsp;*</span>}
      </label>
      <div
        className={`custom-dropdown${showError ? "--error" : ""} ${
          isOpen ? "open" : ""
        }`}
      >
        <div
          className={`selected-value ${!showError && isOpen ? "active" : ""} ${
            selectedValue?.length === 0 ? "placeholder" : ""
          } ${disabled ? "disabled" : ""}`}
          onClick={() => {
            !disabled && handleToggleDropdown(id);
            handleClick();
          }}
        >
          {selectedValue || placeholder || name}
          <div className="container-arrow">
            {isOpen ? (
              <ArrowSVG direction="up" color="#828282" strokeWidth="3" />
            ) : (
              <ArrowSVG direction="down" color="#828282" strokeWidth="3" />
            )}
          </div>
        </div>
        {isOpen && (
          <ul className="dropdown-options">
            {valuesArray.map((option) => (
              <li key={option} onClick={() => handleOptionClick(option)}>
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
      <label htmlFor="" className="custom-dropdown-label-error">
        &nbsp;
        {showError && errorMessage ? errorMessage : ""}
      </label>
    </div>
  );
};

export default DropdownInput;
