import WelcomeDigi from "../../../../styles/svg/welcomeSVG";
import OptionsMessage from "../OptionsMessage/OptionsMessage";
const cards = [
  [
    {
      element: (
        <>
          <p className="subtitle">¿Qué aprendí este mes?</p>
        </>
      ),
    },
    {
      element: (
        <p className="paragraph">
          Hace poco charlé con el equipo de{" "}
          <span className="marked">Infraestructura</span>, conocé las últimas
          noticias acá
        </p>
      ),
    },
    {
      element: (
        <WelcomeDigi rotation="175.79 deg" mobile={window.screen.width < 800} />
      ),
    },
    {
      element: (
        <p className="paragraph">
          Ahora podés preguntarme sobre algún{" "}
          <span className="marked">gasto</span> que hayas hecho y quieras{" "}
          <span className="marked">reintegrar</span>.
        </p>
      ),
    },
  ],
  [
    {
      element: (
        <>
          <p className="subtitle">Te puedo ayudar con:</p>
        </>
      ),
    },
    {
      element: (
        <>
          <p className="paragraph">
            Ahora podés descargarte la versión desktop{" "}
            <mark className="new">New</mark>
          </p>
        </>
      ),
    },
    {
      element: (
        <p className="paragraph">
          También podés completar el form de reintegro de internet
        </p>
      ),
    },
    {
      element: (
        <p className="paragraph">
          Descubrí si completaste el curso de Virtual Digital Academy
        </p>
      ),
    },
  ],
  [
    {
      element: (
        <>
          <p className="subtitle">New Joiner</p>
        </>
      ),
    },
    {
      element: (
        <p className="paragraph">
          Encontrá información en el siguiente hot topic.
        </p>
      ),
    },
    {
      element: (
        <OptionsMessage
          options={["OnBoarding"]}
          containerDirection={"horizontal"}
          permanent={true}
          idName="buttonShow"
        />
      ),
    },
    {
      element: (
        <p className="paragraph">
          Podrás conseguir información útil para tus primeros días en la firma
          😉.
        </p>
      ),
    },
  ],
  [
    {
      element: (
        <>
          <p className="subtitle">#Tipsdeuso</p>
        </>
      ),
    },
    {
      element: (
        <div className="showRow">
          <button className="options__button" id="buttonShow" disabled>
            Hot Topic ilustrativo
          </button>{" "}
          <p className="paragraph">
            Podés encontrar shortcuts a las distintos equipos y temas de interés
          </p>
        </div>
      ),
    },
    { element: <hr /> },
    {
      element: (
        <p className="paragraph">
          Si ya sabés lo que estás buscando, probá escribiendo. Por ejemplo
          'Beneficios'
        </p>
      ),
    },
    {
      element: (
        <div
          className="input-deck__input-container"
          style={{ width: "100%", marginTop: "0" }}
          disabled
        >
          <textarea
            disabled
            className="input-deck__text"
            type="text"
            name="chat"
            id="toShow"
            value={"Beneficios"}
            style={{ width: "100%" }}
          ></textarea>
          <button
            className="input-deck__button"
            type="submit"
            disabled
            id="toShow"
            style={{ width: "100%" }}
          >
            <span className="input-deck__button" disabled id="toShow">
              Enviar{" "}
            </span>
          </button>
        </div>
      ),
    },
  ],
];

export default cards;
