export function getAvailableEditOptions(formData, currentlyEditing) {
  //we set the right answers for the edit page : any answer from the user must be equal to one
  //of the elements of the state we are about to make
  var optionsForEditing = [];
  if (currentlyEditing) {
    function filterUndefined(e) {
      if (e !== "ined") return true;
      return false;
    }
    //next one is with checkbox description-have to strip that prefix
    var optionsForEditingWitPrefixDescription = formData
      .map((ele) => Object.keys(ele).toString().slice(5))
      .filter(filterUndefined);

    function searchForSlash(element) {
      return element.includes("-");
    }
    var positionOfDescriptionPrefix =
      optionsForEditingWitPrefixDescription.findIndex(searchForSlash);
    if (positionOfDescriptionPrefix !== -1) {
      optionsForEditingWitPrefixDescription[positionOfDescriptionPrefix] =
        optionsForEditingWitPrefixDescription[
          positionOfDescriptionPrefix
        ].split("-")[1];
    }
    optionsForEditing = optionsForEditingWitPrefixDescription;
  }

  return optionsForEditing;
}
