// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  position: absolute;
  top: 0;
  transform: translate(-45%, 0%);
  width: auto;
  z-index: 100;
}
.notification__elementsContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0.625rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: 0.4375rem;
  font-size: 1.8rem;
  line-height: 5vh;
}
.notification__elementsContainer * {
  margin-left: 0.625rem;
}
.notification__elementsContainer span {
  font-weight: 600;
}
.notification__elementsContainer.--success {
  background: linear-gradient(90.96deg, rgba(23, 93, 45, 0.32) 21.62%, rgba(23, 93, 45, 0.16) 78%);
}
.notification__elementsContainer.--error {
  background: linear-gradient(90.96deg, rgba(231, 43, 45, 0.32) 21.62%, rgba(231, 43, 45, 0.16) 78%);
}

@media (max-width: 768px) {
  .notification__elementsContainer {
    font-size: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/commons/notifications/notification.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,iBAAA;EACA,2CAAA;EACA,mCAAA;UAAA,2BAAA;EACA,wBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AAEI;EACE,qBAAA;AAAN;AAGI;EACE,gBAAA;AADN;AAMI;EACE,gGAAA;AAJN;AAWI;EACE,kGAAA;AATN;;AAkBA;EAEI;IACE,eAAA;EAhBJ;AACF","sourcesContent":[".notification {\n  position: absolute;\n  top: 0;\n  transform: translate(-45%, 0%);\n  width: auto;\n  z-index: 100;\n\n  &__elementsContainer {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: fit-content;\n    padding: 0.625rem;\n    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);\n    backdrop-filter: blur(20px);\n    border-radius: 0.4375rem;\n    font-size: 1.8rem;\n    line-height: 5vh;\n\n    //all descending elements:text,img\n    * {\n      margin-left: 0.625rem;\n    }\n\n    span {\n      font-weight: 600;\n    }\n\n    //different type of messages\n    //with glass effect (CONCEPT)\n    &.--success {\n      background: linear-gradient(\n        90.96deg,\n        rgba(23, 93, 45, 0.32) 21.62%,\n        rgba(23, 93, 45, 0.16) 78%\n      );\n    }\n\n    &.--error {\n      background: linear-gradient(\n        90.96deg,\n        rgba(231, 43, 45, 0.32) 21.62%,\n        rgba(231, 43, 45, 0.16) 78%\n      );\n    }\n  }\n}\n\n@media (max-width: 768px) {\n  .notification {\n    &__elementsContainer {\n      font-size: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
