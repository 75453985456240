// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-theme=light] {
  --background-color-icon-info: #FFFFFF;
  --color-background-icon-info: #575757;
  --color-font-icon-container: ;
}

body[data-theme=dark] {
  --background-color-icon-info: #464646;
  --color-background-icon-info: #FFFFFF;
}

.message-icon-info {
  background-color: var(--background-color-icon-info);
  border-radius: 0.75rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color: var(--color-background-icon-info);
  font-weight: 400;
  font-family: "PwC Helvetica Neue";
  font-size: calc(var(--globalHeaderText) * 0.55);
  padding: 1rem;
  max-width: 23rem;
  min-width: 12rem;
  left: 100%;
  position: absolute;
  z-index: 1;
  margin-top: -1rem;
}`, "",{"version":3,"sources":["webpack://./src/commons/automation-card/iconInfo.scss"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,qCAAA;EACA,6BAAA;AACJ;;AAEA;EACI,qCAAA;EACA,qCAAA;AACJ;;AAEA;EACI,mDAAA;EACA,sBAAA;EACA,uCAAA;EACA,wCAAA;EACA,gBAAA;EACA,iCAAA;EACA,+CAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,UAAA;EACA,iBAAA;AACJ","sourcesContent":["body[data-theme=\"light\"] {\n    --background-color-icon-info: #FFFFFF;\n    --color-background-icon-info: #575757;\n    --color-font-icon-container:\n}\n\nbody[data-theme=\"dark\"] {\n    --background-color-icon-info: #464646;\n    --color-background-icon-info: #FFFFFF;\n}\n\n.message-icon-info {\n    background-color: var(--background-color-icon-info);\n    border-radius: 0.75rem;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n    color: var(--color-background-icon-info);\n    font-weight: 400;\n    font-family: \"PwC Helvetica Neue\";\n    font-size: calc(var(--globalHeaderText) * 0.55);\n    padding: 1rem;\n    max-width: 23rem;\n    min-width: 12rem;\n    left: 100%;\n    position: absolute;\n    z-index: 1;\n    margin-top: -1rem;\n  } \n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
