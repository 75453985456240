import { useContext } from "react";
import "./confirmationPopup.scss";
import ConfirmationResetChat from "./confirmation-reset-chat/ConfirmationResetChat";
import ConfirmationLogOut from "./confirmation-log-out/ConfirmationLogOut";
import Notification from "../notifications/Notification";
import { PopupsContext } from "../../context/popupsContext";

// When we try to reset the chat, a pop up will display to confirm
const ConfirmationPopup = () => {
  const { showConfirmationPopup } = useContext(PopupsContext);

  switch (showConfirmationPopup.show) {
    case "resetChat" || "other":
      return <ConfirmationResetChat />;
    case "success" || "error":
      return <Notification />;
    case "logOut":
      return <ConfirmationLogOut />;
    default:
      return null;
  }
};

export default ConfirmationPopup;
