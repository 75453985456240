// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../styles/assets/backgroundSVGsDigi/BackgroundFogLight.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../styles/assets/backgroundSVGsDigi/BackgroundFogDark.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-theme=light] {
  --background-color: #ffffff;
  --background-svg: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  --color-title: #949494;
}

body[data-theme=dark] {
  --background-color: var(--darkerGray);
  --background-svg: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  --color-title: #656565;
}

.log-out-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  background-image: var(--background-svg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  overflow-y: auto;
}
.log-out-title {
  color: #7d7d7d;
}`, "",{"version":3,"sources":["webpack://./src/views/LogOut-view/logOutView.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,yDAAA;EACA,sBAAA;AACJ;;AAEA;EACI,qCAAA;EACA,yDAAA;EACA,sBAAA;AACJ;;AAGI;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,yCAAA;EACA,uCAAA;EACA,4BAAA;EACA,4BAAA;EACA,2BAAA;EACA,gBAAA;AAAR;AAEI;EACI,cAAA;AAAR","sourcesContent":["body[data-theme=\"light\"] {\n    --background-color: #ffffff;\n    --background-svg: url(\"../../styles/assets/backgroundSVGsDigi/BackgroundFogLight.svg\");\n    --color-title: #949494;\n}\n\nbody[data-theme=\"dark\"] {\n    --background-color: var(--darkerGray);\n    --background-svg: url(\"../../styles/assets/backgroundSVGsDigi/BackgroundFogDark.svg\");\n    --color-title: #656565;\n}\n\n.log-out{\n    &-container{\n        height: 100%;\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        background-color: var(--background-color);\n        background-image: var(--background-svg);\n        background-repeat: no-repeat;\n        background-attachment: fixed;\n        background-position: center;\n        overflow-y: auto;\n    }\n    &-title{\n        color: #7d7d7d;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
