// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.digi-avatar-reset-container {
  display: flex;
  align-items: center;
}
.digi-avatar-reset-container__reset-wrapper {
  display: flex;
  align-items: center;
}
.digi-avatar-reset-container__reset-wrapper__text {
  margin-right: 0.5rem;
  cursor: pointer;
  font-size: calc(var(--globalHeaderText) * 0.55);
  color: #7d7d7d;
}
.digi-avatar-reset-container__reset-wrapper .reset-icon {
  margin-right: 1.2rem;
}

@media screen and (max-width: 576px) {
  :root {
    --globalHeaderText: 0.8rem;
  }
  .digi-avatar-reset-container__reset-wrapper .reset-icon {
    width: 13.01px;
    height: 11px;
    margin-right: 0.8rem;
  }
  .digi-avatar-reset-container__reset-wrapper__text {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/commons/chat-components/avatar-button-action/avatarButtonAction.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AACI;EACE,oBAAA;EACA,eAAA;EACA,+CAAA;EACA,cAAA;AACN;AAEI;EACE,oBAAA;AAAN;;AAKA;EACE;IACE,0BAAA;EAFF;EAOI;IACE,cAAA;IACA,YAAA;IACA,oBAAA;EALN;EAQI;IACE,aAAA;EANN;AACF","sourcesContent":[".digi-avatar-reset-container {\n  display: flex;\n  align-items: center;\n\n  &__reset-wrapper {\n    display: flex;\n    align-items: center;\n\n    &__text {\n      margin-right: 0.5rem;\n      cursor: pointer;\n      font-size: calc(var(--globalHeaderText) * 0.55);\n      color: #7d7d7d;\n    }\n\n    .reset-icon {\n      margin-right: 1.2rem;\n    }\n  }\n}\n\n@media screen and (max-width: 576px) {\n  :root {\n    --globalHeaderText: 0.8rem;\n  }\n\n  .digi-avatar-reset-container {\n    &__reset-wrapper {\n      .reset-icon {\n        width: 13.01px;\n        height: 11px;\n        margin-right: 0.8rem;\n      }\n\n      &__text {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
