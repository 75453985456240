import Cookie from "js-cookie";

const SetCookie = (cookiename, dateLastNps) => {
  Cookie.set(cookiename, dateLastNps, {
    expires: 30,
    secure: true,
    sameSite: "strict",
    path: "/",
  });
};

const GetCookie = (cookiename) => {
  return Cookie.get(cookiename);
};

const RemoveCookie = (cookiename) => {
  return Cookie.remove(cookiename);
};

export { SetCookie, GetCookie, RemoveCookie };
