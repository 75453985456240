import React, { useState, useEffect, useContext } from "react";
import { getStatsAll } from "../../../../services/userStats/statsService";
import { PopupsContext } from "../../../../context/popupsContext";
import scorePoints from "../../../../helpers/levels/points";
import { ScreenContext } from "../../../../context/screenContext";
import { UserContext } from "../../../../context/userContext";
import "./rankingLeaderboard.scss";

/**
 *
 * @param {object} props
 * @param {boolean} props.isAvailable
 * @returns {React.ReactNode}
 */
const RankingLeaderboard = ({ isAvailable }) => {
  const [userData, setUserData] = useState();
  const [statsAll, setStatsAll] = useState([]);
  const [isLoading, setIsLoading] = useState(isAvailable ? true : false);

  const { setShowPopupStats } = useContext(PopupsContext);
  const { apiToken } = useContext(UserContext);
  const { windowSize } = useContext(ScreenContext);

  const leadersboardContainerClass = isLoading
    ? "leadersboard-container leadersboard-container--blurOn"
    : "leadersboard-container";

  const handleClick = (stats, myStats) => {
    setShowPopupStats({
      show: true,
      stats,
      isMonthly: true,
      isExternal: !myStats,
    });
  };

  useEffect(() => {
    //we need the user data first
    setUserData(JSON.parse(sessionStorage.getItem("userData")));
  }, []);

  useEffect(() => {
    const getStats = async () => {
      try {
        setIsLoading(true);
        //get both list of leaders
        await Promise.all([getStatsAll(userData?.email, apiToken)]).then(
          (values) => {
            setStatsAll(values[0].data.response);
          }
        );
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (userData !== undefined && isAvailable) {
      getStats();
    } else {
      const dayText =
        new Date().getDate() === 3
          ? "1 día"
          : `${new Date().getDate() === 2 ? "2" : "3"} días`;
      let unavailableStats = [];
      for (let i = 0; i < 10; i++) {
        unavailableStats.push({
          userInfo: { name: `Disponible en ${dayText}`, disabledClick: true },
          userStats: { position: i + 1, points: -1 },
        });
      }
      setStatsAll(unavailableStats);
    }
  }, [userData, isAvailable]);

  return (
    <div className={leadersboardContainerClass}>
      {/* Score table for all AC users */}
      <div className="window-leadersboard__individual-table window-leadersboard__individual-table--ac">
        <div className="window__table-name">
          {windowSize[0] > 768
            ? "Los usuarios más activos de este mes - Acceleration Center Buenos Aires"
            : "Top usuarios - AC Buenos Aires"}
        </div>
        <div className="window__table-content">
          {statsAll.map((element, index) => {
            return (
              <div
                key={index}
                className={
                  index === 0 && isAvailable
                    ? "window-leadersboard__row window-leadersboard__row--first-position"
                    : userData.name === element.userInfo.name
                    ? "window-leadersboard__row window-leadersboard__row--user"
                    : isAvailable
                    ? "window-leadersboard__row"
                    : " unavailable-stat-row"
                }
                onClick={() => {
                  if (!element.userInfo.disabledClick)
                    handleClick(
                      element,
                      userData.name === element.userInfo.name
                    );
                }}
              >
                <div
                  className={
                    index === 0
                      ? `window-identifier window__identifier--first-position${
                          isAvailable ? "" : " unavailable-stat-text"
                        }`
                      : `window__identifier${
                          isAvailable ? "" : " unavailable-stat-text"
                        }`
                  }
                >
                  {!isAvailable && index === 9 ? null : (
                    <span className="window-leadersboard__positionNumber">
                      {element.userStats.position}.&nbsp;
                    </span>
                  )}
                  {!isAvailable && index === 9
                    ? windowSize[0] > 900
                      ? "Me encantaría decirte tu puesto, pero tengo que sacar cuentas"
                      : "Aún estoy calculando tu puesto"
                    : !!element.userInfo?.name
                    ? element.userInfo.name
                    : "Juan Perez"}
                </div>
                <div>
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="7"
                      cy="7"
                      r="6"
                      fill={
                        scorePoints.filter((score, i) => {
                          return (
                            element.userStats.points >= score.scorePoints &&
                            score?.color
                          );
                        })[0].color
                      }
                      className={!isAvailable && "unavailable-stat-circle"}
                    />
                  </svg>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RankingLeaderboard;
